import { UsuarioModel } from '@/models/usuario.model';
import { AuthService } from '@/services/auth.service';
import { LoadingService } from '@/services/loading.service';
import { PontosVendasProdForComissoesPadroesService } from '@/services/pontos-vendas-prod-for-comissoes-padroes.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { UsuariosService } from '@/services/usuarios.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatatableColumn } from '@/models/datatable-column.model';
import { SharedService } from 'src/app/shared/shared.service';
import { Location } from '@angular/common';
import { PontosVendasProdForComissoesPadroesModel } from '@/models/pontos-vendas-prod-for-comissoes-padroes.model';
import { PontosVendasProdForComissoesDescModel } from '@/models/pontos-vendas-prod-for-comissoes-desc.model';
import { PontosVendasProdForComissoesDescService } from '@/services/pontos-vendas-prod-for-comissoes-desc.service';



interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}



@Component({
  selector: 'app-comissoes-padroes-list',
  templateUrl: './comissoes-padroes-list.component.html',
  styleUrls: ['./comissoes-padroes-list.component.scss'],
})
export class ComissoesPadroesListComponent implements OnInit {
  buttonAdd = { action: this.add.bind(this), label: 'add' };
  buttonEdit = { action: this.edit.bind(this), label: 'edit' };
  buttonDel = { action: this.del.bind(this), label: 'edit' };
  pdvProdForComissoesDesc: PontosVendasProdForComissoesDescModel[] = [];
  auxObject: any[];

  columns: DatatableColumn[] = [
    {
      field: 'pdvProdForComissoesPadroes.produtoGrupo',
      label: 'Grupo de Produtos',
    },
    {
      field: 'pdvProdForComissoesPadroes.produtoGrupo',
      label: 'Grupo de Produtos',
    },
    {
      field: 'pdvProdForComissoesPadroes.fornecedor',
      label: 'Fornecedor',
    },
    {
      field: 'pdvProdForComissoesPadroes.comissao',
      label: 'Comissão',
    },
  ];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = this.pdvProdForComissoesDesc;


  panelOpenState = false;

  constructor(
    private sharedService: SharedService,
    public authService: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private pontosVendasProdForComissoesPadroesService: PontosVendasProdForComissoesPadroesService,
    private pontosVendasProdForComissoesDescService: PontosVendasProdForComissoesDescService
  ) {}

  ngOnInit(): void {
    this.sharedService.changeAuth(true);
    this.findAllComissoesDesc();
  }


  edit(id: number | undefined) {
    this.router.navigate(['administracao/comissoes-padroes'], {
      queryParams: { id },
      queryParamsHandling: 'merge',
    });
  }

  add() {
    this.router.navigate(['administracao/comissoes-padroes']);
  }

  // findAllComissoesPadroes() {
  //   this.loadingService.show();
  //   this.pontosVendasProdForComissoesPadroesService
  //     .getAllPontosVendasProdForComissoesPadroes()
  //     .subscribe(
  //       (res: PontosVendasProdForComissoesPadroesModel[]) => {
  //         this.pdvProdForComissoesPadroes = res;
  //       },
  //       (err: any) => console.log(err)
  //     );
  //   this.loadingService.hide();
  // }
  findAllComissoesDesc() {
    this.loadingService.show();
    this.pontosVendasProdForComissoesDescService
      .getAllPontosVendasProdForComissoesDesc()
      .subscribe(
        (res: PontosVendasProdForComissoesDescModel[]) => {
          if (res) {
            this.pdvProdForComissoesDesc = res;
          }
        },
        (err: any) => console.log(err)
      );
    this.loadingService.hide();
  }

  del() {}
}
