<div class="container">
  <div class="wrap-title">
    <h2 class="text-center">
      {{ this.id ? "Editar Comissão Padrão" : "Criar Comissão Padrão" }}
    </h2>
  </div>
  <div class="responsive_table">
    <div class="form">
      <p>
        <mat-toolbar class="toolbar">
          <span>Comissões</span>
        </mat-toolbar>
      </p>
      <div
        class="container-desc-produtos-grupos d-flex flex-row justify-content-between"
      >
        <mat-form-field
          appearance="outline"
          class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12"
        >
          <mat-label>Descrição</mat-label>
          <input
            required
            type="text"
            matInput
            name="descricao"
            placeholder="Descrição"
            [(ngModel)]="pdvProdForComissoesDesc.descricao"
          />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12"
        >
          <mat-label>Filtre por categoria</mat-label>
          <mat-select
            (selectionChange)="setCategory($event)"
            [(value)]="idCategoria"
          >
            <mat-option
              *ngFor="let categoria of categoriaProdutos"
              [value]="categoria.id"
            >
              {{ categoria.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-grid-list
        [cols]="breakpoint"
        rowHeight="{{ rowBreakPoint }}:{{ rowBreakPointrow }}"
        (window:resize)="resizeProdutosTable()"
      >
        <mat-grid-tile *ngFor="let produto of produtosGrupos; let i = index">
          <div class="wrap-cards-produtos">
            <mat-card appearance="outlined" class="card-products">
              <mat-card-content>
                <span>
                  {{ produto.descricao }}
                </span>

                <ng-container *ngIf="this.muitosFornecedores">
                  <mat-form-field
                    appearance="outline"
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-3 input-comissao"
                    *ngIf="!this.id"
                  >
                    <mat-label>Fornecedores</mat-label>
                    <mat-select
                      (selectionChange)="
                        composeProdutosComissoes(
                          $event,
                          'fornecedor_id',
                          produto.id
                        )
                      "
                    >
                      <mat-option value=""> Fornecedores </mat-option>
                      <mat-option
                        *ngFor="let fornecedor of fornecedores"
                        value="{{ fornecedor.id }}"
                      >
                        {{ fornecedor.nome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="this.id"
                    appearance="outline"
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-3 input-comissao"
                  >
                  <mat-label>Fornecedores</mat-label>
                    <mat-select
                      [ngModel]="
                        auxPdvProdForComissoesPadroes[produto.id].fornecedor_id
                      "
                      (selectionChange)="
                        composeProdutosComissoes(
                          $event,
                          'fornecedor_id',
                          produto.id
                        )
                      "
                    >
                      <mat-option value=""> Fornecedores </mat-option>
                      <mat-option
                        *ngFor="let fornecedor of fornecedores"
                        value="{{ fornecedor.id }}"
                      >
                        {{ fornecedor.nome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <mat-form-field
                  appearance="outline"
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-3 input-comissao"
                >
                  <mat-label>Comissão</mat-label>

                  <input
                    type="text"
                    matInput
                    placeholder="Comissão"
                    name="comissao"
                    min="0.00"
                    max="3.00"
                    mask="0.00"
                    [(ngModel)]="
                      auxPdvProdForComissoesPadroes[produto.id].comissao
                    "
                    (change)="
                      composeProdutosComissoes(
                        $event.target,
                        'comissao',
                        produto.id
                      )
                    "
                  />
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <ng-container *ngIf="this.id; else create">
      <mat-dialog-actions class="mt-4 container__btn">
        <button
          mat-raised-button
          color="primary"
          class="btn-action"
          (click)="editarPdvComissoesPadroes()"
        >
          Salvar
        </button>
        <button mat-raised-button class="btn-action" (click)="cancel()">
          Cancelar
        </button>
      </mat-dialog-actions>
    </ng-container>
    <ng-template #create>
      <mat-dialog-actions class="container__btn">
        <button
          mat-raised-button
          color="primary"
          class="btn-action"
          (click)="createPdvComissoesPadroes()"
        >
          Cadastrar
        </button>
        <button mat-raised-button class="btn-action" (click)="cancel()">
          Cancelar
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>
</div>
