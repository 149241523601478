import { Component, Input } from '@angular/core';

type StatusType = 'success' | 'danger' | 'warning';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input()
  label: string

  @Input()
  type: StatusType = 'success'
}
