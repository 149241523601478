<div class="container">
  <div class="wrap-title">
    <h2 class="text-center">
      {{ this.id ? "Editar Usuário" : "Criar Usuário" }}
    </h2>
  </div>
  <div class="responsive_table">
    <div>
      <div class="form">
        <p>
          <mat-toolbar class="toolbar">
            <span>Cadastro de Usuário</span>
          </mat-toolbar>
        </p>
        <div class="input-wrapper">

          <mat-form-field
            appearance="outline"
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <mat-label>Nome</mat-label>
            <input
              type="text"
              required
              matInput
              [(ngModel)]="usuarioDto.nome"
              name="nome"
              placeholder="Nome"
            />

          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <mat-label>Login</mat-label>
            <input
              type="text"
              required
              matInput
              [(ngModel)]="usuarioDto.login"
              name="login"
              placeholder="Login"
            />

          </mat-form-field>

          <ng-container *ngIf="id">
            <mat-form-field
            appearance="outline"
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <mat-label>Senha</mat-label>
            <input
              type="password"
              required
              matInput
              name="senha"
              placeholder="senha"
              [(ngModel)]="auxUsuarioDto.senha"
            />

          </mat-form-field>
          </ng-container>

          <mat-form-field *ngIf="!id"
            appearance="outline"
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <mat-label>Senha</mat-label>
            <input
              type="password"
              required
              matInput
              name="senha"
              placeholder="senha"
              [(ngModel)]="usuarioDto.senha"
            />

          </mat-form-field>


          <mat-form-field
            required
            appearance="outline"
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <mat-label>Perfil</mat-label>
            <mat-select
              name="status"
              aria-placeholder="status"
              [(ngModel)]="usuarioDto.usuario_perfil_id"
            >
              <mat-option value=2> Administrador</mat-option>
              <mat-option value=3> Distribuidor</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            required
            appearance="outline"
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <mat-label>Status</mat-label>
            <mat-select
              name="status"
              aria-placeholder="status"
              [(ngModel)]="usuarioDto.status"
            >
              <mat-option value="ATIVO"> ATIVO</mat-option>
              <mat-option value="INATIVO"> INATIVO</mat-option>
              <mat-option value="BLOQUEADO"> BLOQUEADO</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-container *ngIf="this.id; else create">
          <mat-dialog-actions class="container__btn">
            <button mat-raised-button color="primary"mat-dialog-close  class="btn-action" (click)="editarUsuario()">
              Salvar
            </button>
            <button mat-raised-button mat-dialog-close class="btn-action" (click)="cancelarCadastro()">
              Cancelar
            </button>
          </mat-dialog-actions>
        </ng-container>
        <ng-template #create>
          <mat-dialog-actions class="container__btn">
            <button mat-raised-button color="primary" mat-dialog-close  class="btn-action" (click)="cadastrarUsuario()">

              Cadastrar
            </button>
            <button mat-raised-button mat-dialog-close class="btn-action" (click)="cancelarCadastro()">
              Cancelar
            </button>
          </mat-dialog-actions>
        </ng-template>
      </div>
    </div>
  </div>
</div>
