import { Feriado } from '@/models/feriado.model';
import { FeriadoService } from '@/services/feriado.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-holiday-preview-dialog',
  templateUrl: './holiday-preview-dialog.component.html',
  styleUrls: ['./holiday-preview-dialog.component.scss']
})
export class HolidayPreviewDialogComponent {
  loading = false

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Feriado,
    private swal: SweetAlert2Service,
    private dialogRef: MatDialogRef<HolidayPreviewDialogComponent>,
    private feriadoService: FeriadoService
  ) {}

  async onDelete() {
    try {
      this.loading = true
      await this.feriadoService.remove(this.data.id);
      this.swal.success('Feriado excluido com sucesso!')
      this.dialogRef.close({excluded: true})
    } catch(error) {
      this.swal.error('Não foi possível excluir. Tente novamente!')
    } finally {
      this.loading = false
    }
  }
}
