<div class="materialTableHeader d-flex">
  <h3>Pix - Últimas 24 horas</h3>
</div>
<table mat-table [dataSource]="data">
  <ng-container matColumnDef="cod_pdv">
    <th mat-header-cell *matHeaderCellDef>Código</th>
    <td mat-cell *matCellDef="let element">{{element.cod_pdv}}</td>
  </ng-container>

  <ng-container matColumnDef="pdv">
    <th mat-header-cell *matHeaderCellDef>Ponto de venda</th>
    <td mat-cell *matCellDef="let element">{{element.pdv}}</td>
  </ng-container>

  <ng-container matColumnDef="serial">
    <th mat-header-cell *matHeaderCellDef>Serial</th>
    <td mat-cell *matCellDef="let element">{{element.serial}}</td>
  </ng-container>

  <ng-container matColumnDef="data_hora_rec">
    <th mat-header-cell *matHeaderCellDef>Data Hora</th>
    <td mat-cell *matCellDef="let element">{{element.data_hora_rec | date: "dd/MM/yyyy HH:mm:ss"}}</td>
  </ng-container>

  <ng-container matColumnDef="n_recarga">
    <th mat-header-cell *matHeaderCellDef>Recarga</th>
    <td mat-cell *matCellDef="let element">{{element.n_recarga}}</td>
  </ng-container>

  <ng-container matColumnDef="fornecedor_nsu">
    <th mat-header-cell *matHeaderCellDef>Fornecedor NSU</th>
    <td mat-cell *matCellDef="let element">{{element.fornecedor_nsu}}</td>
  </ng-container>

  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef>Valor</th>
    <td mat-cell *matCellDef="let element">{{element.valor | currency: "R$"}}</td>
  </ng-container>

  <ng-container matColumnDef="pix_nsu">
    <th mat-header-cell *matHeaderCellDef>NSU</th>
    <td mat-cell *matCellDef="let element">{{element.pix_nsu}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Ações</th>
    <td mat-cell *matCellDef="let element">
      <button
        matTooltip="Estornar"
        (click)="estornar(element)"
        mat-icon-button
        class="small-icon-button"
        color="accent"
        class="mat-focus-indicator btn-tbl-edit mat-icon-button mat-button-base mat-accent"
        ng-reflect-color="accent"
      >
        <mat-icon>sync</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>

</table>
