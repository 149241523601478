<div class="row">
  <div class="header">
    <h1>Comissões Padrões</h1>
    <button mat-mini-fab color="primary" (click)="add()">
      <mat-icon>{{buttonAdd.label}}</mat-icon>
    </button>
  </div>

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel *ngFor="let comissaoPadraoDesc of pdvProdForComissoesDesc" class="mb-2">
      <mat-expansion-panel-header>
        <mat-panel-title>
        {{ comissaoPadraoDesc.descricao }}
        </mat-panel-title>
        <mat-panel-description >
          Listagem das comissões
          <button
          (click)="edit(comissaoPadraoDesc.id)"
          matTooltip="Editar Elemento"
          mat-icon-button
        >
          <mat-icon>{{ buttonEdit.label }}</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>

    <mat-list role="list"  *ngFor="let item of comissaoPadraoDesc.pdvProdForComissoesPadroes">
      <mat-list-item role="listitem"> <span class="field-display">Grupo de Produto:</span> <span>{{ item.produtosgrupo!.descricao }},</span>  <span class="field-display field-display-left">Comissão:</span>  {{ item.comissao }}</mat-list-item>
    </mat-list>

    </mat-expansion-panel>
  </mat-accordion>
</div>
