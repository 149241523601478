import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSelectAutocompleteComponent } from './input-select-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ErrorsLabelModule } from '../errors-label/errors-label.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [InputSelectAutocompleteComponent],
  imports: [
    CommonModule,
    ErrorsLabelModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  exports: [InputSelectAutocompleteComponent],
})
export class InputSelectAutocompleteModule {}
