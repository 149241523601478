import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PontosDeVendaModel } from '../models/pontos-de-venda';
import { environment } from '../../environments/environment';
import { ProdutosComissoesModel } from '@/models/produtos-comissoes.model';
import { PontosVendaConfiguracaoModel } from '@/models/pontos-venda-configuracao.model';
import { LimitePontoVenda } from '@/models/dto/ponto_venda.simple';
import { pontosVendaFaturamentoDiasModel } from '../models/pontos-venda-faturamento-dias.Model';
import { LoadingService } from './loading.service';
import { PontosVendaBloqueioFinanceiroModel } from '@/models/pontos-venda-bloqueio-financeiro.model';
import { BancoPdv } from '@/models/BancoPdv.';

@Injectable({
  providedIn: 'root',
})
export class PontosDeVendaService {
  url = environment.baseURL;

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService,
    public loadingService: LoadingService
  ) {}

  createPontosDeVenda(
    pontoDeVenda: PontosDeVendaModel,
    pontosVendaConfiguracao?: PontosVendaConfiguracaoModel,
    pontosVendaFaturamentoDias?: pontosVendaFaturamentoDiasModel[],
    terminalConfiguracoes?: any
  ) {
    return this.httpClient
      .post<PontosDeVendaModel>(`${this.url}/pontos-de-venda`, {
        pontoDeVenda,
        pontosVendaConfiguracao,
        pontosVendaFaturamentoDias,
        terminalConfiguracoes
      })
      .pipe(catchError(this.authService.handleError as any));
  }
  findAllPontosDeVenda(params?: any): Observable<PontosDeVendaModel[]> {
    return this.httpClient
      .get<PontosDeVendaModel[]>(`${this.url}/pontos-de-venda`, {
        params
      })
      .pipe(catchError(this.authService.handleError as any));
  }
  findAllWithRecolhe(): Observable<PontosDeVendaModel[]> {
    return this.httpClient
      .get<PontosDeVendaModel[]>(`${this.url}/pontos-de-venda/recolhe`)
      .pipe(catchError(this.authService.handleError as any));
  }

  searchPontosDeVenda(query?: string): Observable<PontosDeVendaModel[]> {
    return this.httpClient
      .get<PontosDeVendaModel[]>(`${this.url}/pontos-de-venda/search?${query}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  partialSearchPontosDeVenda(query?: string): Observable<PontosDeVendaModel[]> {
    return this.httpClient
      .get<PontosDeVendaModel[]>(`${this.url}/pontos-de-venda/partial?${query}`)
      .pipe(catchError(this.authService.handleError as any));
  }
  findOnePontosDeVenda(id: number): Observable<PontosDeVendaModel> {
    return this.httpClient
      .get<PontosDeVendaModel>(`${this.url}/pontos-de-venda/${id}`)
      .pipe(catchError(this.authService.handleError as any));
  }
  async findOnePontoDeVenda(id: number){
    return this.httpClient
      .get<PontosDeVendaModel>(`${this.url}/pontos-de-venda/${id}`)
      .pipe(catchError(this.authService.handleError as any));
  }
  updatePontosDeVenda(id: string, body: any) {
    return this.httpClient
      .post<PontosDeVendaModel>(`${this.url}/pontos-de-venda/${id}`, body)
      .pipe(catchError(this.authService.handleError as any));
  }
  updateLimitePontosDeVenda(id: number | undefined, body: any) {
    return this.httpClient
      .patch<LimitePontoVenda>(
        `${this.url}/pontos-de-venda/limite-pontos-de-venda/${id}`,
        body
      )
      .pipe(catchError(this.authService.handleError as any));
  }
  togglePontoDeVendaStatus(id: number, status: string) {
    return this.httpClient
      .put(`${this.url}/pontos-de-venda/toggle/${id}`, { status })
      .pipe(catchError(this.authService.handleError as any));
  }

  historicoBloqueioFinanceiro(id: number): Observable<PontosVendaBloqueioFinanceiroModel[]> {
    return this.httpClient
      .get<PontosVendaBloqueioFinanceiroModel[]>(`${this.url}/pontos-de-venda/${id}/historico-bloqueio-financeiro`)
  }

  registraBloqueioFinanceiro(id: number, data: {obs: string}): Observable<PontosVendaBloqueioFinanceiroModel> {
    return this.httpClient
      .post<PontosVendaBloqueioFinanceiroModel>(`${this.url}/pontos-de-venda/${id}/bloqueio-financeiro`, data)
  }

  atualizaComissoes(id: number, data: any) {
    return this.httpClient.put(`${this.url}/pontos-de-venda/${id}/comissoes`, data)
  }

  validaModalidadePre(id: number) {
    return this.httpClient
      .get<any>(`${this.url}/pontos-de-venda/${id}/valida-modalidade-pre`)
  }

  boletosConfiguracoes(params: any) {
    return this.httpClient
      .get<BancoPdv[]>(`${this.url}/pontos-de-venda/boletos-configuracoes`, {
        params
      })
  }

  atualizaBoletosConfiguracoes(data: {pdv_ids: number[], boletoConfigId: string;}) {
    return this.httpClient
      .put(`${this.url}/pontos-de-venda/boletos-configuracoes`, data)
  }
}
