import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecebimentoComponent } from './recebimento/recebimento.component';
import { LancamentosComponent } from './lancamentos/lancamentos.component';
import { FaturamentosComponent } from './faturamentos.component';

const routes: Routes = [
  { path: 'recebimento/:idRec', component: RecebimentoComponent },
  { path: 'recebimento/:idRec/lancamentos', component: LancamentosComponent },
  { path: ':id', component: FaturamentosComponent },
  { path: '**', component: FaturamentosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FaturamentosRoutingModule {}
