import { Injectable } from '@angular/core';
import { catchError, lastValueFrom } from 'rxjs';
import { TerminaisAtualizacaoModel } from '@/models/terminais-atualizacao.model';
import { BaseApi } from './base/base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TerminaisAtualizacoesService extends BaseApi {
  findAll(params: any): Promise<any[]> {
    return lastValueFrom(this.get(`${this.url}/terminais-atualizacoes`, {
      params
    }))
  }


  createTerminaisAtualizacao(terminaisAtualizacao: TerminaisAtualizacaoModel) {
    return this
      .post<TerminaisAtualizacaoModel>(`${this.url}/terminais-atualizacoes`, terminaisAtualizacao)
      .pipe(catchError(this.authService.handleError as any));
  }

  alteraAtualizacaoAutomatica(data: {terminal_ids: number[], enabled: boolean}) {
    return lastValueFrom(this.put(`${this.url}/terminais-atualizacoes`, data))
  }
}
