import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseApi } from './base/base-api.service';
import { DashboardOverview, DashboardTrends } from '@/models/dashboard.model';
import { Pagination } from '@/models/pagination.model';
import {
  HipercapPdvProduct,
  HipercapProduct,
} from '@/models/hipercap-product.model';

@Injectable({
  providedIn: 'root',
})
export class HipercapService extends BaseApi {
  async getProducts(): Promise<HipercapProduct[]> {
    return lastValueFrom(this.get(`${this.url}/hipercap/products`));
  }

  async getProductsPdv(params?: {
    pdv_id: number;
    product_id: number;
  }): Promise<Pagination<HipercapPdvProduct>> {
    return lastValueFrom(
      this.get(`${this.url}/hipercap/products-pdv`, { params })
    );
  }

  async updatePermissions(data: any) {
    return lastValueFrom(this.put(`${this.url}/hipercap/products-pdv`, data));
  }

  async massUpdate(data: any) {
    return lastValueFrom(
      this.put(`${this.url}/hipercap/mass-update-products-pdv`, data)
    );
  }
}
