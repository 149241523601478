<h2 mat-dialog-title>Alterar dados do faturamento</h2>
<mat-dialog-content>
  <span>{{ data.ponto_venda.nome_comercial }}</span>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="datepicker">
      <mat-label>Data inicial</mat-label>
      <input matInput [matDatepicker]="data_inicio" formControlName="data_inicio" />
      <mat-datepicker-toggle matSuffix [for]="data_inicio"></mat-datepicker-toggle>
      <mat-datepicker #data_inicio></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="datepicker">
      <mat-label>Data final</mat-label>
      <input matInput [matDatepicker]="data_fim" formControlName="data_fim" />
      <mat-datepicker-toggle matSuffix [for]="data_fim"></mat-datepicker-toggle>
      <mat-datepicker #data_fim></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="datepicker">
      <mat-label>Data faturamento</mat-label>
      <input matInput [matDatepicker]="data_faturamento" formControlName="data_faturamento" />
      <mat-datepicker-toggle matSuffix [for]="data_faturamento"></mat-datepicker-toggle>
      <mat-datepicker #data_faturamento></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="datepicker">
      <mat-label>Data de vencimento</mat-label>
      <input matInput [matDatepicker]="data_vencimento" formControlName="data_vencimento" />
      <mat-datepicker-toggle matSuffix [for]="data_vencimento"></mat-datepicker-toggle>
      <mat-datepicker #data_vencimento></mat-datepicker>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  <button mat-button color="primary" (click)="handleSubmit()">Salvar</button>
</mat-dialog-actions>
