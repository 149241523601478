import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermissaoService } from '@/services/permissao.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-create-permissao',
  templateUrl: './create-permissao.component.html',
  styleUrls: ['./create-permissao.component.scss']
})
export class CreatePermissaoComponent implements OnInit {
  form!: FormGroup

  constructor(
    private fb: FormBuilder,
    private permissaoService: PermissaoService,
    private swal2: SweetAlert2Service,
    private dialogRef: MatDialogRef<CreatePermissaoComponent>,
  ) {
    this.form = this.fb.group({
      nome: ['', [Validators.required]],
      descricao: ['', [Validators.required]]
    })
  }

  async sendForm() {
    this.permissaoService.create({
      nome: this.form.value['nome'],
      descricao: this.form.value['descricao'],
    })

    this.dialogRef.close(true)
    this.swal2.success('Criado com sucesso!')
  }

  ngOnInit() {
  }
}
