import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UsuarioModel } from '@/models/usuario.model';
import { AuthService } from '@/services/auth.service';
import { UsuariosService } from '@/services/usuarios.service';
import { SharedService } from '../../../../shared/shared.service';
import { FormBuilder, FormControl } from '@angular/forms';
@Component({
  selector: 'app-usuarios-list',
  templateUrl: './usuarios-list.component.html',
  styleUrls: ['./usuarios-list.component.scss'],
})
export class UsuariosListComponent implements OnInit {
  usuarios: UsuarioModel[];
  tableColumns: string[] = ['id', 'perfil', 'login', 'nome', 'status', 'acoes']
  
  inputSearch = new FormControl('');
  inputStatus = new FormControl('TODOS');

  constructor(
    private sharedService: SharedService,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private usuariosService: UsuariosService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.sharedService.changeAuth(true);
    const query: any = {};
    this.route.queryParams.subscribe((params) => {
      query.search = params['search'] || '';
      query.perfil = params['perfil'] || '';
      query.status = params['status'] || '';
    });

    this.inputSearch.setValue(query.search)
    this.inputStatus.setValue(query.status)

    this.searchUsuarios();

    this.inputStatus.valueChanges.subscribe(() => this.searchUsuarios())
  }

  edit(id: number) {
    this.router.navigate(['administracao/usuarios'], {
      queryParams: { id },
      queryParamsHandling: 'merge',
    });
  }

  add() {
    this.router.navigate(['administracao/usuarios']);
  }

  searchUsuarios() {
    const url = this.sharedService.buildQueryUrl({
      search: this.inputSearch.value,
      status: this.inputStatus.value,
    });
    this.usuariosService.findAllUsuarios(url).subscribe(
      (res: UsuarioModel[]) => {
        if (url !== '') {
          this.location.replaceState('administracao/usuarios/lista?' + url);
        }
        this.usuarios = res;
      },
      (err: any) => console.log(err)
    );
  }
}
