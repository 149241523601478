import { BoletoStatus } from '@/enums/BoletoStatus';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'boletoStatusDescricao'})
export class BoletoStatusDescricaoPipe implements PipeTransform {
  transform(status: string): string {
    const statuses = {
      [BoletoStatus.IMPRESSO]: 'Aberto',
      [BoletoStatus.IMPRESSAO_PENDENTE]: 'Aberto',
      [BoletoStatus.QUITADO]: 'Quitado',
      [BoletoStatus.SEM_REGISTRO]: 'Sem registro',
      [BoletoStatus.CANCELADO]: 'Cancelado',
    }

    return statuses[status as keyof typeof statuses];
  }
}