<h1 mat-dialog-title>Registrar feriado</h1>
<div mat-dialog-content>
  <div>
    <strong>Data:</strong>
    {{formattedData}}
  </div>
  <mat-form-field class="field">
    <mat-label>Descrição</mat-label>
    <input matInput [formControl]="descricao">
  </mat-form-field>
</div>
<div mat-dialog-actions class="btn-actions">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
  <button mat-button (click)="create()" color="primary" [disabled]="descricao.invalid || loading">
    {{ !loading ? 'Salvar' : 'Salvando...' }}
  </button>
</div>
