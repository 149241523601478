
<div class="auth-content">
  <div class="justify-content-center">
    <mat-card appearance="outlined" class="card-content-login">
      <div>
        <div class="text-center">
          <div>
            <a routerLink="" class="logo-full"
              ><img src="/assets/logo-curvas.svg" alt="logo-full"
            /></a>
          </div>
          <h4 class="mt-4">Fazer Login</h4>
        </div>

        <div class="container-login100-form-btn">
          <div *ngIf="error" class="alert alert-danger">
            {{ error }}
          </div>
          <mat-stepper linear #stepper>
            <mat-step [stepControl]="loginFormGroup" >
              <form (ngSubmit)="findLogin()" [formGroup]="loginFormGroup">
                <div class="form-group auth-form-group">
                  <mat-icon matSuffix class="auth-page-icon">
                    email
                  </mat-icon>
                  <input
                    formControlName="username"
                    type="text"
                    name="login"
                    class="form-control auth-control input-form mat-input"
                    id="Login"
                    placeholder="Login"
                    [ngClass]="{
                      'is-invalid': loginFormGroup.invalid
                    }"
                  />
                </div>
                <div class="next">
                  <button mat-flat-button [disabled]="loginFormGroup.invalid" color="primary" type="submit">Próximo</button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="passFormGroup">
              <form (ngSubmit)="valida2Fa()" [formGroup]="passFormGroup">
                <div>
                  <div class="wrap-user-info">
                    <span class="text-user-info">{{ userCurrent?.nome }}</span>

                    <mat-card
                      appearance="outlined"
                      (click)="voltarParaEmail()"
                      class="view-email-form"
                    >
                      <span>{{ loginFormGroup.get('username')?.value }}  </span> <mat-icon matSuffix class="auth-page-icon" >keyboard_arrow_down</mat-icon >
                    </mat-card>
                  </div>

                  <div class="form-group auth-form-group">
                    <mat-icon matSuffix class="auth-page-icon"
                      >vpn_key</mat-icon
                    >
                    <input
                      type="password"
                      formControlName="password"
                      class="form-control auth-control input-form"
                      id="senha"
                      [ngClass]="{
                        'is-invalid': passFormGroup.invalid
                      }"
                      placeholder="Senha"
                    />
                    <div
                      *ngIf="passFormGroup.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="passFormGroup?.dirty && passFormGroup?.errors?.['required']">
                        Preencha o campo de senha
                      </div>
                    </div>
                  </div>
                </div>
                <div class="buttons-back-next">
                  <button mat-button color="primary" matStepperPrevious>Voltar</button>
                  <button mat-flat-button color="primary" type="submit" [disabled]="passFormGroup.invalid">Avançar</button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="twoFactorFormGroup" >
              <form (ngSubmit)="login()" [formGroup]="twoFactorFormGroup">
                <div class="form-group auth-form-group">
                  <mat-icon matSuffix class="auth-page-icon">
                    email
                  </mat-icon>
                  <input
                    formControlName="token"
                    type="text"
                    name="token"
                    class="form-control auth-control input-form mat-input"
                    id="token"
                    placeholder="Código do APP"
                    [ngClass]="{
                      'is-invalid': twoFactorFormGroup.invalid
                    }"
                  />
                </div>
                <p>
                  Abra seu aplicativo do Google Authenticator (TOTP) para visualizar o código de entrada.
                </p>
                <div class="next">
                  <button mat-flat-button color="primary" type="submit" [disabled]="twoFactorFormGroup.invalid">Verificar</button>
                </div>
              </form>
            </mat-step>
          </mat-stepper>
        </div>
      </div>
    </mat-card>
  </div>
</div>

