import { LoadingService } from '@/services/loading.service';
import { UsuariosService } from '@/services/usuarios.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { UsuarioDto } from '@/models/dto/usuario.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.scss']
})
export class TrocarSenhaComponent {
  form: FormGroup;
  usuarioId: any;
  user: any;
  usuarioDto = new UsuarioDto();

  constructor(
    private formBuilder: FormBuilder,
    private usuariosService: UsuariosService,
    private loadingService: LoadingService,
    private router: Router,
    private sweetAlertService: SweetAlert2Service,
    ) {}

    checkResetPassword() {
      if(localStorage.getItem('resetPassword') === '1') {
        this.sweetAlertService.info('Para sua segurança, recomendamos você alterar sua senha atual!', 'Atenção!');
      }

      localStorage.removeItem('resetPassword');
    }

  ngOnInit() {
    this.form = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)])
    });
    this.checkResetPassword()
  }

  handleSubmit() {
    this.loadingService.show()
    this.usuariosService.updatePassword(this.form.value)
      .subscribe({
        next: () => {
          this.sweetAlertService.success('Senha alterada com sucesso!');
          this.router.navigate(['home'])
         },
         error: (err: HttpErrorResponse) => {
           this.sweetAlertService.error(err.message);
         },
         complete: () => {
            this.loadingService.hide()
          }
      })
  }
}
