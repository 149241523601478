import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { Permissao } from '@/enums/Permissao';

@Component({
  selector: 'app-menu-administracao',
  templateUrl: './menu-administracao.component.html',
  styleUrls: ['./menu-administracao.component.scss'],
})
export class MenuAdministracaoComponent implements OnInit {

  Permissao = Permissao

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.sharedService.changeAuth(true);
  }
}
