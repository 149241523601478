import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
  static moneyNonZero(control: AbstractControl): ValidationErrors | null {
    const money = control.value

    if(money <= 0) {
      return {
        moneyNonZero: true
      }
    }

    return null;
  }
}
