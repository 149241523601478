import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-input',
  template: '',
})
export class InputComponent<T> implements ControlValueAccessor, OnInit {
  @Output() clickInput = new EventEmitter<MouseEvent>();
  @Output() ngModelChange = new EventEmitter<string>();
  @Input() label!: string;
  @Input() placeholder: string = '';
  @Input() disablePaste: boolean = false;
  focused = false

  public value!: T;
  public disabled = false;
  public required: boolean = false;
  public touched = false;
  public matcher: ErrorStateMatcher = {
    isErrorState: () => {
      return this.touched && !!this.ngControl.errors;
    },
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.required = this.hasRequiredValidator(this.ngControl.control);
    }
  }

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (_value: T) => {
    //esta função é utilizada internamente pelo form
  };

  onTouched = () => {
    //esta função é utilizada internamente pelo form
  };

  handleClick(event: MouseEvent) {
    this.clickInput.emit(event);
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  hasRequiredValidator(control: AbstractControl | null): boolean {
    if (control?.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }

  onPaste(event: ClipboardEvent) {
    this.blockPaste(event);
  }

  onDrop(event: DragEvent) {
    this.blockPaste(event);
  }

  blockPaste(event: ClipboardEvent | DragEvent) {
    if (this.disablePaste) {
      event.preventDefault();
    }
  }

  onFocus(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      if (!this.touched) {
        this.touched = true;
        this.onTouched();
      }
    }
  }
}
