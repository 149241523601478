import { PaginationData } from '@/models/pagination.model';
import { TerminaisMensagemAssoc } from '@/models/terminais-mensagem';
import { TerminaisMensagensService } from '@/services/terminais-mensagens.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-mensagens-terminais-details',
  templateUrl: './mensagens-terminais-details.component.html',
  styleUrls: ['./mensagens-terminais-details.component.scss'],
})
export class MensagensTerminaisDetailsComponent implements OnInit {
    resourceId: number | null = null
    terminaisAssoc = new PaginationData<TerminaisMensagemAssoc>()
    terminaisMensagemData: any = {}
    columns: string[] = [
        'pdv',
        'pdv_status',
        'serial',
        'modelo',
        'status',
        'mensagem_status',
    ];

    editor = new Editor()

    editorForm = new FormGroup({
        editorContent: new FormControl(null),
    });

    constructor(
        private route: ActivatedRoute,
        private terminaisMensagensService: TerminaisMensagensService
    ) {
        const {id} = this.route.snapshot.params
        this.resourceId = Number(id)
    }

    async loadTerminaisAssoc() {
        const result = await this.terminaisMensagensService.terminaisAssoc(this.resourceId as number, this.terminaisAssoc.meta)
        this.terminaisAssoc.setData(result.items, result.meta)
        
        console.log(this.terminaisAssoc.items[0])
    }

    async loadTerminaisMensagemData() {
        const result = await this.terminaisMensagensService.findById(this.resourceId as number)
        this.terminaisMensagemData = result
        this.editor.setContent(result.mensagem)
        this.editorForm.get('editorContent')?.disable();
    }

    onPaginate(data: PageEvent) {
        this.terminaisAssoc.setCurrentPage(data.pageIndex + 1)
        this.loadTerminaisAssoc()
      }

    ngOnInit(): void {
        this.loadTerminaisMensagemData()
        this.loadTerminaisAssoc()
    }
}
