<div class="page">
  <h1>Terminais Firmware</h1>
  <app-create-terminal-firmware
    (created)="onCreated($event)"
  ></app-create-terminal-firmware>

  <div class="materialTableHeader">
    <h3>Informações</h3>
  </div>
  <table mat-table [dataSource]="terminaisFirmwares">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let item"> {{item.id}} </td>
    </ng-container>

    <ng-container matColumnDef="modelo">
      <th mat-header-cell *matHeaderCellDef>Modelo</th>
      <td mat-cell *matCellDef="let item"> {{item.modelo.nome}} </td>
    </ng-container>

    <ng-container matColumnDef="perfil">
      <th mat-header-cell *matHeaderCellDef>Perfil</th>
      <td mat-cell *matCellDef="let item"> {{item.firmware_perfil}} </td>
    </ng-container>

    <ng-container matColumnDef="versao">
      <th mat-header-cell *matHeaderCellDef>Versão</th>
      <td mat-cell *matCellDef="let item"> {{item.versao}} </td>
    </ng-container>

    <ng-container matColumnDef="descricao">
      <th mat-header-cell *matHeaderCellDef>Descrição</th>
      <td mat-cell *matCellDef="let item"> {{item.descricao}} </td>
    </ng-container>

    <ng-container matColumnDef="caminho">
      <th mat-header-cell *matHeaderCellDef>Caminho</th>
      <td mat-cell *matCellDef="let item"> {{item.caminho}} </td>
    </ng-container>

    <ng-container matColumnDef="arquivo">
      <th mat-header-cell *matHeaderCellDef>Arquivo</th>
      <td mat-cell *matCellDef="let item"> {{item.arquivo}} </td>
    </ng-container>

    <ng-container matColumnDef="servidor">
      <th mat-header-cell *matHeaderCellDef>Servidor</th>
      <td mat-cell *matCellDef="let item"> {{item.servidor.servidor}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let item">
        <mat-slide-toggle
          class="example-margin"
          color="primary"
          [matTooltip]="item.status === 'ATIVO' ? 'ATIVO' : 'INATIVO'"
          [checked]="item.status === 'ATIVO'"
          (toggleChange)="updateStatus(item)"
        >
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let item">
        <button mat-icon-button class="small-icon-button" color="warn" matTooltip="Remover">
          <mat-icon
            (click)="remove(item.id)"
            >delete</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
</div>
