import { environment } from "@/environments/environment";
import { FaturamentoDto } from "@/models/dto/faturamento.dto";
import { PontoVendaSimple } from "@/models/dto/ponto_venda.simple";
import { FaturamentoLancamentos } from "@/models/faturamentos.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base/base-api.service";

@Injectable({
    providedIn: 'root',
})
export class LancamentosService extends BaseApi {

  findLancamentosByRecebimentoId(recebimentoId: number) {
    return this.get<FaturamentoLancamentos[]>(`${this.url}/faturamentos/recebimento/${recebimentoId}/lancamentos`);
  }

  createLancamentosByRecebimentoId(recebimentoId: number, data: any) {
    return this.post(`${this.url}/faturamentos/recebimento/${recebimentoId}/lancamentos`, data);
  }
}
