import { environment } from '@/environments/environment';
import { OptionsQuery } from '@/models/api.model';
import { LoadingService } from '@/services/loading.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseApi {
  readonly url: string = '';

  constructor(
    protected http: HttpClient,
    protected dialog: MatDialog,
    protected loadingService: LoadingService,
    protected authService: AuthService
  ) {
    this.url = environment.baseURL;
  }

  protected get<T>(url: string, options?: OptionsQuery | {}): Observable<T> {
    options = options || {};
    return this.http.get<T>(url, options);
  }

  protected post<T>(url: string, data: any): Observable<T> {
    const fullUrl = `${url}`;
    return this.http.post<T>(fullUrl, data);
  }

  protected put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(url, data);
  }

  protected patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(url, data);
  }

  protected delete<T>(url: string, data: any = {}): Observable<T> {
    return this.http.request<T>('DELETE', url, { body: data });
  }
}
