import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom } from 'rxjs';
// import { CreateTerminalFirmware } from '@/models/dto/create-terminal-firmware';
import { BaseApi } from './base/base-api.service';
import { PaginationQuery, PaginationResult } from '@/models/pagination.model';
import { TerminalMensagem } from '@/models/terminal-mensagem';
import { TerminaisMensagem, TerminaisMensagemAssoc } from '@/models/terminais-mensagem';

@Injectable({
  providedIn: 'root',
})
export class TerminaisMensagensService extends BaseApi {
  async findAllTerminais() {
    return lastValueFrom(this.get<any[]>(`${this.url}/terminais`));
  }

  async createMensagem(data: any, terminaisId: any, id: any): Promise<any> {
    return lastValueFrom(
      this.post(`${this.url}/terminais/mensagem`, { data, terminaisId, id })
    );
  }

  async deleteMensagem(id: any): Promise<any> {
    return lastValueFrom(this.delete(`${this.url}/terminais/mensagem/${id}`));
  }

  async findAllTerminaisMensagens(params:  PaginationQuery & {vigente: boolean}) {
    return lastValueFrom(this.get<PaginationResult<TerminalMensagem>>(`${this.url}/terminais/mensagens`, {params}));
  }

  async findTerminaisSelecionados(id: any) {
    return lastValueFrom(
      this.get<any[]>(`${this.url}/terminais/mensagem/${id}`)
    );
  }

  async ativar(id: number) {
    return lastValueFrom(
      this.put(`${this.url}/terminais/mensagem/ATIVO/${id}`, null)
    );
  }

  async inativar(id: number) {
    return lastValueFrom(
      this.put(`${this.url}/terminais/mensagem/INATIVO/${id}`, null)
    );
  }

  async terminaisAssoc(id:number, params: PaginationQuery) {
    return lastValueFrom(this.get<PaginationResult<TerminaisMensagemAssoc>>(`${this.url}/terminais-mensagem/${id}/terminais`, {params}))
  }

  async findById(id:number) {
    return lastValueFrom(this.get<TerminaisMensagem>(`${this.url}/terminais-mensagem/${id}`))
  }
}
