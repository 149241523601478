export interface PaginationProps {
  current_page: number;
  last_page: number;
  total: number;
  per_page: number;
}

export interface Pagination<T> extends PaginationProps {
  data: T[]
}

export interface PaginationMeta {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number
}


export interface PaginationResult<T> {
  meta: PaginationMeta,
  items: T[]
}

export interface PaginationQuery {
  page: number | string;
  limit: number | string
}

export class PaginationData<T> {
  currentPage: number = 1;
  itemsPerPage: number = 20;
  itemCount: number;
  totalItems: number;
  totalPages: number

  items: T[] = []

  get meta() {
    return {
      page: this.currentPage,
      limit: this.itemsPerPage
    }
  }

  get total() {
    return this.totalItems
  }

  get limit() {
    return this.itemsPerPage
  }

  get page() {
    return this.currentPage
  }

  setData(data: T[], meta: PaginationMeta) {
    this.items = data
    this.setMetaPage(meta)
  }

  setItems(data: T[]) {
    this.items = data
  }


  setMetaPage(data: PaginationMeta) {
    this.currentPage = data.currentPage
    this.itemCount = data.itemCount
    this.itemsPerPage = data.itemsPerPage
    this.totalItems = data.totalItems
    this.totalPages = data.totalPages
  }

  setCurrentPage(page: number) {
    this.currentPage = page
  }

  setItemsPerPage(limit: number) {
    this.itemsPerPage = limit
  }
}