<div class="container-fluid container-wrap">
    <h1>Recebimento</h1>

    <ng-container *ngIf="recebimento; then content else no_content"></ng-container>
    <ng-template #no_content>
        <div *ngIf="!recebimento;else content"
            class="no_content_container">
            Recebimento não encontrado!
        </div>
    </ng-template>
    <ng-template #content>
        <div class="mat-elevation-z1 card container-space">
            <div class="container-info">
                <mat-grid-list cols="2" rowHeight="36px">
                    <mat-grid-tile colspan="2">
                        <div class="container-info">
                            <span>Ponto de Venda:</span> {{recebimento.ponto_venda.id}} -
                            {{recebimento.ponto_venda.nome_comercial}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="container-info">
                            <span>Recebimento:</span> {{recebimento.id}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="container-info">
                            <span>F.Pagamento:</span> {{recebimento.forma_pagamento}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="container-info">
                            <span>Criado:</span> {{recebimento.created_at | date:'dd/MM/yyyy HH:mm:ss'}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="container-info">
                            <span>Atualizado:</span> {{recebimento.updated_at | date:'dd/MM/yyyy HH:mm:ss'}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div *ngIf="datasFaturamentoIguais else dataUnica" class="container-info">
                            <span>Periodo:</span> {{recebimento.data_inicio | date:'dd/MM/yy'}}
                        </div>
                        <ng-template #dataUnica class="container-info">
                            <div class="container-info">
                                <span>Periodo:</span> {{recebimento.data_inicio | date:'dd/MM/yy'}} -
                                {{recebimento.data_fim | date:'dd/MM/yy'}}
                            </div>
                        </ng-template>
                    </mat-grid-tile>
                    <mat-grid-tile>
                      <div class="container-info">
                          <span>Modalidade:</span> {{recebimento.faturamento.modalidade}}
                      </div>
                  </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="container-info">
                            <span>Criado por:</span> {{recebimento.user_updated.id}} - {{recebimento.user_updated.nome}}
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

        <div class="mat-elevation-z1 card container-space" fxLayout="column" fxLayoutAlign="center start">
          <div class="form-container">
              <mat-form-field appearance="outline" color="primary" style="max-width: 180px;">
                  <input matInput currencyMask type="text"  [(ngModel)]="txtValorBoletoAvulso" placeholder="Valor">
              </mat-form-field>
              <mat-form-field appearance="outline" color="primary" style="min-width: 400px;">
                  <input matInput type="text" placeholder="Observação" [(ngModel)]="txtObservacaoBoletoAvulso">
              </mat-form-field>
              <mat-form-field appearance="outline" style="max-width: 180px;">
                  <mat-label>Data Vencimento</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="dtBoletoAvulso" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <button mat-button color="primary" (click)="btnCadastrarBoletoAvulso()" style="height: 55px;">Cadastrar Boleto Avulso</button>
          </div>
          <section style="margin-top: 15px;">
            <button mat-button color="primary" [disabled]="!showBtnRegistratTodosBoletos()"
                (click)="btnRegistrarTodosBoleto()">Registrar Todos Boletos</button>
            <button mat-button color="accent" style="margin-left: 16px;" disabled>Cancelar Todos Boletos</button>
        </section>
        </div>

        <app-alert *ngIf="boletos_com_erro_de_registro.length" class="alert" type="warning">
          <h2>Avisos</h2>
          <ul>
            <li *ngFor="let item of boletos_com_erro_de_registro">
              Código do boleto: <strong>{{item.boletoId}}</strong> - {{item.message}}
            </li>
          </ul>
        </app-alert>

        <div class="materialTableHeader d-flex">
          <h3>Boletos</h3>
        </div>
        <table mat-table [dataSource]="recebimento.boletos">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="banco_nome">
            <th mat-header-cell *matHeaderCellDef>Banco</th>
            <td mat-cell *matCellDef="let element">{{element.banco_nome}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="banco_nosso_numero">
            <th mat-header-cell *matHeaderCellDef>N. número</th>
            <td mat-cell *matCellDef="let element">{{element.banco_nosso_numero}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="valor_documento">
            <th mat-header-cell *matHeaderCellDef>Valor doc.</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'red': element.status !== 'ABERTO'}">{{this.formataValorParaBRL(element.valor_documento)}}</td>
            <td mat-footer-cell *matFooterCellDef>{{ somaDosBoletos.valor_documento | currency: 'R$' }}</td>
          </ng-container>

          <ng-container matColumnDef="valor_desconto">
            <th mat-header-cell *matHeaderCellDef>Desconto</th>
            <td mat-cell *matCellDef="let element">{{this.formataValorParaBRL(element.valor_desconto)}}</td>
            <td mat-footer-cell *matFooterCellDef>{{ somaDosBoletos.valor_desconto | currency: 'R$' }}</td>
          </ng-container>

          <ng-container matColumnDef="valor_cobrar">
            <th mat-header-cell *matHeaderCellDef>Valor cobrado</th>
            <td mat-cell *matCellDef="let element">{{this.formataValorParaBRL(element.valor_cobrar)}}</td>
            <td mat-footer-cell *matFooterCellDef>{{ somaDosBoletos.valor_cobrar | currency: 'R$' }}</td>
          </ng-container>

          <ng-container matColumnDef="data_documento">
            <th mat-header-cell *matHeaderCellDef>Data Doc.</th>
            <td mat-cell *matCellDef="let element">{{this.formataData(element.data_documento)}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="data_vencimento">
            <th mat-header-cell *matHeaderCellDef>Vencimento</th>
            <td mat-cell *matCellDef="let element">{{this.formataData(element.data_vencimento)}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <div [ngSwitch]="element.status">
                <div *ngSwitchCase="BOLETO_STATUS.SEM_REGISTRO">
                    <button mat-button (click)="btnRegistrarBoleto(element)" color="warn">
                        Sem registro
                    </button>
                </div>
                <div *ngSwitchCase="'REGISTRANDO'">
                    <mat-spinner diameter="24"></mat-spinner>
                </div>
                <div *ngSwitchCase="BOLETO_STATUS.IMPRESSAO_PENDENTE">
                    <button mat-button color="primary" (click)="alternarStatusImpresso(element)">
                        Impressão Pendente
                    </button>
                </div>
                <div *ngSwitchCase="BOLETO_STATUS.IMPRESSO">
                  <button mat-button color="primary" (click)="alternarStatusImpresso(element)">
                      Impresso
                  </button>
                </div>
                <div *ngSwitchCase="BOLETO_STATUS.CANCELADO">
                  <button mat-button color="accent">
                      Cancelado
                  </button>
              </div>
              <div *ngSwitchCase="BOLETO_STATUS.QUITADO">
                <button mat-button class="status_quitado">
                    Quitado
                </button>
            </div>
            </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
               {{ element.modo_gerado | normalize }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="boleto_pai_id">
            <th mat-header-cell *matHeaderCellDef>Vinculado?</th>
            <td mat-cell *matCellDef="let element">
              <app-chip *ngIf="element.boleto_pai_id; else semVinculo;" type="warning" [label]="'#ID: ' + element.boleto_pai_id"></app-chip>
              <ng-template #semVinculo>---</ng-template>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="data.status !== BOLETO_STATUS.CANCELADO">
                <button *ngIf="(data.status == BOLETO_STATUS.IMPRESSO || data.status == BOLETO_STATUS.IMPRESSAO_PENDENTE) && !data.boleto_pai_id"
                    (click)="vincularBoleto(data)" mat-icon-button
                    class="small-icon-button"
                    matTooltip="Vincular Boleto">
                  <mat-icon>sync_alt</mat-icon>
                </button>
                <button *ngIf="data.status !== BOLETO_STATUS.SEM_REGISTRO"
                    (click)="visualizarBoleto(data.id)" mat-icon-button
                    class="small-icon-button"
                    matTooltip="Visualizar">
                    <mat-icon>
                      visibility
                    </mat-icon>
                </button>
                <button *ngIf="data.status === BOLETO_STATUS.IMPRESSO || data.status === BOLETO_STATUS.IMPRESSAO_PENDENTE"
                    (click)="quitarBoleto(data)"
                    mat-icon-button
                    class="small-icon-button"
                    matTooltip="Quitar Boleto">
                    <mat-icon>attach_money</mat-icon>
                </button>
                <button *ngIf="data.status !== BOLETO_STATUS.QUITADO && data.filhos_abertos == 0" (click)="btnCancelarBoleto(data)" mat-icon-button
                    color="warn"
                    matTooltip="Cancelar Boleto">
                    <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="data.status === BOLETO_STATUS.SEM_REGISTRO"
                  (click)="btnAlterarVencimentoBoleto(data)" mat-icon-button
                  class="small-icon-button"
                  matTooltip="Alterar Vencimento do Boleto">
                  <mat-icon>event</mat-icon>
                </button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <button mat-button (click)="imprimirBoletos()">Imprimir Boletos</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
          <tr mat-footer-row *matFooterRowDef="columns"></tr>
        </table>

    </ng-template>
</div>
