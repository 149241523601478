import { PontosVendasProdForComissoesDescModel } from '@/models/pontos-vendas-prod-for-comissoes-desc.model';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@/environments/environment';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PontosVendasProdForComissoesDescService {

  url = environment.baseURL;
  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}
  // getAllPontosVendasProdForComissoesPadroess() {
  //   return this.httpClient
  //     .get<PontosVendasProdForComissoesDescModel[]>(
  //       `${this.url}/pontos-vendas-prod-for-comissoes-desc`
  //     )
  //     .pipe(catchError(this.authService.handleError as any));
  // }
  getAllPontosVendasProdForComissoesDesc() {
    return this.httpClient
      .get<PontosVendasProdForComissoesDescModel[]>(
        `${this.url}/pontos-vendas-prod-for-comissoes-desc`
      )
      .pipe(catchError(this.authService.handleError as any));
  }
}


