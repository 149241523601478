import { Injectable } from '@angular/core';
import { BaseApi } from './base/base-api.service';
import { BoletoSimple } from '@/models/dto/boleto.simple';
import { NovoBoletoDto } from '@/models/dto/novo-boleto.dto';
import { HttpClient } from '@angular/common/http';
import { BoletoStatus } from '@/enums/BoletoStatus';
import { Observable } from 'rxjs';
import { VincularBoletoDto } from '@/models/dto/vincular-boleto.dto';
import { QuitarBoltoDto } from '@/models/dto/quitar-boleto.dto';

@Injectable({
  providedIn: 'root',
})
export class BoletoService extends BaseApi {

  registrarBoleto(id:number){
    return this.post(`${this.url}/boletos/registrar/${id}`, null)
  }

  cadastrarBoletoAvulso(idRec:number, valor:number, obs:string, dataVencimento:Date|string){
    const boleto = new NovoBoletoDto()
    boleto.recebimento_id = +idRec;
    boleto.tipo = 'AVULSO';
    boleto.observacao = obs;
    boleto.valor = valor;
    boleto.data_vencimento = dataVencimento;
    return this.post<BoletoSimple>(`${this.url}/boletos/novo-boleto`, boleto)
  }

  pdf(boletoId: number) {
    return this.get(`${this.url}/boletos/${boletoId}/pdf`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob',
    })
  }

  cancelar(boletoId: number, data: QuitarBoltoDto) {
    return this.put<BoletoSimple>(`${this.url}/boletos/${boletoId}/cancelar`, data)
  }

  alternarStatusImpressao(boletoId: number): Observable<{status: BoletoStatus}> {
    return this.put(`${this.url}/boletos/${boletoId}/alternar-status-impresso`, null)
  }

  vincular(boletoId: number, data: VincularBoletoDto) {
    return this.post(`${this.url}/boletos/${boletoId}/vincular`, data)
  }

  quitar(boletoId: number, data: QuitarBoltoDto) {
    return this.post(`${this.url}/boletos/${boletoId}/quitar`, data)
  }

  alterarVencimento(boletoId: number, data: any) {
    console.log(boletoId, data.dataVencimentoFormatada)
    return this.put(`${this.url}/boletos/${boletoId}/alterar-data-vencimento`, data)
  }

  mergePdfBoletos(boleto_ids: number[]) {
    return this.get(`${this.url}/boletos/pdf`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob',
      params: {
        boleto_ids
      }
    })
  }
}
