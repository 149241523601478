import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseApi } from './base/base-api.service';
import { TransacoesPixModel } from '@/models/transacoes-pix.model';

@Injectable({
  providedIn: 'root',
})
export class TransacoesPixService extends BaseApi {

  async ultimos24Horas(): Promise<TransacoesPixModel[]> {
    return lastValueFrom(this.get(`${this.url}/transacoes/pix/ultimos-24-horas`));
  }

  async estornar(transacao_id: number): Promise<{message: string}> {
    return lastValueFrom(this.post(`${this.url}/transacoes/pix/estornar`, {
      transacao_id
    }));
  }
}
