import { UsuarioModel } from '@/models/usuario.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, Observable, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { UsuarioDto } from '@/models/dto/usuario.dto';
import { Gerar2FaSecret } from '@/models/gerar-2fa-secret.model';
import { UpdatePasswordDto } from '@/models/dto/update-password.dto';
@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  url = environment.baseURL;
  ons$ = timer(10, 1)
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  createUsuario(usuario: UsuarioDto) {
    return this.httpClient
      .post<UsuarioDto>(`${this.url}/usuario`, usuario)
      .pipe(catchError(this.authService.handleError as any));
  }

  findAllUsuarios(query?: string): Observable<UsuarioModel[]> {
    return this.httpClient
      .get<UsuarioModel[]>(`${this.url}/usuario?${query}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  findOneUsuario(id: number): Observable<UsuarioDto> {
    return this.httpClient
      .get<UsuarioDto>(`${this.url}/usuario/${id}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  updateUsuario(id: number, usuario: UsuarioDto) {
    return this.httpClient
      .put<UsuarioDto>(`${this.url}/usuario/${id}`, usuario)
      .pipe(catchError(this.authService.handleError as any));
  }

  toggleUsuarioStatus(id: number, status: string) {
    return this.httpClient
      .put(`${this.url}/usuario/toggle/${id}`, { status })
      .pipe(catchError(this.authService.handleError as any));
  }

  gerar2FaSecret() {
    return lastValueFrom(this.httpClient.post<Gerar2FaSecret>(`${this.url}/usuario/gerar-2fa-secret`, null))
  }

  ativar2Fa() {
    return lastValueFrom(this.httpClient.post(`${this.url}/usuario/ativar-2fa`, null))
  }

  updatePassword(data: UpdatePasswordDto) {
    return this.httpClient
      .put(`${this.url}/usuario/change-password`, data)
      .pipe(catchError(this.authService.handleError as any));
  }
}
