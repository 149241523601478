import { TerminaisModeloModel } from '@/models/terminais-modelo.model';
import { TerminalFirmware } from '@/models/terminal-firmware.model';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { TerminaisFirmwareService } from '@/services/terminais-firmware.service';
import { TerminaisModeloService } from '@/services/terminais-modelo.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-terminal-firmware',
  templateUrl: './create-terminal-firmware.component.html',
  styleUrls: ['./create-terminal-firmware.component.scss']
})
export class CreateTerminalFirmwareComponent implements OnInit {
  @Output()
  created: EventEmitter<TerminalFirmware> = new EventEmitter()

  form!: FormGroup
  terminaisModelos: TerminaisModeloModel[] = []
  perfis: {firmware_perfil: string}[] = []
  servidores: {servidor: string}[] = []

  constructor(
    private fb: FormBuilder,
    private terminaisModeloService: TerminaisModeloService,
    private terminaisFirmwareService: TerminaisFirmwareService,
    private swal2: SweetAlert2Service
  ) {
    this.form = this.fb.group({
      modelo_id: [null],
      perfil: [null],
      versao: [''],
      caminho: [''],
      arquivo: [''],
      servidor: [''],
      descricao: ['']
    })
  }

  async fetchTerminaisModelos() {
    this.terminaisModeloService.findAllTerminaisModelo()
      .subscribe({
        next: (data) => {
          this.terminaisModelos = data
        }
      })
  }

  async fetchTerminaisFirmwarePerfis() {
    const perfis = await this.terminaisFirmwareService.perfis();
    this.perfis = perfis
    const servidores = await this.terminaisFirmwareService.ftps();
    this.servidores = servidores
  }

  async sendForm() {
    const data = await this.terminaisFirmwareService.create({
      descricao: this.form.value['descricao'],
      arquivo: this.form.value['arquivo'],
      terminal_firmware_ftp_id: this.form.value['servidor']?.id,
      caminho: this.form.value['caminho'],
      versao: this.form.value['versao'],
      terminal_modelo_id: this.form.value['modelo_id']?.id,
      firmware_perfil: this.form.value['perfil']?.firmware_perfil,
    })
    this.created.emit(data);
    this.form.reset();

    this.swal2.success('Criado com sucesso!')
  }

  ngOnInit() {
    this.fetchTerminaisModelos();
    this.fetchTerminaisFirmwarePerfis();
  }
}
