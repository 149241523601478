import { Component, Input, OnInit } from '@angular/core';

type AlertType = 'success' | 'warning' | 'danger'

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() type: AlertType = 'success'
  constructor() { }

  ngOnInit(): void {
  }

}
