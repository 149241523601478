<div class="page">
 @if(subjectId) {
  <div class="heading">
    <h1>Editar mensagem</h1>
    <button mat-flat-button color="primary" [routerLink]="['/administracao/mensagens-terminais/'+ subjectId + '/detalhes']">
      Visão Geral da Mensagem
    </button>
  </div>
 }

 @else {
  <h1>Cadastrar mensagem para terminais</h1>
 }

  <mat-card appearance="outlined" class="card">
    <mat-card-content>
      <mat-stepper [linear]="true" #stepper>
        <mat-step
          label="Selecione os terminais a serem notificados"
        >
          <form class="form form__table">
            <div class="search">
              <mat-form-field appearance="outline">
                <mat-label>Pesquisar</mat-label>
                <mat-icon matSuffix>search</mat-icon>
                <input
                  matInput
                  type="text"
                  placeholder="PDV ou Serial do Terminal"
                  (keydown.enter)="resetPagination()"
                  [formControl]="terminalSearch"
                />
              </mat-form-field>
            </div>
          </form>

          <div>
            <div>
              @if(isAllElementsFromCurrentPageSelected) {
                <div class="selecionar-tudo">
                  <mat-icon color="warn">error</mat-icon>
                  <span>
                    Todos os itens dessa página foram selecionado, para selecionar todos os <strong>{{terminals.ids.length}}</strong> itens
                    <button mat-flat-button color="primary" (click)="selectAllElements()">clique aqui.</button>
                  </span>
                </div>
              }

              <small><strong>{{ selection.selected.length }}</strong> terminais selecionado(s).</small>
            </div>
            <div class="scroll-container">
              <div class="materialTableHeader">
                <h3>Terminais</h3>
              </div>
              <table mat-table [dataSource]="terminals.data">
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()"
                      color="primary"
                    >
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row.id) : null"
                      [checked]="selection.isSelected(row.id)"
                      [aria-label]="checkboxLabel(row)"
                      color="primary"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ponto_venda">
                  <th mat-header-cell *matHeaderCellDef>Ponto de Venda</th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.pontovenda.id +
                        " - " +
                        element.pontovenda.nome_comercial
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="pontovenda.status">
                  <th mat-header-cell *matHeaderCellDef>Status PDV</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pontovenda.status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="serial">
                  <th mat-header-cell *matHeaderCellDef>Serial</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.serial }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="modelo">
                  <th mat-header-cell *matHeaderCellDef>Modelo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.modelo.nome }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status Terminal</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.status }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: columns"
                  (click)="selection.toggle(row)"
                ></tr>
              </table>
            </div>
            <mat-paginator
              [length]="terminals.total"
              [pageSize]="terminals.per_page"
              [pageIndex]="terminals.current_page - 1"
              (page)="onPaginate($event)"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
          <div class="actions">
            <button
              mat-flat-button
              color="warn"
              [routerLink]="['/administracao/mensagens-terminais']"
            >
              Voltar
            </button>
            <button
              mat-flat-button
              color="primary"
              matStepperNext
              [disabled]="!this.selection.selected.length"
            >
              Avançar
            </button>
          </div>
        </mat-step>
        <mat-step
          [stepControl]="form"
          label="Adicione os dados da mensagem"
          [editable]="false"
        >
          <form (submit)="sendForm()" [formGroup]="form">
            <div class="grid grid-cols-3 gap-4">
              <app-input-text
                class="descricao col-lg-12 col-md-12 col-sm-12 col-xs-12"
                label="Descrição"
                formControlName="descricao"
              ></app-input-text>
              <mat-form-field appearance="outline" class="datepicker w-full">
                <mat-label>Data início</mat-label>
                <input
                  type="datetime-local"
                  matInput
                  placeholder="Data Início"
                  formControlName="periodo_inicio"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="datepicker w-full">
                <mat-label>Data fim</mat-label>
                <input
                  type="datetime-local"
                  matInput
                  placeholder="Data Fim"
                  formControlName="periodo_fim"
                />
              </mat-form-field>
            </div>
            <div>
              <div class="mensagem">
                <ngx-editor-menu
                  [editor]="editor"
                  [toolbar]="toolbar"
                ></ngx-editor-menu>
                <ngx-editor
                  (paste)="onPaste($event)"
                  [editor]="editor"
                  formControlName="mensagem"
                  placeholder="Digite a mensagem aqui..."
                ></ngx-editor>
              </div>
            </div>
          </form>

          <div class="actions">
            <button mat-flat-button color="warn" matStepperPrevious>
              Voltar
            </button>
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="!this.selection.selected.length || isSendingForm"
              (click)="sendForm()"
              
            >
              {{ subjectId ? 'Atualizar' : 'Cadastrar' }}
            </button>
          </div>
        </mat-step>
      </mat-stepper>
    </mat-card-content>
  </mat-card>
</div>
