<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title> Trocar Senha </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="handleSubmit()">
        <app-input-text
          type="password"
          formControlName="currentPassword"
          placeholder="Informe sua senha atual"
          label="Senha atual"
          required
        />

        <app-input-text
          type="password"
          formControlName="password"
          label="Nova senha"
          required
        />

        <app-input-text
          type="password"
          formControlName="passwordConfirm"
          label="Confirme a sua nova senha"
          required
        />
        <button
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="form.invalid"
        >
          Confirmar
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
