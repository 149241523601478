import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseApi } from './base/base-api.service';
import { PontosVendaAgendaFaturamento } from '@/models/pontos-venda-agenda-faturamento';
import { UpdateFaturamentoPdvAgenda } from '@/models/dto/update-faturamento-pdv-agenda.dto';

@Injectable({
  providedIn: 'root',
})
export class PontosVendaAgendaFaturamentoService extends BaseApi {
  async findAll(query: {ponto_venda_id?: number}): Promise<PontosVendaAgendaFaturamento[]> {
    return lastValueFrom(this.get(`${this.url}/faturamentos-pdv-agendas`, {
      params: query
    }))
  }
  async updateByPontoVendaId(pdvId: number, data: UpdateFaturamentoPdvAgenda): Promise<PontosVendaAgendaFaturamento> {
    return lastValueFrom(this.put(`${this.url}/faturamentos-pdv-agendas/ponto-venda/${pdvId}`, data))
  }
}
