import { InputComponent } from '../../shared/input/input.component';
import {
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';

type Value = string | Record<string, any>;

@Component({
  selector: 'app-input-select-autocomplete',
  templateUrl: './input-select-autocomplete.component.html',
  styleUrls: ['./input-select-autocomplete.component.scss'],
})
export class InputSelectAutocompleteComponent
  extends InputComponent<Value>
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() options: Value[] = [];
  @Input() optionLabel: string = 'name';

  filteredOptions: Observable<Value[]> | undefined;
  inputFormControl = new FormControl();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['options'] &&
      changes['options'].currentValue !== changes['options'].previousValue
    ) {
      this.options = changes['options'].currentValue;
      this.inputFormControl.reset();
    }
  }

  override ngOnInit(): void {
    this.filteredOptions = this.inputFormControl.valueChanges.pipe<
      any,
      Value[]
    >(
      startWith(''),
      map((value) => this._filter(value)),
    );
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.inputFormControl.valueChanges.subscribe(() => {
      if (
        !this.inputFormControl.value ||
        this.options.includes(this.inputFormControl.value)
      ) {
        this.onChange(this.inputFormControl.value);
      }
    });
  }

  _filter = (value: Value): Value[] => {
    const filterValue = this._optionLabel(value).toLowerCase();
    return this.options.filter((option) =>
      this._optionLabel(option).toLowerCase().includes(filterValue),
    );
  };

  override writeValue(value: Value): void {
    this.inputFormControl.setValue(value);
  }
  override setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.inputFormControl.disable()
      : this.inputFormControl.enable();
  }

  _optionLabel(option: Value): string {
    if (typeof option === 'string') {
      return option;
    }
    return option?.[this.optionLabel] || '';
  }

  checkOption() {
    setTimeout(() => {
      const value = this.inputFormControl.value;
      if (value && !this.options.includes(value)) {
        this.inputFormControl.reset();
      }
    }, 1000);
  }

  optionClick(event: MatAutocompleteSelectedEvent) {
    // this.inputFormControl.setValue(event.option.value);
  }

  displayFn = (value: Value): string => {
    return this._optionLabel(value);
  };
}
