import {
  HipercapPdvProduct,
  HipercapProduct,
} from '@/models/hipercap-product.model';
import { Pagination } from '@/models/pagination.model';
import { PontosDeVendaModel } from '@/models/pontos-de-venda';
import { HipercapService } from '@/services/hipercap.service';
import { PontosDeVendaService } from '@/services/pontos-de-venda.service';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { HipercapProductDialogComponent } from './components/hipercap-product-dialog/hipercap-product-dialog.component';
import { HipercapMassUpdateProductDialogComponent } from './components/hipercap-mass-update-product-dialog/hipercap-mass-update-product-dialog.component';
import { debounceTime } from 'rxjs';

type PdvSearch = PontosDeVendaModel & {
  search: string;
};

@Component({
  selector: 'app-hipercap-produtos',
  templateUrl: './hipercap-produtos.component.html',
  styleUrl: './hipercap-produtos.component.scss',
})
export class HipercapProdutosComponent {
  tableColumns: string[] = [];
  tableData: Pagination<HipercapPdvProduct> = {
    data: [],
    current_page: 1,
    last_page: 0,
    per_page: 20,
    total: 0,
  };
  data: any[] = [];
  hipercapProducts: HipercapProduct[] = [];
  pontosDeVendas: PdvSearch[] = [];
  pdvSearch = new FormControl({ value: '', disabled: false });

  form: FormGroup;

  constructor(
    private service: HipercapService,
    private fb: FormBuilder,
    private pontoVendaService: PontosDeVendaService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      pdv_id: [''],
      product_id: [''],
    });
  }

  productIsActive(
    products: Array<{ id: number; descricao: string; active: boolean }>,
    productId: number
  ) {
    return products.find((product) => product.id === productId)?.active;
  }

  onPdvSelect(data: MatAutocompleteSelectedEvent) {
    this.form.patchValue({
      pdv_id: data.option.value?.id,
    });
  }

  loadPdvs() {
    this.pontoVendaService.findAllPontosDeVenda().subscribe({
      next: (data) => {
        this.pontosDeVendas = data.map((pdv) => ({
          ...pdv,
          search: `${pdv.id} - ${pdv.nome_comercial}`,
        }));
      },
    });
  }

  displayFn(pontoVenda: PdvSearch) {
    if (!pontoVenda) {
      return '';
    }

    return pontoVenda?.search;
  }

  get pdvFiltered() {
    const { pdv } = this.form.value;
    if (!pdv) return this.pontosDeVendas;
    const regex = new RegExp(pdv, 'i');
    return this.pontosDeVendas.filter((option) => regex.test(option.search));
  }

  async getHipercapProducts() {
    const data = await this.service.getProducts();
    this.hipercapProducts = data;
  }

  async getData() {
    const data: any = await this.service.getProductsPdv({
      ...this.form.value,
      page: this.tableData.current_page,
    });

    if (!this.tableColumns.length) {
      const products = this.hipercapProducts?.map(
        (item: any) => `product_${item.id}`
      );

      this.tableColumns = ['pdv', ...products, 'acoes'];
    }
    this.tableData = data;
  }

  onPaginate(data: PageEvent) {
    this.tableData.current_page = data.pageIndex + 1;
    this.getData();
  }

  showEditDialog(data: HipercapPdvProduct) {
    const dialogRef = this.dialog.open(HipercapProductDialogComponent, {
      data: {
        data,
        products: this.hipercapProducts,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (data) => {
        if (data.success) {
          this.getData();
        }
      },
    });
  }

  showMassUpdateDialog() {
    const dialogRef = this.dialog.open(
      HipercapMassUpdateProductDialogComponent,
      {
        data: this.hipercapProducts,
        width: '1200px',
      }
    );

    dialogRef.afterClosed().subscribe({
      next: (data) => {
        if (data.success) {
          this.getData();
        }
      },
    });
  }

  async ngOnInit() {
    this.loadPdvs();
    await this.getHipercapProducts();
    this.getData();

    this.form.valueChanges.subscribe((data) => {
      this.getData();
    });

    this.pdvSearch.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      if (!data) {
        this.form.patchValue({ pdv_id: '' });
        this.getData();
      }
    });
  }
}
