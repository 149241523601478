import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import jwt_decode, { InvalidTokenError } from 'jwt-decode';
import * as dayjs from 'dayjs';

declare interface RefreshTokenDecoded {
  expiresIn: number;
}
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const currentToken = localStorage.getItem('token');
    const refresh_token = localStorage.getItem('refreshToken');

    const req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${currentToken}`,
      },
    });

    return next.handle(req).pipe(
      catchError((err: any) => {
        const erro: any = this.authService.handleError(err, false);
        if (erro.message === "Refresh Token invalido") {
          this.authService.resetUser();
          return throwError(() => err);
        }

        if ((erro.status === 401)) {
          try {
            const refreshTokenDecoded: RefreshTokenDecoded = jwt_decode(refresh_token as string);
            const refreshTokenExpired = dayjs().isAfter(dayjs.unix(refreshTokenDecoded.expiresIn));

            if (refreshTokenExpired) {
              this.authService.resetUser();
              return throwError(() => err);
            }

            return this.handle401Error(request, next, refresh_token!)
          } catch(error: any) {
            if(error instanceof InvalidTokenError) {
              this.authService.resetUser();
            }
          }
        }

        return throwError(err);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, refreshToken: string) {
    if(!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService
        .callRefreshToken({ refreshToken: refreshToken })
        .pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            localStorage.setItem('token', token.access_token);
            console.warn('Token was refreshed');
            this.refreshTokenSubject.next(token.access_token);

            return next.handle(this.addTokenHeader(request, token.access_token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.resetUser();
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}
