import { BoletoSimple } from '@/models/dto/boleto.simple';
import { FaturamentoRecebimenetoDto } from '@/models/dto/faturamento-recebimento.dto';
import { BoletoService } from '@/services/boleto.service';
import { FaturamentosRecebimentosService } from '@/services/faturamentos-recebimento.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BoletoStatus } from '@/enums/BoletoStatus';
import { SharedService } from 'src/app/shared/shared.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ModalVincularBoletoComponent } from './components/modal-vincular-boleto/modal-vincular-boleto.component';
import { ModalAlterarVencimentoBoletoComponent } from './components/modal-alterar-vencimento-boleto/modal-alterar-vencimento-boleto.component';
import * as dayjs from 'dayjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-recebimento',
  templateUrl: './recebimento.component.html',
  styleUrls: ['./recebimento.component.scss']
})
export class RecebimentoComponent implements OnInit {
  columns: string[] = ['id', 'banco_nome', 'banco_nosso_numero', 'valor_documento', 'valor_desconto', 'valor_cobrar', 'data_documento', 'data_vencimento', 'status', 'tipo', 'boleto_pai_id', 'actions']
  recebimento: FaturamentoRecebimenetoDto;
  txtValorBoletoAvulso: number;
  txtObservacaoBoletoAvulso: string;
  dtBoletoAvulso:Date = new Date();
  boletos_com_erro_de_registro: {boletoId: number, message?:string}[] = []
  BOLETO_STATUS = BoletoStatus
  datasFaturamentoIguais : boolean = false
  faturamentoRecebimentoId!: number

  inputSearch = new FormControl(null);

  constructor(
    private recebimentoService: FaturamentosRecebimentosService,
    private boletoService: BoletoService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private sweetAlert: SweetAlert2Service,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sharedService.changeAuth(true)
    const fatRecId = this.route.snapshot.paramMap.get('idRec');
    if (fatRecId) {
      this.faturamentoRecebimentoId = +fatRecId
      this.getRecebimento();
    }
  }

  btnRegistrarBoleto(bol: BoletoSimple) {
    bol.status = 'REGISTRANDO';
    this.boletoService.registrarBoleto(bol.id)
      .subscribe({
        next: (value: any) => {
          bol.status = BoletoStatus.IMPRESSAO_PENDENTE
        },
        error: (err) => {
          this.boletos_com_erro_de_registro.push({
            boletoId: bol.id,
            message: err?.error?.message || err?.message,
          })
          bol.status = BoletoStatus.SEM_REGISTRO
        },
      })
  }

  btnRegistrarTodosBoleto() {
    this.boletos_com_erro_de_registro = [];
    this.recebimento.boletos.filter(b => b.status == BoletoStatus.SEM_REGISTRO)
      .forEach(b => this.btnRegistrarBoleto(b))
  }

  get somaDosBoletos() {
    return this.recebimento?.boletos.reduce((acc, current) => {
      return {
        valor_cobrar: acc.valor_cobrar + Number(current.valor_cobrar),
        valor_desconto: acc.valor_desconto + Number(current.valor_desconto),
        valor_documento: acc.valor_documento + Number(current.valor_documento),
      }
    }, {
      valor_documento: 0,
      valor_desconto:0,
      valor_cobrar: 0
    })
  }

  btnCadastrarBoletoAvulso() {
    if(!this.txtValorBoletoAvulso || !this.txtObservacaoBoletoAvulso) {
      this.sweetAlert.info('Preencha todos os campos"')
      return;
    }

    if(parseInt(this.txtValorBoletoAvulso.toString()) === 0) {
      this.sweetAlert.info('O valor precisa ser acima de R$1,00')
      return;
    }

    this.boletoService.cadastrarBoletoAvulso(this.recebimento.id,
      this.txtValorBoletoAvulso,
      this.txtObservacaoBoletoAvulso,
      dayjs(this.dtBoletoAvulso).format('YYYY-MM-DD')
      )
      .subscribe({
        next: (v) => {
          this.getRecebimento();
          this.sweetAlert.success('Boleto cadastrado com sucesso!')
          this.txtValorBoletoAvulso = 0
          this.txtObservacaoBoletoAvulso = ''
        },
        error: (e) => {
          this.sweetAlert.error(e?.message || 'Algo inesperado aconteceu.')
        },
        complete: () => console.info('getComplete','complete')
      })
  }

  btnCancelarBoleto(boleto: BoletoSimple) {
    this.sweetAlert.build({
      title: 'Atenção',
      icon: 'question',
      text: 'Deseja realmente cancelar o boleto?',
      showDenyButton: true,
      confirmButtonText: 'Sim, cancelar',
      denyButtonText: 'Não',
      input: 'text',
      inputPlaceholder: 'Observação',
      inputAttributes: {
        autocapitalize: 'off'
      },
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(
            '<i class="fa fa-info-circle"></i> Observação necessária'
          )
        }
      }
    }).then(result => {
      if(result.isConfirmed)  {
        this.sweetAlert.loading()
        this.boletoService.cancelar(boleto.id, {
          novoStatus: "CANCELADO",
          obs: result.value
        })
          .subscribe({
            next: data => {
              boleto.status = data.status
            },
            error: (err) => {
              this.sweetAlert.error(err?.message)
            },
            complete: () => this.sweetAlert.hideLoading(),
          })
      }
    })
  }

  gerarBoletoAvulso() {
    this.recebimento.boletos.filter(b => b.status == BoletoStatus.SEM_REGISTRO)
      .forEach(b => this.btnRegistrarBoleto(b))
  }

  showBtnRegistratTodosBoletos() {
    return this.recebimento.boletos.filter(b => b.status == BoletoStatus.SEM_REGISTRO).length > 0
  }

  getRecebimento(){
    if (this.faturamentoRecebimentoId) {
      this.recebimentoService.findById(+this.faturamentoRecebimentoId)
      .subscribe(rec => {
        this.recebimento = rec;
        this.comparaDatas(rec.data_inicio, rec.data_fim)
      })
    }
  }

  visualizarBoleto(id: number) {
    this.boletoService.pdf(id)
      .subscribe(data => {
        window.open(window.URL.createObjectURL(data as Blob), '_blank');
      })
  }

  comparaDatas(dataInicio: Date, dataFim: Date){
    if(this.datePipe.transform(dataInicio, 'dd/MM/yyyy') === this.datePipe.transform(dataFim, 'dd/MM/yyyy')){
      this.datasFaturamentoIguais = true;
    }
  }

  alternarStatusImpresso(boleto: BoletoSimple) {
    const newStatusDescription = boleto.status === BoletoStatus.IMPRESSO ? 'Impressão Pendente' : 'Impresso';
    this.sweetAlert.confirm(`Deseja alterar o status do boleto para "${newStatusDescription}" ?`)
    .then(result => {
      if(result.isConfirmed) {
        this.sweetAlert.loading()
        this.boletoService.alternarStatusImpressao(boleto.id)
          .subscribe({
            next: data => boleto.status = data.status,
            complete: () => this.sweetAlert.hideLoading(),
            error: error => {
              this.sweetAlert.hideLoading()
              this.sweetAlert.error(error?.message)
            }
          })
      }
    })
  }

  vincularBoleto(boleto: BoletoSimple) {
    const formatToBRL = new Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"})
    const valorDocumento = formatToBRL.format(boleto.valor_documento);
    this.sweetAlert.confirm(`Deseja gerar um novo boleto e vincula-lo ao de ID: ${boleto.id} e valor ${valorDocumento}`)
      .then(result => {
        if(result.isConfirmed) {
          const dialogData = this.dialog.open(ModalVincularBoletoComponent, {
            data: {
              recebimentoId: this.faturamentoRecebimentoId,
              boletoId: boleto.id,
              valorDocumento: boleto.valor_documento
            }
          })

          dialogData.afterClosed()
            .subscribe(status => {
              if(status) {
                this.getRecebimento()
              }
            })
        }
      })
  }

  async btnAlterarVencimentoBoleto(boleto: BoletoSimple) {
    this.sweetAlert.confirm(`Deseja realmente alterar a data de vencimento do boleto com ID: ${boleto.id} ?`)
      .then(result => {
        if(result.isConfirmed) {
          const dialogData = this.dialog.open(ModalAlterarVencimentoBoletoComponent, {
            data: {
              recebimentoId: this.faturamentoRecebimentoId,
              boletoId: boleto.id,
              valorDocumento: boleto.valor_documento
            }
          })

          dialogData.afterClosed()
            .subscribe(status => {
              if(status) {
                this.getRecebimento()
              }
            })
        }
      })
  }

  async quitarBoleto(boleto: BoletoSimple) {
    const formatToBRL = new Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"})
    const valorDocumento = formatToBRL.format(boleto.valor_documento);

    let result = await this.sweetAlert.build({
      title: `Deseja realmente quitar o boleto?`,
      icon: 'question',
      html: `ID: ${boleto.id}<br> N. número: ${boleto.banco_nosso_numero}<br> Valor doc. ${valorDocumento}<br>`,
      input: 'text',
      inputPlaceholder: 'Observação',
      inputAttributes: {
        autocapitalize: 'off'
      },
      confirmButtonText: 'Quitar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3741',
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(
            '<i class="fa fa-info-circle"></i> Observação necessária'
          )
        }
      }
    })

    if(result.isConfirmed){
      console.log("me quitaram", result.value)
      this.boletoService.quitar(boleto.id, {
        novoStatus: "QUITADO",
        obs: result.value
      }).subscribe(() => {
        location.reload()
      })
    }
  }

  formataData(data:string) {
    return dayjs(data).format('DD/MM/YYYY')
  }

  formataValorParaBRL(valor: number) {
    const formatter = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2})
    return formatter.format(valor)
  }

  imprimirBoletos() {
    const boletosQualificados = this.recebimento.boletos
      .filter(boleto => boleto.status !== BoletoStatus.CANCELADO)
      .map(boleto => boleto.id)

    if(!boletosQualificados.length) {
      this.sweetAlert.info('Nenhum boleto disponível para impressão!')
      return;
    }

    return this.boletoService.mergePdfBoletos(boletosQualificados)
    .subscribe({
      next: data => window.open(window.URL.createObjectURL(data as Blob), '_blank')
    })
  }
}
