import { LancamentoTipos } from '@/enums/LancamentoTipos';
import { FaturamentoLancamentos } from '@/models/faturamentos.model';
import { LancamentosService } from '@/services/lancamentos.service';
import { CustomValidators } from '@/utils/custom-validators';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-lancamentos',
  templateUrl: './lancamentos.component.html',
  styleUrls: ['./lancamentos.component.scss']
})
export class LancamentosComponent implements OnInit {
  columns: string[] = ['id', 'produto_grupo', 'quantidade_transacoes', 'tipo', 'valor', 'descricao']
  recebimentoId: number
  lancamentos: FaturamentoLancamentos[] = [];
  form!: FormGroup
  loading = false;
  LANCAMENTO_TIPOS = LancamentoTipos

  constructor(
    private route: ActivatedRoute,
    private lancamentosService: LancamentosService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
  ){
    this.recebimentoId = +this.route.snapshot.params['idRec']

    this.form = this.formBuilder.group({
      valor: [0, [Validators.required, CustomValidators.moneyNonZero]],
      descricao: ['', [Validators.required]],
      tipo: [LancamentoTipos.CREDITO, [Validators.required]],
    })
  }

  btnRegistrarLancamento(){
    this.lancamentosService.createLancamentosByRecebimentoId(this.recebimentoId, this.form.value)
      .subscribe({complete: ()=> this.getLancamentos()})
  }

  formataValorParaBRL(valor: number) {
    const formatter = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2})
    return formatter.format(valor)
  }

  getLancamentos(){
    this.loading = true;
    this.lancamentosService.findLancamentosByRecebimentoId(this.recebimentoId)
      .subscribe({
        next: data => {
          let response = data.map(lancamento => {
            if(lancamento.produto_grupo === null){
              lancamento.produto_grupo = {id: 0, descricao: ""}
            }
            return lancamento
          })
          this.lancamentos = response
        },
        complete: () => this.loading = false
      })
  }

  ngOnInit(): void {
    this.sharedService.changeAuth(true)
    this.getLancamentos()
  }
}
