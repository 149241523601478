import { LoadingService } from '@/services/loading.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { RelatorioService } from '@/services/relatorio.service';
import { AlteraStatusRecargaModel } from '@/models/altera-status-recarga.model';
import * as dayjs from 'dayjs';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-altera-status-recarga',
  templateUrl: './altera-status-recarga.component.html',
  styleUrls: ['./altera-status-recarga.component.scss']
})
export class AlteraStatusRecargaComponent {
  columns: string[] = ['serial', 'data_hora', 'pdv', 'produto', 'forma_pagamento', 'valor', 'numero_identificador', 'fornecedor_nsu', 'actions']
  data: AlteraStatusRecargaModel[] = []

  constructor(
    private loading: LoadingService,
    private sharedService: SharedService,
    private swal: SweetAlert2Service,
    private relatorioService: RelatorioService,
    private currencyPipe: CurrencyPipe,
  ) {}

  async buscarRecargas() {
    try {
      this.loading.show()
      const data = await this.relatorioService.buscaRecargasPendentes()
      this.data = data
    } finally {
      this.loading.hide()
    }
  }

  confirmModal(data: any) {
    this.swal.build({
      icon: 'question',
      title: 'Deseja alterar o Status da recarga?',
      html: `
        <div style="text-align: left; padding: 0 10px;">
          <p>Data Hora: <b>${dayjs(data.data_hora).format('DD/MM/YYYY HH:mm:ss')}</b></p>
          <p>PVD: <b>${data.pv_id} - ${data.nome_comercial}</b></p>
          <p>Porduto: <b>${data.descricao} - ${data.produto}</b></p>
          <p>Valor: <b>${this.currencyPipe.transform(data.valor, 'BRL', '')}</b></p>
          <p>Serial: <b>${data.serial}</b></p>
          <p>Numero Indentificador: <b>${data.numero_identificador}</b></p>
          <p>Fornecedor NSU: <b>${data.fornecedor_nsu}</b></p>

          <div style="display: flex; align-items: center; text-align: center; justify-content: space-evenly;">
            <label for="selectOpcoes">Novo Status:</label>
            <select class="swal2-input" style="width: 190px;" id="selectOpcoes">
              <option value="CONCLUIDA">CONCLUIDA</option>
              <option value="ERRO">ERRO</option>
            </select>
          </div>
        </div>
        `,
      inputPlaceholder: 'Motivo',
      input: 'text',
      confirmButtonText: 'Salvar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      preConfirm: (motivo) => {
        const {value} = document.getElementById('selectOpcoes') as HTMLSelectElement;
        return {status: value, motivo}
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Motivo necessário!'
        } else return null
      }
    }).then(async response => {
      if(response.isConfirmed) {
        try {
          await this.relatorioService.alteraStatusRecarga({
            transacao_id: data.id,
            motivo: response.value.motivo,
            status_antigo: 'PENDENTE',
            status_novo: response.value.status,
            forma_pagamento: data.forma_pagamento,
          })
          this.swal.success('Status da recarga alterado!')
        } catch (err: any) {
          this.swal.error(err?.message || 'Algo inesperado aconteceu')
          return
        } finally {
          this.buscarRecargas()
          this.loading.hide()
        }
      }
    })
  }

  async ngOnInit() {
    this.sharedService.changeAuth(true)
    this.buscarRecargas()
  }
}
