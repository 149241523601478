import { FornecedoresModel } from './fornecedores.model';
import { PontosVendasProdForComissoesPadroesModel } from './pontos-vendas-prod-for-comissoes-padroes.model';
import { ProdutosGruposModel } from './produtos-grupos.model';

export class PontosVendasProdForComissoesDescModel {
  id?: number;
  descricao: string = '';
  created_at?: string;
  updated_at?: string;
  user_updated: number;
  pdvProdForComissoesPadroes?: PontosVendasProdForComissoesPadroesModel[];
  produtosgrupo?: ProdutosGruposModel;
  fornecedor?: FornecedoresModel;
}
