<div>
  <h1>Processamento CNAB</h1>

  <app-resultado-quitacao
    *ngIf="resultadoQuitacao?.boletos_quitados; else template"
    [resultado]="resultadoQuitacao"
    (finalizar)="resultadoQuitacao = null"
    ></app-resultado-quitacao>
  <ng-template #template>
    <div *ngIf="!table.data?.boletos?.length; else registros">
      <mat-card appearance="outlined" class="filtros">
        <mat-card-content>

          <span class="text-muted">Arquivo de retorno a ser quitado ou recebido.</span>
          <form [formGroup]="form">
            <app-input-select-autocomplete
              label="Informe o banco"
              [options]="bancos"
              formControlName="banco"
            ></app-input-select-autocomplete>
            <input hidden type="file" #inputFile (change)="handleSelectedFile($event)" />
            <div class="d-flex flex-column">
              <button mat-button class="btn-choose-file" (click)="inputFile.click()">
                <span *ngIf="file; else fallback">
                  Arquivo: {{file.name}} - {{file.size | formatFileSize}}
                </span>
                <ng-template #fallback>
                  <span class="d-flex align-items-center">
                    <mat-icon>upload_file</mat-icon>
                    <span>Escolher arquivo</span>
                  </span>
                </ng-template>
              </button>
            </div>
            <div>
              <button class="btn-processar" mat-raised-button (click)="handleForm()" [disabled]="form.invalid">
                Processar
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <ng-template #registros>
      <app-alert *ngIf="table.data.msg_retorno.length" class="alert" type="warning">
        <h2>Avisos</h2>
        <ul>
          <li *ngFor="let message of table.data.msg_retorno">
            {{message}}
          </li>
        </ul>
      </app-alert>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span>
          <strong>Empresa:</strong> SERVLOT SERVIÇOS E SISTEMAS
        </span>
        <div class="btns-action">
          <button mat-stroked-button color="primary" (click)="table.data.boletos = []">Voltar</button>
          <button mat-flat-button color="primary" (click)="quitar()">Quitar</button>
        </div>
      </div>

      <div class="materialTableHeader">
        <h3>{{table.data.boletos.length + ' Registros' }}</h3>
      </div>
      <table mat-table [dataSource]="table.data.boletos">
        <ng-container matColumnDef="pdvNome">
          <th mat-header-cell *matHeaderCellDef>Empresa</th>
          <td mat-cell *matCellDef="let element">{{element.pdvNome || '---'}}</td>
        </ng-container>

        <ng-container matColumnDef="nossoNumero">
          <th mat-header-cell *matHeaderCellDef>Nosso número</th>
          <td mat-cell *matCellDef="let element">{{element.nossoNumero}}</td>
        </ng-container>

        <ng-container matColumnDef="valor">
          <th mat-header-cell *matHeaderCellDef>Valor do título</th>
          <td mat-cell *matCellDef="let element">{{element.valor | currency: 'R$'}}</td>
        </ng-container>

        <ng-container matColumnDef="valorPago">
          <th mat-header-cell *matHeaderCellDef>Valor pago</th>
          <td mat-cell *matCellDef="let element">{{element.valorPago | currency: 'R$'}}</td>
        </ng-container>

        <ng-container matColumnDef="taxaBoleto">
          <th mat-header-cell *matHeaderCellDef>Taxa de emissão</th>
          <td mat-cell *matCellDef="let element">{{element.taxaBoleto | currency: 'R$'}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="['status', element.status ? element.status : '']" class="danger">{{mapStatus(element.status)}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="table.columns"></tr>
        <tr mat-row *matRowDef="let row; columns: table.columns;"></tr>
      </table>
    </ng-template>
  </ng-template>
