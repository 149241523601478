import { Modalidade } from '@/enums/Modalidade';
import { RecebimentoStatus } from '@/enums/RecebimentoStatus';
import { FaturamentoRecebimenetoDto } from '@/models/dto/faturamento-recebimento.dto';
import { FaturamentoDto } from '@/models/dto/faturamento.dto';
import { FaturamentosService } from '@/services/faturamentos.service';
import { LoadingService } from '@/services/loading.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalidadesOptions } from 'src/app/constants';
import { SharedService } from 'src/app/shared/shared.service';
import { PageEvent } from '@angular/material/paginator';
import { PontosDeVendaModel } from '@/models/pontos-de-venda';
import { PontosDeVendaService } from '@/services/pontos-de-venda.service';

type PdvSearch = PontosDeVendaModel & {
  search: string
}

@Component({
  selector: 'app-faturamentos',
  templateUrl: './faturamentos.component.html',
  styleUrls: ['./faturamentos.component.scss']
})
export class FaturamentosComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private faturamentosService: FaturamentosService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private swal: SweetAlert2Service,
    private loadingService: LoadingService,
    private pontoVendaService: PontosDeVendaService
  ) {
    const params = this.route.snapshot.queryParams
    this.modalidade.setValue(params['modalidade'] || Modalidade.AUTOMATICO)
  }

  currentPage = 0;
  pageSize = 100
  displayedItems: FaturamentoRecebimenetoDto[]
  RECEBIMENTO_STATUS = RecebimentoStatus
  columns: string[] = ['id', 'ponto_venda', 'status', 'forma_pagamento', 'data_inicio', 'quantidade_transacoes', 'valor_total_debito', 'valor_total_credito', 'valor_liquido', 'qtd_boletos', 'actions']
  faturamentos: FaturamentoDto[] = [];
  dataFat:Date = new Date();
  loading = false
  somatoriaColunas = {
    transacoes: 0,
    debito: 0,
    credito: 0,
    liquidoTotal: 0,
    qtdBoletos: 0,
  }
  pdv = new FormControl<PdvSearch | null>(null);
  inputStatus = new FormControl(null);
  inputTipo = new FormControl(null);
  modalidades = ModalidadesOptions
  modalidade = new FormControl(null)
  faturamentoRecebimentos: FaturamentoRecebimenetoDto[] = []

  pontosDeVendas: PdvSearch[] = [];

  ngOnInit(): void {
    this.sharedService.changeAuth(true)
    this.searchFaturamentos();
    this.buscaPontosDeVendas()
  }

  searchFaturamentos(date: any = null){
    const query = this.buildQuery(date)

    this.loading = true;
    this.faturamentosService.searchFaturamentos(query)
      .subscribe( result => {
        if(result.length > 0){
          this.mergeFaturamentoRecebimentos(result)
          this.somatoriaColunas = this.somaTotaldeColunas()
        }else{
          this.faturamentoRecebimentos = [];
          this.displayedItems = [];
          this.somatoriaColunas = {
            transacoes: 0,
            debito: 0,
            credito: 0,
            liquidoTotal: 0,
            qtdBoletos: 0,
          }
        }
        this.loading = false;
        this.router.navigate([], {
          queryParams: query,
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        })
      })
  }

  buildQuery(params: any){
    let {search, date, status, tipo} = this.route.snapshot.queryParams
    const dataAtual = this.dataFormatada(new Date)

    if(this.pdv.value){
      search = this.pdv.value?.['id']
    }
    if(params?.date){
      date = params?.date
    }
    if(this.inputStatus.value || this.inputStatus.value === ""){
      status = this.inputStatus.value
    }
    if(this.inputTipo.value || this.inputTipo.value === ""){
      tipo = this.inputTipo.value
    }

    const query = {
      search: search || '',
      date: date || dataAtual,
      status: status || '',
      tipo: tipo || '',
      modalidade: this.modalidade.value || ''
    }

    this.dataFat = new Date(`${query.date}T00:00:00`)
    return query
  }

  mergeFaturamentoRecebimentos(faturamentos: FaturamentoDto[]) {
    this.faturamentoRecebimentos = faturamentos
      .map(item => item.recebimentos)
      .reduce((acc, current) => {
        const result = acc.concat(current)

        return result;
      }, [])
      this.updateDisplayedItems();
  }

  updateDisplayedItems() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedItems = this.faturamentoRecebimentos.slice(startIndex, endIndex);
  }

  handleEvent(pageEvent: PageEvent) {
    this.currentPage = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.updateDisplayedItems();
  }

  somaTotaldeColunas() {
    const recebimentos = this.faturamentoRecebimentos
    const sumTransacoes = recebimentos.map((recebimento: { quantidade_transacoes: number }) => Number(recebimento.quantidade_transacoes)).reduce((acc: number, value: number)=> acc + value, 0)
    const sumDebito = recebimentos.map((recebimento: {valor_total_debito: number }) => Number(recebimento.valor_total_debito)).reduce((acc: number, value: number)=> acc + value, 0).toFixed(2)
    const sumCredito = recebimentos.map((recebimento: {valor_total_credito  : number }) => Number(recebimento.valor_total_credito)).reduce((acc: number, value: number)=> acc + value, 0).toFixed(2)
    const sumLiquidoTotal = recebimentos.map((recebimento: { valor_liquido: number }) => Number(recebimento.valor_liquido)).reduce((acc: number, value: number)=> acc + value, 0).toFixed(2)
    const qtdBoletos = recebimentos.map((recebimento) => (recebimento.boletos.length)).reduce((acc: number, value: number)=> acc + value, 0)

    return {
      transacoes: sumTransacoes,
      debito: Number(sumDebito),
      credito: Number(sumCredito),
      liquidoTotal: Number(sumLiquidoTotal),
      qtdBoletos
    }
  }

  changeDataFat(date: any){
    this.dataFat = date
    const params = {
      date: this.dataFormatada(date),
    }
    this.searchFaturamentos(params)
  }

  setDiaAnterior() {
    const diaAnterior = this.dataFat.setDate(this.dataFat.getDate() - 1)
    const novaData = this.dataFormatada(new Date(diaAnterior))
    this.dataFat = new Date(diaAnterior)
    const params = {
      date: novaData,
    }
    this.searchFaturamentos(params)
  }
  setProximoDia() {
    const proximoDia = this.dataFat.setDate(this.dataFat.getDate() + 1)
    const novaData = this.dataFormatada(new Date(proximoDia))
    this.dataFat = new Date(proximoDia)
    const params = {
      date: novaData,
    }
    this.searchFaturamentos(params)
  }

  dataFormatada(data: Date) {
    return data.toLocaleDateString()
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{2})(\d{4})/, '$3-$2-$1')
  }

  formataPerido(element: any){
    let dataIni = this.datePipe.transform(element.data_inicio, 'dd/MM/yyyy');
    let dataFim = this.datePipe.transform(element.data_fim, 'dd/MM/yyyy');
    if(dataIni === dataFim){
      return dataFim!;
    }
    return `${dataIni} - ${dataFim}`
  }

  formataValorParaBRL(valor: number) {
    const formatter = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2})
    return formatter.format(valor)
  }

  btnRecebimento(idRec:number){
    this.router.navigate(["faturamentos","recebimento", idRec]);
  }

  gerarRecebimento(id: number) {
    const params = {
      date: this.dataFat,
    }
    this.swal.confirm('Deseja realmente gerar o recebimento?')
      .then(result => {
        if(!result.isConfirmed) return;

        this.loadingService.show()
        this.faturamentosService.gerarRecebimento(id)
          .subscribe({
            next: () => {
              this.searchFaturamentos(params)
            },
            complete: () => this.loadingService.hide(),
            error: error => {
              this.swal.error(error.message);
            }
          })
      })
  }

  buscaPontosDeVendas() {
    const {search} = this.route.snapshot.queryParams
    this.pontoVendaService.findAllPontosDeVenda()
      .subscribe({
        next: data => {
          this.pontosDeVendas = data.map(pdv => ({
            ...pdv,
            search: `${pdv.id} - ${pdv.nome_comercial}`
          }))

          if(search) {
            const matchPdv = this.pontosDeVendas?.find(item => item.id == search) || null
            this.pdv.setValue(matchPdv)
          }
        }
      });
  }

  async onSelectPdv() {
    if(!this.pdv.value?.id) {
      await this.router.navigate([], {
        queryParams: {search: null},
        queryParamsHandling:'merge',
        relativeTo: this.route
      })
    }
    this.searchFaturamentos()
  }
}
