<mat-form-field
  [ngClass]="['input custom-input', 'input__size-' + size]"
  [appearance]="appearance"
  (click)="touched = true"
>
  <mat-label class="mb-xs" *ngIf="label" [ngClass]="{ asterix: required }">{{ label }}</mat-label>
  <input
    matInput
    [type]="type"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="ngModelChange.emit($event); onChange(value)"
    (change)="onChange(value)"
    (click)="handleClick($event); onTouched()"
    (focus)="onFocus($event)"
    [errorStateMatcher]="matcher"
    [placeholder]="placeholder"
    [mask]="mask"
    [showMaskTyped]="showMaskTyped"
    [dropSpecialCharacters]="!showMaskTyped"
    (paste)="onPaste($event)"
    (drop)="onDrop($event)"
    (keydown.enter)="handleKeyEnter($event)"
    maxlength="256"
  />
  <mat-icon matPrefix *ngIf="prefix">{{ prefix }}</mat-icon>
  <mat-icon matSuffix *ngIf="sufix" (click)="sufixClick.emit($event)">{{ sufix }}</mat-icon>
  <mat-error>
    <app-errors-label
      [label]="label"
      *ngIf="touched && ngControl.errors"
      [errors]="ngControl.errors"
    ></app-errors-label>
  </mat-error>
</mat-form-field>
