<h1 mat-dialog-title>Permissões</h1>
<div mat-dialog-content>
  <mat-spinner *ngIf="loadingCount; else content" diameter="20" class="mx-auto"></mat-spinner>
  <ng-template #content>

    <div *ngIf="perfil" class="checkbox-group">
      <mat-label class="checkbox-group__label">
        Perfil: {{ perfil.nome }}
      </mat-label>

      <div class="all">
        <mat-checkbox color="primary" [checked]="allIsChecked" (change)="markAllChecked($event)">
          Marcar Todos
        </mat-checkbox>
      </div>

      <div class="checkbox-group__list">
        <div class="checkbox-group__item" *ngFor="let permissao of permissoes">
          <mat-checkbox [matTooltip]="permissao.descricao" [checked]="isChecked(permissao.id)" (change)="checkboxChange($event, permissao.id)" color="primary">
            {{ permissao.nome }}
          </mat-checkbox>
        </div>
      </div>
    </div>

  </ng-template>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  <button mat-button (click)="atualizaPermissoes()">Atualizar</button>
</mat-dialog-actions>
