import { BoletoService } from '@/services/boleto.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { CustomValidators } from '@/utils/custom-validators';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';

interface DialogData {
  recebimentoId: number;
  boletoId: number;
  valorDocumento: number;
}

@Component({
  selector: 'app-modal-vincular-boleto',
  templateUrl: './modal-vincular-boleto.component.html',
  styleUrls: ['./modal-vincular-boleto.component.scss']
})
export class ModalVincularBoletoComponent {
  data_vencimento = new Date();
  form!: FormGroup
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: DialogData,
    private fb: FormBuilder,
    private boletoService: BoletoService,
    private swal: SweetAlert2Service,
    private dialogRef: MatDialogRef<ModalVincularBoletoComponent>
  ){
    this.form = this.fb.group({
      valor: [this.data.valorDocumento, [Validators.required, CustomValidators.moneyNonZero]],
      data_vencimento: [new Date(), [Validators.required]]
    })
  }


  submit() {
    const {data_vencimento, valor} = this.form.value;
    this.loading = true;
    this.boletoService.vincular(this.data.boletoId, {
      valor,
      data_vencimento: dayjs(data_vencimento).format('YYYY-MM-DD'),
      recebimento_id: this.data.recebimentoId
    }).subscribe({
      next: () => {
        this.swal.success('Boleto gerado com sucesso!')
        this.dialogRef.close(true);
      },
      complete: () => {
        this.loading = false
      },
      error: (err) => {
        if(err.error) {
          this.swal.error(err.error?.message);
        }
        this.loading = false;
      },
    })
  }
}
