import { PontosVendaAgendaFaturamento } from '@/models/pontos-venda-agenda-faturamento';
import { LoadingService } from '@/services/loading.service';
import { PontosVendaAgendaFaturamentoService } from '@/services/pontos-venda-agenda-faturamento.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { DatatableColumn } from '@/models/datatable-column.model';
import { SharedService } from 'src/app/shared/shared.service';
import { ModalAtualizaAgendaComponent } from './modal-atualiza-agenda/modal-atualiza-agenda.component';
import { PontosDeVendaModel } from '@/models/pontos-de-venda';
import { PontosDeVendaService } from '@/services/pontos-de-venda.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Permissao } from '@/enums/Permissao';

@Component({
  selector: 'app-dias-faturamento',
  templateUrl: './dias-faturamento.component.html',
  styleUrls: ['./dias-faturamento.component.scss']
})
export class DiasFaturamentoComponent implements OnInit {
  pontoDeVendas: PontosDeVendaModel[] = []
  diasDeFaturamentos: PontosVendaAgendaFaturamento[] = []
  columns: string[] = ['nome_comercial', 'data_inicio', 'data_fim', 'data_faturamento', 'data_vencimento', 'actions']
  formFilter!: FormGroup
  Permissao = Permissao

  constructor(
    private sharedService: SharedService,
    private pontosVendaAgendaFaturamentoService: PontosVendaAgendaFaturamentoService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private pdvService: PontosDeVendaService,
    private formBuilder: FormBuilder
  ) {
    this.formFilter = this.formBuilder.group({
      pdv: [null]
    });
  }

  formataData(data:string) {
    return dayjs(data).format('DD/MM/YYYY')
  }

  async getDiasDeFaturamentos() {
    try {
      this.loadingService.show();
      const response = await this.pontosVendaAgendaFaturamentoService.findAll({
        ponto_venda_id: this.formFilter.get('pdv')?.value?.id || ''
      });
      this.diasDeFaturamentos = response
    } finally {
      this.loadingService.hide();
    }
  }

  handleAlterarFaturamento(id: number) {
    const data = this.diasDeFaturamentos.find(item => item.id == id);
    const dialogRef = this.dialog.open(ModalAtualizaAgendaComponent, {
      data
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data?.id) {
        this.diasDeFaturamentos = this.diasDeFaturamentos.map(item => {
          if(item.id == data.id) {
            item = {
              ...item,
              data_inicio: data.data_inicio,
              data_fim: data.data_fim,
              data_faturamento: data.data_faturamento,
              data_vencimento: data.data_vencimento
            }
          }

          return item;
        })
      }
    })
  }

  async getAllPDVs() {
    this.pdvService.findAllPontosDeVenda()
      .subscribe({
        next: data => {
          this.pontoDeVendas = data.map(pdv => ({
            ...pdv,
            search: `${pdv.id} - ${pdv.nome_comercial}`
          }))
        }
      });
  }

  ngOnInit(): void {
    this.sharedService.changeAuth(true);
    this.getAllPDVs();
    this.formFilter.valueChanges.subscribe(data => {
      this.getDiasDeFaturamentos();
    })
  }
}
