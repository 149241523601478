<h1 mat-dialog-title>Detalhes do feriado</h1>
<div mat-dialog-content>
  <div class="info">
    <strong>{{ data.data | date: "dd/MM/yyyy"}}</strong>
    <p>{{data.descricao}}</p>
  </div>

  <div class="user">
    <strong>Registrado por</strong>
    <p>{{ data.usuario.nome }}</p>
  </div>
</div>
<div mat-dialog-actions class="btn-actions">
  <button mat-button mat-dialog-close cdkFocusInitial>Fechar</button>
  <button mat-button color="warn" (click)="onDelete()" [disabled]="loading">Remover</button>
</div>
