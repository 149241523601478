import { FornecedoresModel } from '@/models/fornecedores.model';
import { PontosVendasProdForComissoesDescModel } from '@/models/pontos-vendas-prod-for-comissoes-desc.model';
import { PontosVendasProdForComissoesPadroesModel } from '@/models/pontos-vendas-prod-for-comissoes-padroes.model';
import { ProdutosComissoesModel } from '@/models/produtos-comissoes.model';
import { ProdutosGruposModel } from '@/models/produtos-grupos.model';
import { AuthService } from '@/services/auth.service';
import { FornecedoresService } from '@/services/fornecedores.service';
import { LoadingService } from '@/services/loading.service';
import { PontosVendasProdForComissoesPadroesService } from '@/services/pontos-vendas-prod-for-comissoes-padroes.service';
import { ProductService } from '@/services/produtos-categorias.service';
import { ProdutosGruposService } from '@/services/produtos-grupos.service';
import { ProdutosService } from '@/services/produtos.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { validateFieldsComissaoDesc } from '@/utils/validateFields/validateFieldsComissoesDesc';
import { validateFieldsComissaoPadroes } from '@/utils/validateFields/validateFieldsComissoesPadroes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-comissoes-padroes-upsert',
  templateUrl: './comissoes-padroes-upsert.component.html',
  styleUrls: ['./comissoes-padroes-upsert.component.scss'],
})
export class ComissoesPadroesUpsertComponent implements OnInit {
  id: string;
  idCategoria: string = '1';
  categoriaProdutos: any;
  pdvProdForComissoesPadroes: PontosVendasProdForComissoesPadroesModel[];
  auxPdvProdForComissoesPadroes: PontosVendasProdForComissoesPadroesModel[];
  pdvProdForComissoesDesc = new PontosVendasProdForComissoesDescModel();
  fornecedores: FornecedoresModel[] = [];
  produtosGrupos: ProdutosGruposModel[] = [];
  idUserCurrent: number | undefined;
  muitosFornecedores: boolean;
  rowBreakPoint: number = 2;
  rowBreakPointrow: number = 1;
  breakpoint: number;
  produtosComissoes: ProdutosComissoesModel[] = [];
  auxProdutosComissoes: ProdutosComissoesModel[] = [];
  constructor(
    private pontosVendasProdForComissoesPadroesService: PontosVendasProdForComissoesPadroesService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private loadingService: LoadingService,
    private fornecedoresService: FornecedoresService,
    public produtosService: ProdutosService,
    public produtosGrupoService: ProdutosGruposService,
    public authService: AuthService,
    public productService: ProductService,
    private sweetAlertService: SweetAlert2Service,
    private produtosGruposService: ProdutosGruposService
  ) {}

  ngOnInit(): void {
    this.sharedService.changeAuth(true);
    this.loadingService.show();
    this.getAllProdutosGrupos();
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.findPdvComissoesByIdDesc(+this.id);
      }
    });
    this.resizeProdutosTable();
    this.getAllCategoriaProdutos();
    this.getProdutosByCategoria(+this.idCategoria);
    this.pdvProdForComissoesDesc.user_updated =
      this.authService.userCurrent()!.sub;
    this.loadingService.hide();
  }

  resizeProdutosTable() {
    if (window.innerWidth <= 500) {
      this.breakpoint = 1;
    } else if (window.innerWidth <= 800) {
      this.breakpoint = 2;
    } else if (window.innerWidth <= 991) {
      this.breakpoint = 3;
    } else if (window.innerWidth <= 1560) {
      this.breakpoint = 4;
    } else {
      this.breakpoint = 5;
    }
  }

  getAllProdutosGrupos() {
    this.produtosGrupoService.findAllProdutosGrupos({
      orderBy: 'produto_categoria_id',
      sort: 'ASC'
    }).subscribe(
      (res) => {
        this.produtosGrupos = res;
        const auxArray: ProdutosComissoesModel[] = [];
        const auxArray2: PontosVendasProdForComissoesPadroesModel[] = [];
        res.forEach((produtoGrupo) => {
          auxArray[produtoGrupo.id] = {
            comissao: 0,
            fornecedor_id: 0,
            produto_grupo_id: produtoGrupo.id,
            ponto_venda_id: 0,
            user_insert_id: this.authService.userCurrent()?.sub,
          };
        });
        res.forEach((produtoGrupo) => {
          auxArray2[produtoGrupo.id] = {
            pdv_prod_comissao_desc_id: null,
            fornecedor_id: 0,
            produto_grupo_id: produtoGrupo.id,
            comissao: 0,
            user_insert_id: this.authService.userCurrent()!.sub,
          };
        });
        this.auxProdutosComissoes = auxArray;
        this.auxPdvProdForComissoesPadroes = auxArray2;
        this.loadingService.hide();
        return auxArray;
      },
      (err) => {
        this.authService.handleError(err, true);
      }
    );
  }

  composeProdutosComissoes(e: any, key: string, produtoId: number) {
    this.auxPdvProdForComissoesPadroes[produtoId] = {
      ...this.auxPdvProdForComissoesPadroes[produtoId],
      [key]: e.value,
      produto_grupo_id: produtoId,
    };
  }

  atribuirFornecedor(id: number, produtosGrupos: ProdutosGruposModel[]) {
    const produtos = produtosGrupos.map((produtosGrupos) => {
      return produtosGrupos.id;
    });

    produtos.forEach((prod) => {
      this.auxPdvProdForComissoesPadroes[prod] = {
        ...this.auxPdvProdForComissoesPadroes[prod],
        fornecedor_id: id,
      };
    });
  }

  formatProdutosComissoesResult(produtosComissoes?: ProdutosComissoesModel[]) {
    const auxArray: ProdutosComissoesModel[] = [];
    if (!produtosComissoes) return [];

    produtosComissoes.forEach((produtoComissao) => {
      auxArray[produtoComissao.produto_grupo_id] = {
        ...auxArray[produtoComissao.produto_grupo_id],
        ...produtoComissao,
      };
    });
    return auxArray;
  }

  getFornecedoresPorCategoria(
    id: number,
    produtosGrupos: ProdutosGruposModel[]
  ) {
    this.fornecedoresService.findCategoriasEmFornecedores(id).subscribe(
      (res: any) => {
        this.fornecedores = res;
        this.muitosFornecedores = this.fornecedores.length > 1;
        this.rowBreakPointrow = this.muitosFornecedores ? 2 : 1;
        if (!this.muitosFornecedores) {
          this.atribuirFornecedor(res[0].id, produtosGrupos);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getProdutosByCategoria(idCategoria: number) {
    this.produtosGrupoService.findProdutosByCategoria(+idCategoria).subscribe(
      (res) => {
        this.produtosGrupos = res;
        this.getFornecedoresPorCategoria(+idCategoria, res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setCategory(e: any) {
    console.log('set categoria: ', e.value);
    if (!e.value) {
      this.muitosFornecedores = false;
    }
    this.idCategoria = e.value;
    if (this.idCategoria) this.getProdutosByCategoria(+this.idCategoria);
    else this.produtosGrupos = [];
  }

  getAllCategoriaProdutos() {
    this.productService
      .findCategories({
        hasFornecedor: true,
      })
      .then((value: any) => {
        this.categoriaProdutos = value;
      });
  }

  findPdvComissoesByIdDesc(id: number) {
    this.pontosVendasProdForComissoesPadroesService
      .getAllPontosVendasProdComissoesPadroesForDesc(id)
      .subscribe((result: PontosVendasProdForComissoesPadroesModel[]) => {
        result.forEach((resultComissao) => {
          this.auxPdvProdForComissoesPadroes.forEach((comissoesPadroes) => {
            if (
              resultComissao.produto_grupo_id ===
              comissoesPadroes.produto_grupo_id
            ) {
              comissoesPadroes.comissao = resultComissao.comissao;
              comissoesPadroes.fornecedor_id = resultComissao.fornecedor_id
            }
          });
        });
        this.pdvProdForComissoesDesc = result[0].pdvProdForComissoesDesc;
      });
  }

  createPdvComissoesPadroes() {
    const checkedComissoesDesc = validateFieldsComissaoDesc(
      this.pdvProdForComissoesDesc
    );

    if (!checkedComissoesDesc.isValid) {
      return this.sweetAlertService.info(
        'Existe campos  vazios, revise o formulario por favor!'
      );
    }

    this.loadingService.show();
    this.auxPdvProdForComissoesPadroes.forEach((pdvProdComissoesPadroes) => {
      if (
        pdvProdComissoesPadroes.comissao === '' ||
        pdvProdComissoesPadroes.comissao === null ||
        pdvProdComissoesPadroes.comissao === undefined
      ) {
        pdvProdComissoesPadroes.comissao = 0;
      }
    });

    this.pdvProdForComissoesPadroes = this.auxPdvProdForComissoesPadroes.filter(
      (pdvComissoesPadroes) =>
        pdvComissoesPadroes !== null && pdvComissoesPadroes.fornecedor_id !== 0
    );
    const body = {
      pdvProdForComissoesPadroes: this.pdvProdForComissoesPadroes,
      pdvProdForComissoesDesc: this.pdvProdForComissoesDesc,
    };

    this.pontosVendasProdForComissoesPadroesService
      .createPontosVendasProdForComissoesPadroes(body)
      .subscribe(
        (res) => {
          this.sweetAlertService.success('Comissão Padrão criada com sucesso!');
          this.router.navigate(['administracao/comissoes-padroes-lista']);
        },
        (err) => {
          this.sweetAlertService.error(err.message);
        }
      );
    this.loadingService.hide();
  }

  editarPdvComissoesPadroes() {
    this.auxPdvProdForComissoesPadroes.forEach((pdvProdComissoesPadroes) => {
      if (
        pdvProdComissoesPadroes.comissao === '' ||
        pdvProdComissoesPadroes.comissao === null ||
        pdvProdComissoesPadroes.comissao === undefined
      ) {
        pdvProdComissoesPadroes.comissao = 0;
      }
    });

    this.pdvProdForComissoesPadroes = this.auxPdvProdForComissoesPadroes.filter(
      (pdvComissoesPadroes) =>
        pdvComissoesPadroes !== null && pdvComissoesPadroes.fornecedor_id !== 0
    );
    const checkedComissoesDesc = validateFieldsComissaoDesc(
      this.pdvProdForComissoesDesc
    );

    if (!checkedComissoesDesc.isValid) {
      return this.sweetAlertService.info(
        'Existe campos  vazios, revise o formulario por favor!'
      );
    }
    this.loadingService.show();
    const body = {
      idComissoesDesc: this.pdvProdForComissoesDesc.id,
      pdvProdForComissoesPadroes: this.pdvProdForComissoesPadroes,
      pdvProdForComissoesDesc: this.pdvProdForComissoesDesc,
    };
    this.pontosVendasProdForComissoesPadroesService
      .editPontosVendasProdForComissoesPadroes(body)
      .subscribe(
        (res) => {
          this.sweetAlertService.success('Comissão Padrão criada com sucesso!');
          this.router.navigate(['administracao/comissoes-padroes-lista']);
        },
        (err) => {
          this.sweetAlertService.error(err.message);
        }
      );
    this.loadingService.hide();
  }

  findAllFornecedores() {
    this.fornecedoresService.findAllFornecedores().subscribe(
      (res) => {
        this.fornecedores = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  findAllProdutosGrupos() {
    this.produtosGruposService.findAllProdutosGrupos({
      orderBy: 'produto_categoria_id',
      sort: 'ASC'
    }).subscribe(
      (res) => {
        this.produtosGrupos = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  cancel() {
    this.router.navigate(['administracao/comissoes-padroes-lista']);
  }
}
