import { TerminaisModeloModel } from '@/models/terminais-modelo.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TerminaisModeloService {
  url = environment.baseURL;

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  findAllTerminaisModelo(): Observable<TerminaisModeloModel[]> {
    return this.httpClient
      .get<TerminaisModeloModel[]>(`${this.url}/terminais-modelos`)
      .pipe(catchError(this.authService.handleError as any));
  }
}
