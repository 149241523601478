import { ProdutosModel } from '@/models/produtos.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProdutosService {

  url = environment.baseURL;
  handleError: any;

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  createProdutos(produto: ProdutosModel) {
    return this.httpClient
      .post<ProdutosModel>(`${this.url}/produtos`, produto)
      .pipe(catchError(this.authService.handleError as any));
  }
  findAllProdutos(): Observable<ProdutosModel[]> {
    return this.httpClient
      .get<ProdutosModel[]>(`${this.url}/produtos`)
      .pipe(catchError(this.authService.handleError as any));
  }
  findOneProdutos(id: string): Observable<ProdutosModel> {
    return this.httpClient
      .get<ProdutosModel>(`${this.url}/produtos/${id}`)
      .pipe(catchError(this.authService.handleError as any));
  }
  updatePontosDeVenda(id: string, produto: ProdutosModel) {
    return this.httpClient.put<ProdutosModel>(`${this.url}/produtos/${id}`, produto)
      .pipe(catchError(this.authService.handleError as any));
  }
  deletePontosDeVenda(id: string) {
    return this.httpClient
      .delete(`${this.url}/produtos/${id}`)
      .pipe(catchError(this.authService.handleError as any));
  }
}


