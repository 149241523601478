<div class="row">
  <h1>Atualização de terminais</h1>

  <div>
    <mat-card appearance="outlined" class="filtros">
      <mat-card-content>
        <form [formGroup]="formFilters">
          <mat-form-field
            appearance="outline"
            class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
          >
            <mat-label>Escolha o modelo</mat-label>
            <mat-select
              name="status"
              aria-placeholder="status"
              formControlName="modelo"
              (selectionChange)="buscaPerfis()"
            >
              <mat-option value="">Todos</mat-option>
              <mat-option
                *ngFor="let modelo of terminaisModelo"
                value="{{ modelo.id }}"
              >
                {{ modelo.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
          >
            <mat-label>Escolha o Firmware</mat-label>
            <mat-select
              name="status"
              aria-placeholder="status"
              formControlName="firmware"
            >
              <mat-option value="">Todos</mat-option>
              <mat-option
                *ngFor="let firmware of terminaisFirmware"
                value="{{ firmware.firmware_perfil}}"
              >
                {{ firmware.firmware_perfil }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="firmware-atual-wrapper">
            <small>Firmware Atual</small>
            <strong>
              {{ firmwareAtual }}
            </strong>
          </div>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="versoes-container" *ngIf="formFilters.value?.['modelo']">
      <mat-card-header>
        <mat-card-title>Versões</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group [(ngModel)]="tipoVersao" (ngModelChange)="buscaTerminais()">
          <mat-radio-button color="warn" value="all">Todas as Versões</mat-radio-button>
          <mat-radio-button color="warn" value="previous" class="mx-4">Versão Antiga</mat-radio-button>
          <mat-radio-button color="warn" value="current">Versão Atual</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="selection.selected.length">
      <mat-card-header>
        <mat-card-title>Atualização automática</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div class="btn-actions">
            <button mat-flat-button color="primary" (click)="atualizacaoAutomatica(true)">Ativar</button>
            <button mat-flat-button color="warn" (click)="atualizacaoAutomatica(false)">Desativar</button>
          </div>
      </mat-card-content>
    </mat-card>

    <table mat-table matSort [dataSource]="dataSource">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary" (change)="$event ? toggleAllRows() : null"
            [checked]="isAllSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected">
        </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="ponto_venda">
        <th mat-header-cell *matHeaderCellDef>Ponto de Venda</th>
        <td mat-cell *matCellDef="let element"> {{element.pdv_id.toString().padStart(4, '0')}} - {{element.ponto_venda}} </td>
      </ng-container>

      <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef>Terminal</th>
        <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="terminal_modelo">
        <th mat-header-cell *matHeaderCellDef>Modelo</th>
        <td mat-cell *matCellDef="let element"> {{element.terminal_modelo}} </td>
      </ng-container>

      <ng-container matColumnDef="firmware_perfil">
        <th mat-header-cell *matHeaderCellDef>Firmware</th>
        <td mat-cell *matCellDef="let element"> {{element.firmware_perfil}} </td>
      </ng-container>

      <ng-container matColumnDef="firmware_versao">
        <th mat-header-cell *matHeaderCellDef>V. POS</th>
        <td mat-cell *matCellDef="let element">
          <app-chip
            *ngIf="element.firmware_versao; else noContent"
            [label]="element.firmware_versao"
            [type]="element.versao_atual && element.firmware_versao >= element?.ultima_versao ? 'success' : 'warning'">
          </app-chip>
          <ng-template #noContent>
            <span>---</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="versao_atual">
        <th mat-header-cell *matHeaderCellDef>V. Req. Firmware</th>
        <td mat-cell *matCellDef="let element"> {{element.versao_atual}} </td>
      </ng-container>

      <ng-container matColumnDef="versao_req">
        <th mat-header-cell *matHeaderCellDef>V. Req. Solicitada</th>
        <td mat-cell *matCellDef="let element"> {{element.versao_req}} </td>
      </ng-container>

      <ng-container matColumnDef="dt_req_atualizacao_firmware">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitado em</th>
        <td mat-cell *matCellDef="let element"> {{element.dt_req_atualizacao_firmware | date: "dd/MM/yyyy HH:mm"}} </td>
      </ng-container>

      <ng-container matColumnDef="dt_confirmacao_versao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Confirmado em</th>
        <td mat-cell *matCellDef="let element"> {{element.dt_confirmacao_versao | date: "dd/MM/yyyy HH:mm"}} </td>
      </ng-container>

      <ng-container matColumnDef="atualizacao_automatica">
        <th mat-header-cell *matHeaderCellDef>Automático?</th>
        <td mat-cell *matCellDef="let element"
          [ngClass]="{'verde': element.atualizacao_automatica === 1, 'vermelho': element.atualizacao_automatica === 0}"
        > {{element.atualizacao_automatica === 0 ? "Não" : "Sim"}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>

  </div>
</div>
