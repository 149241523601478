import { AuthService } from '@/services/auth.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AclGuard  {
  constructor(private authService: AuthService, private router: Router, private swal: SweetAlert2Service) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.userCurrent();
    const userPermissions = user?.perfil?.permissoes || []
    const routePermissions = route.data['permissions'] || [];
    if(Array.isArray(routePermissions) && routePermissions.length) {
      const hasPermission = userPermissions.some(permissao => routePermissions.includes(permissao))
      if(!hasPermission) {
        this.swal.info('Acesso negado!');
        return this.router.navigate(['/home']);
      }
    }

    return true;
  }
}
