<h1 mat-dialog-title>Operadoras Exceções</h1>

<div mat-dialog-content>
  <div class="item"
    *ngFor="
      let produto of produtosGruposCategoria;
      let i = index
    " >
    <div class="wrapExcecoes">
      <div class="produtoGrupoExcesso">
        {{ produto.sigla }}
      </div>

      <div>
        <mat-slide-toggle
          color="warn"
          [(ngModel)]="produto.checked"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="actions">
  <button
    mat-raised-button
    color="primary"
    (click)="savePontosVendaProdutosGrupos()"
  >
    Salvar
  </button>
  <button
    mat-raised-button
    mat-dialog-close
  >
    Cancelar
  </button>
</div>
