<div class="hipercap-produtos">
  <div class="header">
    <h1>Hipercap Produtos</h1>
    <!-- <button (click)="showMassUpdateDialog()" mat-flat-button color="primary">
      Atualizar
    </button> -->
  </div>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Filtros</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field class="form-field" appearance="outline" color="primary">
          <mat-label class="mb-xs">Ponto de Venda</mat-label>
          <input
            matInput
            type="text"
            [formControl]="pdvSearch"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onPdvSelect($event)"
          >
            <mat-option *ngFor="let pdv of pdvFiltered" [value]="pdv">
              {{ pdv.id }} - {{ pdv.nome_comercial }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix color="primary">arrow_drop_down</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="container__select">
          <mat-label>Produto</mat-label>
          <mat-select formControlName="product_id">
            <mat-option value="">TODOS</mat-option>
            @for(product of hipercapProducts; track product.id) {
            <mat-option [value]="product.id">
              {{ product.descricao }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>

  <table mat-table [dataSource]="tableData.data">
    <ng-container matColumnDef="pdv">
      <th mat-header-cell *matHeaderCellDef>PDV</th>
      <td mat-cell *matCellDef="let element">
        {{ element.pdv_id }} - {{ element.pdv_nome }}
      </td>
    </ng-container>

    @for(product of hipercapProducts; track product.id) {
    <ng-container [matColumnDef]="'product_' + product.id">
      <th mat-header-cell *matHeaderCellDef>{{ product.descricao }}</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          [ngClass]="{
            icon: true,
            active: productIsActive(element.hipercap_products, product.id)
          }"
        >
          {{
            productIsActive(element.hipercap_products, product.id)
              ? "check"
              : "close"
          }}
        </mat-icon>
      </td>
    </ng-container>
    }

    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          class="small-icon-button"
          (click)="showEditDialog(element)"
          class="small-icon-button"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
  </table>
  <mat-paginator
    [length]="tableData.total"
    [pageSize]="20"
    [pageIndex]="tableData.current_page - 1"
    (page)="onPaginate($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
