import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom } from 'rxjs';
import { CreateTerminalFirmware } from '@/models/dto/create-terminal-firmware';
import { TerminalFirmware } from '@/models/terminal-firmware.model';
import { BaseApi } from './base/base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TerminaisFirmwareService extends BaseApi {

  searchTerminaisfirmware(query?: string): Observable<any[]> {
    return this.get<any[]>(`${this.url}/terminais-firmwares/search?${query}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  async perfis(params?: any): Promise<{firmware_perfil: string, versao_atual?:string}[]> {
    return lastValueFrom(this.get(`${this.url}/terminais-firmwares/perfis`, {
      params
    }));
  }

  async ftps(): Promise<[]> {
    return lastValueFrom(this.get(`${this.url}/terminais-firmwares/ftps`, ));
  }

  async create(data: CreateTerminalFirmware): Promise<TerminalFirmware> {
    return lastValueFrom(this.post(`${this.url}/terminais-firmwares`, data));
  }

  async remove(id: number) {
    return lastValueFrom(this.delete(`${this.url}/terminais-firmwares/${id}`,));
  }

  async ativar(id: number) {
    return lastValueFrom(this.put(`${this.url}/terminais-firmwares/${id}/ativar`, null));
  }

  async inativar(id: number) {
    return lastValueFrom(this.put(`${this.url}/terminais-firmwares/${id}/inativar`, null));
  }

  async findFirmwareVersionsByTerminalModeloId(id: number) {
    return lastValueFrom(this.get(`${this.url}/terminais-firmwares/modelo/${id}`, ));
  }
}
