import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon,SweetAlertResult, SweetAlertOptions } from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class SweetAlert2Service {
  constructor() {}

    public success(message: string, title: string = ''): void {
      this.showAlert(title, message, 'success');
    }

  public info(message: string, title: string = ''): void {
    this.showAlert(title, message, 'info');
  }

  public error(message: string,  title: string = ''): void {
    this.showAlert(title, message, 'error');
  }
  private showAlert( title: string ,  message: string,  icon: SweetAlertIcon
  ): void {
    Swal.fire({
      title,
      text: message,
      icon
    });
  }

  public confirm(message:string, title: string = ''): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dc3741'
    })
  }

  public build(options: SweetAlertOptions) {
    return Swal.fire(options);
  }

  public loading(options?: SweetAlertOptions) {
    if(options) {
      return Swal.fire({
        ...options,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
    }

    return Swal.fire({
      title: 'Aguarde...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  public hideLoading() {
    Swal.hideLoading()
    Swal.close()
  }
}
