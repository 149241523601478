import { InputComponent } from '../../shared/input/input.component';

import {
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  Component,
  Input,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

type Value = string | Record<string, any>;

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent
  extends InputComponent<Value>
  implements AfterViewInit, OnChanges
{
  @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;

  @Input() options: Value[] = [];
  @Input() optionLabel: string = 'name';
  @Input() allowEmptyValue: boolean = false
  @Input() emptyOptionName: string = 'Todos'

  filteredOptions: Observable<Value[]> | undefined;
  inputFormControl = new UntypedFormControl();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['options'] &&
      changes['options'].currentValue !== changes['options'].previousValue
    ) {
      this.options = changes['options'].currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.inputFormControl.valueChanges.subscribe(() => {
      this.onChange(this.inputFormControl.value);
    });
  }

  _optionLabel(option: Value): string {
    if (typeof option === 'string') {
      return option;
    }
    return option?.[this.optionLabel] || '';
  }

  displayFn = (value: Value): string => {
    return this._optionLabel(value);
  };
}
