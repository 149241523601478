import { PontosDeVendaModel } from '@/models/pontos-de-venda';
import { PontosVendaProdutosGruposExceccoes } from '@/models/pontos-venda-produtos-grupos-excecoes.mode';
import { AuthService } from '@/services/auth.service';
import { PontosDeVendaService } from '@/services/pontos-de-venda.service';
import { PontosVendaProdutosGruposExcecoesService } from '@/services/pontos-venda-produtos-grupos-excecoes.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  produtosGruposCategoria: any[],
  pdvId: number;
}

@Component({
  selector: 'app-operadoras-excecoes-dialog',
  templateUrl: './operadoras-excecoes-dialog.component.html',
  styleUrls: ['./operadoras-excecoes-dialog.component.scss']
})
export class OperadorasExcecoesDialogComponent implements OnInit {

  produtosGruposCategoria: any[] = []
  pdvId: number;
  pdv!: PontosDeVendaModel
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private dialogRef: MatDialogRef<OperadorasExcecoesDialogComponent>,
    private pontosDeVendaService: PontosDeVendaService,
    private pontosVendaProdutosGruposExcecoesService: PontosVendaProdutosGruposExcecoesService,
    private swal: SweetAlert2Service,
    private authService: AuthService
  ) {
    this.produtosGruposCategoria = this.data.produtosGruposCategoria
    this.pdvId = this.data.pdvId
  }

  setPontoDeVenda(id: number) {
    this.pontosDeVendaService.findOnePontosDeVenda(id).subscribe(
      (res) => {
        this.pdv = res;
      },
      (err) => {
        this.swal.error(err.message);
      }
    );
    this.findGruposProdutosExcecoes(id);
  }

  findGruposProdutosExcecoes(id: number) {
    this.pontosVendaProdutosGruposExcecoesService
      .findForPontoVenda(id)
      .subscribe(
        (res) => {
          this.produtosGruposCategoria.map((item) => {
            item.checked = false;
          });
          if (res.length > 0) {
            for (let i = 0; i <= res.length; i++) {
              const aux = res[i];
              if (aux) {
                const item = this.produtosGruposCategoria.find(
                  (el) => el.id === aux.produto_grupo_id
                );
                if (item) {
                  item.checked = true;
                }
              }
            }
          }
        },
        (err) => {}
      );
  }

  savePontosVendaProdutosGrupos() {
    const produtosGruposComissaoFilter = this.produtosGruposCategoria.filter(
      (item) => item.checked === true
    );

    const arrayProdutosGruposExceccoes: PontosVendaProdutosGruposExceccoes[] = [];

    produtosGruposComissaoFilter.forEach((produtoExcecoes) => {
      arrayProdutosGruposExceccoes.push({
        ponto_venda_id: this.pdvId,
        produto_grupo_id: produtoExcecoes.id,
        user_inserted_id: this.authService.userCurrent()!.sub,
      });
    });

    this.pontosVendaProdutosGruposExcecoesService
      .createPontosDeVendaProdutosGruposExceccoes(
        this.pdvId,
        arrayProdutosGruposExceccoes
      )
      .subscribe(
        (res) => {
          this.swal.success('Atualizado com sucesso!');
        },
        (err) => {
          this.swal.error(err.message);
        }
      );
  }

  ngOnInit(): void {
    this.setPontoDeVenda(this.pdvId)
  }

}
