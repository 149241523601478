import { PontosVendaAgendaFaturamento } from '@/models/pontos-venda-agenda-faturamento';
import { PontosVendaAgendaFaturamentoService } from '@/services/pontos-venda-agenda-faturamento.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-modal-atualiza-agenda',
  templateUrl: './modal-atualiza-agenda.component.html',
  styleUrls: ['./modal-atualiza-agenda.component.scss']
})
export class ModalAtualizaAgendaComponent {
  form!: FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PontosVendaAgendaFaturamento,
    private formBuilder: FormBuilder,
    private swal: SweetAlert2Service,
    private pontosDeVendaAgendaFaturamento: PontosVendaAgendaFaturamentoService,
    private dialogRef: MatDialogRef<ModalAtualizaAgendaComponent>
  ){
    this.form = this.formBuilder.group({
      data_inicio: [dayjs(this.data.data_inicio).toDate()],
      data_fim: [dayjs(this.data.data_fim).toDate()],
      data_faturamento: [dayjs(this.data.data_faturamento).toDate()],
      data_vencimento: [dayjs(this.data.data_vencimento).toDate()],
    })
  }

  async updateAgendaDeFaturamento() {
    const { data_inicio, data_fim, data_faturamento, data_vencimento } = this.form.value
    const pdvId = this.data.ponto_venda.id as number;
    const data = {
      data_inicio: dayjs(data_inicio).format('YYYY-MM-DD'),
      data_fim: dayjs(data_fim).format('YYYY-MM-DD'),
      data_faturamento: dayjs(data_faturamento).format('YYYY-MM-DD'),
      data_vencimento: dayjs(data_vencimento).format('YYYY-MM-DD'),
    }

    try {
      const response = await this.pontosDeVendaAgendaFaturamento.updateByPontoVendaId(pdvId, data);
      this.swal.success('Dados alterado com sucesso!');
      this.dialogRef.close(response);
    } catch(error) {
      console.log(error);
    }
  }


  handleSubmit() {
    this.swal.confirm('Confirma essas alterações?')
      .then(async result => {
        if(result) {
          this.updateAgendaDeFaturamento();
        }
      })
  }
}
