import { environment } from "@/environments/environment";
import { FaturamentoRecebimenetoDto } from "@/models/dto/faturamento-recebimento.dto";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { AuthService } from "./auth.service";


@Injectable({
    providedIn: 'root',
})
export class FaturamentosRecebimentosService {
    url = environment.baseURL;

    constructor(
        private httpClient: HttpClient,
        public authService: AuthService
    ) { }

    findById(id: number): Observable<FaturamentoRecebimenetoDto> {
        return this.httpClient
            .get<FaturamentoRecebimenetoDto>(`${this.url}/faturamentos/recebimento/${id}`)
            .pipe(catchError(this.authService.handleError as any));
    }

}
