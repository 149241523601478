import { LoadingService } from '@/services/loading.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorHandler implements HttpInterceptor {
  constructor(
    protected loadingService: LoadingService,
    protected swal: SweetAlert2Service,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 403) {
          this.swal.info('Acesso negado!');
          this.router.navigate(['/home'])
        }

        const error = err.error || err.statusText;
        return throwError(error);
      }),
    );
  }
}
