import {
  HipercapPdvProduct,
  HipercapProduct,
} from '@/models/hipercap-product.model';
import { HipercapService } from '@/services/hipercap.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  data: HipercapPdvProduct;
  products: HipercapProduct[];
}

@Component({
  selector: 'app-hipercap-product-dialog',
  templateUrl: './hipercap-product-dialog.component.html',
  styleUrls: ['./hipercap-product-dialog.component.scss'],
})
export class HipercapProductDialogComponent {
  loading = false;
  descricao = new FormControl<string>('', Validators.required);
  products: HipercapProduct[] = [];

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private swal: SweetAlert2Service,
    private dialogRef: MatDialogRef<HipercapProductDialogComponent>,
    private hipercapService: HipercapService,
    private fb: FormBuilder
  ) {
    this.products = this.data.products;
    this.form = this.fb.group({
      items: this.fb.array(
        this.data.data.hipercap_products.map((item) =>
          this.fb.group({
            id: [item.id],
            active: [item.active],
          })
        )
      ),
    });
  }

  get itemsArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  async update() {
    try {
      this.loading = true;
      await this.hipercapService.updatePermissions({
        pdv_id: this.data.data.pdv_id,
        products: this.form.value.items,
      });
      this.dialogRef.close({ success: true });
      this.swal.success('Dados atualizado com sucesso!');
    } catch (error) {
      this.swal.error('Algo inesperado aconteceu. Tente novamente!');
    } finally {
      this.loading = false;
    }
  }
}
