<mat-card appearance="outlined" class="card">
  <mat-card-content>
    <form (submit)="sendForm()" [formGroup]="form">
      <div class="inputs">
        <app-input-select
          label="Modelo"
          [options]="terminaisModelos"
          formControlName="modelo_id"
          optionLabel="nome"
        >
        </app-input-select>
        <app-input-select
          label="Perfil"
          [options]="perfis"
          formControlName="perfil"
          optionLabel="firmware_perfil"
        >
        </app-input-select>

        <app-input-text
          label="Versão"
          formControlName="versao"
        ></app-input-text>

        <app-input-text
          label="Caminho"
          formControlName="caminho"
        ></app-input-text>

        <app-input-text
          label="Arquivo"
          formControlName="arquivo"
        ></app-input-text>

        <app-input-select
          label="Servidor"
          [options]="servidores"
          formControlName="servidor"
          optionLabel="servidor"
        >
        </app-input-select>

        <app-input-text
          class="descricao-field"
          label="Descrição"
          formControlName="descricao"
        ></app-input-text>
      </div>
      <div class="actions">
        <button type="submit" mat-flat-button color="primary">Cadastrar</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
