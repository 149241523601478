
export class NovoBoletoDto {
    tipo: string
    recebimento_id: number
    valor: number
    data_vencimento: Date|string
    observacao?: string
    vinculado?: boolean
    boleto_id?: number
    usuario_id: number
}
