<mat-card appearance="outlined" class="card">
  <mat-card-content>
    <form (submit)="sendForm()" [formGroup]="form">
      <h2>Criar Permissão</h2>
      <div class="row">
        <app-input-text
          class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
          label="Nome"
          formControlName="nome"
        ></app-input-text>

        <app-input-text
          class=" col-lg-12 col-md-12 col-sm-12 col-xs-12"
          label="Descrição"
          formControlName="descricao"
        ></app-input-text>
      </div>
      <div class="actions">
        <button type="submit" mat-flat-button color="primary">Cadastrar</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
