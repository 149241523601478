import { Status } from '@/enums/Status';
import { TerminalFirmware } from '@/models/terminal-firmware.model';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { SharedService } from 'src/app/shared/shared.service';
import { TerminaisMensagensService } from '@/services/terminais-mensagens.service';
import { AuthService } from '@/services/auth.service';
import { Pagination, PaginationData } from '@/models/pagination.model';
import { PageEvent } from '@angular/material/paginator';
import { TerminalMensagem } from '@/models/terminal-mensagem';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-mensagens-terminais',
  templateUrl: './mensagens-terminais.component.html',
  styleUrls: ['./mensagens-terminais.component.scss'],
})
export class MensagensTerminaisComponent implements OnInit {
  inputsValue: any;
  columns: string[] = [
    'id',
    'data_hora',
    'descricao',
    'numero_terminais',
    'mensagens_recebidas',
    'mensagens_nao_recebidas',
    'status',
    'editar',
  ];
  
  vigente = new FormControl<boolean>({
    value: true,
    disabled: false
  })

  mensagens = new PaginationData<TerminalMensagem>()

  constructor(
    private sharedService: SharedService,
    private terminaisMensagensService: TerminaisMensagensService,
    private swal2: SweetAlert2Service,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.sharedService.changeAuth(true);
    this.fetchMensagens();
  }

  async fetchMensagens() {
    const result =
      await this.terminaisMensagensService.findAllTerminaisMensagens({
        ...this.mensagens.meta,
        vigente: this.vigente.value as boolean
      });

      this.mensagens.setMetaPage(result.meta)
      this.mensagens.setItems(result.items)
  }

  onPaginate(data: PageEvent) {
    this.mensagens.setCurrentPage(data.pageIndex + 1);
    this.fetchMensagens();
  }

  async onCreated({ id, mensagem, selectedTerminais }: any) {
    let terminaisId = selectedTerminais.map((obj: any) => obj.id);

    await this.terminaisMensagensService.createMensagem(
      { ...mensagem, user_created_id: this.authService.userCurrent()!.sub },
      terminaisId,
      id
    );
    this.fetchMensagens();
  }

  remove(id: number) {
    this.swal2.confirm('Tem certeza dessa operação?').then(async (result) => {
      if (result.isConfirmed) {
        await this.terminaisMensagensService.deleteMensagem(id);
        this.mensagens.setItems(this.mensagens.items.filter((item) => item.id !== id));
      }
    });
  }

  async edit(data: any) {
    this.scrollToTop();
    this.inputsValue = data;
  }

  async updateStatus(mensangem: TerminalFirmware) {
    if (mensangem.status === Status.ATIVO) {
      await this.terminaisMensagensService.inativar(mensangem.id);
      mensangem.status = Status.INATIVO;
      return;
    }

    await this.terminaisMensagensService.ativar(mensangem.id);
    mensangem.status = Status.ATIVO;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  formataPerido(element: any) {
    const dataIni = dayjs(element.periodo_inicio).format('DD/MM/YYYY HH:mm');
    const dataFim = dayjs(element.periodo_fim).format('DD/MM/YYYY HH:mm');
    return `${dataIni} - ${dataFim}`;
  }
}
