import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked {
  title = 'Plataforma Servlot';
  public show = false;
  showMenu: boolean = false;

  constructor(
    private loadingService: LoadingService,
    public sharedService: SharedService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.sharedService.currentAuth.subscribe(
      (serviceValue) => (this.showMenu = serviceValue)
    );
  }


  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
}
