<div>
  <div class="header">
    <h1>Horários de acesso </h1>
    <small>{{perfil?.nome}}</small>
  </div>

  <form [formGroup]="horariosForm" (ngSubmit)="onSubmit()">
    @for (item of horarios; track item; let i = $index) {
    <div [formGroup]="item">
        <h1>{{item.get('label')?.value}}</h1>
        <div class="time">
            <div>
                <mat-form-field appearance="outline">
                <mat-label>Hora inicial</mat-label>
                <input
                    formControlName="hora_inicio"
                    matInput
                    type="time"
                />
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Hora final</mat-label>
                <input
                formControlName="hora_fim"
                matInput
                type="time"
                />
            </mat-form-field>
        </div>
        </div>
    }

    <div class="btn-group">
        <button mat-flat-button color="warn" type="button" routerLink="/administracao/perfis">
            Cancelar
        </button>
        <button mat-flat-button color="primary" type="submit" [disabled]="horariosForm.invalid">
            Salvar
        </button>
    </div>
  </form>
</div>
