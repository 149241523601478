import { PermissaoService } from '@/services/permissao.service';
import { UsuarioPerfilService } from '@/services/usuario-perfil.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Permissao } from 'src/app/@types/permissao';
import { UsuarioPerfilPermissao } from 'src/app/@types/usuario-perfil';

@Component({
  selector: 'app-permissoes',
  templateUrl: './permissoes.component.html',
  styleUrls: ['./permissoes.component.scss'],
})
export class PermissoesComponent implements OnInit {
  loadingCount = 0;
  perfil!: UsuarioPerfilPermissao
  permissoesSelecionadas: number[] = []
  permissoes: Permissao[] = []
  allIsChecked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {perfilId: number},
    private usuarioPerfilService: UsuarioPerfilService,
    private permissaoService: PermissaoService,
    private dialogRef: MatDialogRef<PermissoesComponent>,
  ) {}

  isChecked(permissaoId: number) {
    return this.permissoesSelecionadas?.includes(permissaoId);
  }

  async checkboxChange(event: MatCheckboxChange, id: number) {
    const findIndex = this.permissoesSelecionadas.findIndex(item => item == id);
    if(findIndex !== -1) {
      this.allIsChecked = false;
      this.permissoesSelecionadas = this.permissoesSelecionadas.filter(item => item != id)
      return;
    }
    this.permissoesSelecionadas.push(id)
  }

  async atualizaPermissoes() {
    try {
      this.loadingCount++
      await this.usuarioPerfilService.updatePermissions(this.data.perfilId, {
        permissao_ids: this.permissoesSelecionadas
      })
      this.dialogRef.close(true)
    } finally {
      this.loadingCount--
    }
  }

  async buscaPermissoes() {
    try {
      this.loadingCount++
      this.permissoes = await this.permissaoService.findAll()
    } finally {
      this.loadingCount--
    }
  }

  async buscaPerfil() {
    try {
      this.loadingCount++
      this.perfil = await this.usuarioPerfilService.findOne(this.data.perfilId)
      this.permissoesSelecionadas = this.perfil.permissoes?.map(permissao => permissao.id)
    } finally {
      this.loadingCount--
    }
  }

  markAllChecked(event: MatCheckboxChange) {
    if(event.checked) {
      this.allIsChecked = event.checked
      this.permissoesSelecionadas = this.permissoes.map(permissao => permissao.id);
      return;
    }

    this.permissoesSelecionadas = [];
  }

  ngOnInit() {
    this.buscaPermissoes()
    this.buscaPerfil()
  }
}
