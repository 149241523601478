import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorsLabelComponent } from './errors-label.component';

@NgModule({
  declarations: [ErrorsLabelComponent],
  imports: [CommonModule, MatFormFieldModule],
  exports: [ErrorsLabelComponent],
})
export class ErrorsLabelModule {}
