import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'normalize'})
export class NormalizePipe implements PipeTransform {

  ucfirst(value: string) {
    const data = value.toLowerCase();
    return data.at(0)?.toUpperCase() + data.substring(1)
  }

  transform(value: string): string {
    const data = value?.trim()?.replace(/\_|\-/, ' ')?.split(' ');
    const parsedValue = data.map(this.ucfirst)?.join(' ')
    return parsedValue;
  }
}
