import { ProcessaCnab } from '@/models/pagamento.model';
import { LoadingService } from '@/services/loading.service';
import { PagamentoService } from '@/services/pagamento.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Bancos } from 'src/app/constants';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-pagamentos',
  templateUrl: './pagamentos.component.html',
  styleUrls: ['./pagamentos.component.scss']
})
export class PagamentosComponent implements OnInit {
  form: FormGroup;
  file: File
  bancos: any[] = []

  table: {
    columns: string[],
    data: ProcessaCnab
  } = {
    columns: [ 'pdvNome', 'nossoNumero', 'valor', 'valorPago', 'taxaBoleto', 'status' ],
    data: {
      banco_codigo: 0,
      banco_nome: "",
      boletos: [],
      empresa: "",
      erro_codigo: 0,
      msg_retorno: []
    }
  }

  resultadoQuitacao: any

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private pagamentoService: PagamentoService,
    private loading: LoadingService,
    private sweetAlert: SweetAlert2Service
  ){
    this.form = this.formBuilder.group({
      banco: [null, [Validators.required]],
      arquivo: [null, [Validators.required]],
    })
  }

  handleSelectedFile(event: Event) {
    const files = (<HTMLInputElement>event.target).files;
    if(!files?.length) return;
    this.file = files[0]
    this.form.patchValue({
      arquivo: files[0]
    })
  }

  async getBancos() {
    this.bancos = Bancos
  }

  showLoading() {
    this.sweetAlert.build({
      icon: 'info',
      title: 'Carregando...',
      text: 'Aguarde...',
      didOpen: () => {
        this.sweetAlert.loading()
      }
    })
  }

  async handleForm() {
    if(this.form.valid) {
      try {
        this.showLoading()
        const {arquivo, banco} = this.form.value
        const response = await this.pagamentoService.processaArquivoRetorno({
          banco: banco.id,
          file: arquivo
        })

        if(response.erro_msg && response.erro_codigo) {
          this.sweetAlert.info(response.erro_msg);
          return;
        }

        this.table.data = {
          ...response,
          boletos: response.boletos.sort((a,b) => {
            if(a.status === 'NAO_ENCONTRADO') {
              return -1;
            }

            if(a.status === 'JA_QUITADO') {
              return 1
            }

            return 0;
          })
        }
      } finally {
        this.sweetAlert.hideLoading()
      }
    }
  }

  mapStatus(status: 'JA_QUITADO' | 'PARA_QUITAR' | 'NAO_ENCONTRADO') {
    const statuses = {
      JA_QUITADO: 'Quitado',
      PARA_QUITAR: 'Não quitado',
      NAO_ENCONTRADO: 'Não encontrado',
    }

    return statuses[status] || 'Indefinido';

  }

  quitar() {
    this.sweetAlert.confirm('Deseja continuar?')
      .then(async result => {
        if(result.isConfirmed) {
          try {
            this.showLoading()
            const response = await this.pagamentoService.quitar()
            if(response.erro_msg && response.erro_codigo) {
              this.sweetAlert.info(response.erro_msg);
              return;
            }
            this.resultadoQuitacao = response;
          } catch(error) {
            console.log(error)
          } finally {
            this.sweetAlert.hideLoading()
          }
        }
      })
  }

  ngOnInit(): void {
    this.sharedService.changeAuth(true)
    this.getBancos()
  }
}
