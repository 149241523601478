<div class="container-fluid">
  <h1>Faturamento</h1>

  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="data">
        <button (click)="setDiaAnterior()" mat-icon-button color="primary">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-form-field appearance="outline">
          <mat-label>Data Faturamento</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="dataFat" (dateChange)="changeDataFat($event.value)" name="dataFat"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button (click)="setProximoDia()" mat-icon-button color="primary">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form>
        <h3 style="font-weight: 500;">
          Filtros
        </h3>
        <div class="search">
          <app-input-select-autocomplete
            label="Ponto de Venda"
            placeholder="Selecione"
            [options]="pontosDeVendas"
            [formControl]="pdv"
            optionLabel="search"
            (ngModelChange)="onSelectPdv()"
          ></app-input-select-autocomplete>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select [formControl]="inputStatus" (selectionChange)="searchFaturamentos()">
              <mat-option value="">TODOS</mat-option>
              <mat-option value="ABERTO">ABERTO</mat-option>
              <mat-option value="GERANDO">GERANDO</mat-option>
              <mat-option value="GERANDO_BOLETO">GERANDO_BOLETO</mat-option>
              <mat-option value="QUITADO">QUITADO</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select [formControl]="inputTipo" (selectionChange)="searchFaturamentos()">
              <mat-option value="">TODOS</mat-option>
              <mat-option value="BOLETO">BOLETO</mat-option>
              <mat-option value="RECOLHE">RECOLHE</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="modalidade">
            <h3>Modalidade:</h3>
            <mat-button-toggle-group [formControl]="modalidade" (change)="searchFaturamentos(dataFat)">
              <mat-button-toggle [value]="">Todos</mat-button-toggle>
              <mat-button-toggle *ngFor="let tipo of modalidades" [value]="tipo.id">
                {{tipo.label}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-spinner *ngIf="loading" style="margin: 0 auto"></mat-spinner>

  <div class="materialTableHeader d-flex">
    <h3>Recebimentos</h3>
  </div>
  <table mat-table [dataSource]="displayedItems">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="ponto_venda">
      <th mat-header-cell *matHeaderCellDef>Ponto de Venda</th>
      <td mat-cell *matCellDef="let element">{{element.ponto_venda.id + " - "+ element.ponto_venda.nome_comercial}}</td>
      <td mat-footer-cell *matFooterCellDef>∑ [{{faturamentoRecebimentos.length}}]</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'red': element.status !== 'ABERTO'}">{{element.status}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="forma_pagamento">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let element">{{element.forma_pagamento}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="data_inicio">
      <th mat-header-cell *matHeaderCellDef>Período</th>
      <td mat-cell *matCellDef="let element">{{this.formataPerido(element)}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="quantidade_transacoes">
      <th mat-header-cell *matHeaderCellDef>Transações</th>
      <td mat-cell *matCellDef="let element">{{element.quantidade_transacoes}}</td>
      <td mat-footer-cell *matFooterCellDef>{{this.somatoriaColunas.transacoes}}</td>
    </ng-container>

    <ng-container matColumnDef="valor_total_debito">
      <th mat-header-cell *matHeaderCellDef>Débito</th>
      <td mat-cell *matCellDef="let element">{{this.formataValorParaBRL(element.valor_total_debito)}}</td>
      <td mat-footer-cell *matFooterCellDef>{{this.somatoriaColunas.debito | currency: 'R$' }}</td>
    </ng-container>

    <ng-container matColumnDef="valor_total_credito">
      <th mat-header-cell *matHeaderCellDef>Crédito</th>
      <td mat-cell *matCellDef="let element">{{this.formataValorParaBRL(element.valor_total_credito)}}</td>
      <td mat-footer-cell *matFooterCellDef>{{this.somatoriaColunas.credito | currency: 'R$' }}</td>
    </ng-container>

    <ng-container matColumnDef="valor_liquido">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let element">{{this.formataValorParaBRL(element.valor_liquido)}}</td>
      <td mat-footer-cell *matFooterCellDef>{{this.somatoriaColunas.liquidoTotal | currency: 'R$' }}</td>
    </ng-container>

    <ng-container matColumnDef="qtd_boletos">
      <th mat-header-cell *matHeaderCellDef>Qtd. Boletos</th>
      <td mat-cell *matCellDef="let element">{{element.boletos.length.toString()}}</td>
      <td mat-footer-cell *matFooterCellDef>{{this.somatoriaColunas.qtdBoletos}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.status !== RECEBIMENTO_STATUS.CANCELADO">
          <button
            *ngIf="element.status === RECEBIMENTO_STATUS.QUITADO || element.status === RECEBIMENTO_STATUS.ABERTO && element.status !== RECEBIMENTO_STATUS.GERANDO"
            (click)="btnRecebimento(element.id)"
            mat-icon-button
            class="small-icon-button"
            matTooltip="Boletos"
            matTooltipPosition="above">
            <mat-icon>description</mat-icon>
          </button>
          <button *ngIf="element.status !== RECEBIMENTO_STATUS.GERANDO" [routerLink]="['/faturamentos', 'recebimento', element.id, 'lancamentos']"
            mat-icon-button
            class="small-icon-button"
            matTooltip="Lançamentos"
            matTooltipPosition="above">

            <mat-icon>currency_exchange</mat-icon>
          </button>

          <button
            *ngIf="element.status === RECEBIMENTO_STATUS.GERANDO || element.status === RECEBIMENTO_STATUS.GERANDO_BOLETO"
            (click)="gerarRecebimento(element.id)"
            mat-icon-button
            class="small-icon-button"
            matTooltip="Gerar Recebimento"
            matTooltipPosition="above">
            <mat-icon>paid</mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    <tr mat-footer-row *matFooterRowDef="columns"></tr>
  </table>
  <mat-paginator
    [length]="faturamentoRecebimentos.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[50, 100, 500, 1000]"
    [pageIndex]="currentPage"
    (page)="handleEvent($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>

