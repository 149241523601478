
<mat-dialog-content class="mat-typography container">
  <form class="container__form">
    <div class="row">
      <div
        *ngFor="let formData of data; index as i"
        ngClass="'{{formData.column}}': {{formData.column}}"
      >
        <mat-form-field appearance="outline" class="container__input">
          <mat-label>{{ formData?.label }}</mat-label>
          <input
            type="{{ formData?.type }}"
            matInput
            placeholder="{{ formData?.placeholder }}"
            prefix="{{formData?.prefix}}"
            mask="{{formData?.mask}}"
          />
          <mat-icon matSuffix>{{ formData.icon }}</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row">
      <div
        *ngFor="let formData of data; index as i; first as isFirs"
        class="col-12"
      >
        <div
          *ngFor="let select of formData.select; index as i; first as isFirs"
          class="col-12"
        >
          <mat-form-field appearance="outline" class="container__select">
            <mat-label></mat-label>
            <mat-select>
              <mat-option
                value="{{ fields.value }}"
                *ngFor="
                  let fields of select?.options;
                  index as i;
                  first as isFirs
                "
              >
                {{ fields.option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        *ngFor="let formData of data; index as i; first as isFirs"
        class="col-12 teste"
      >
        <div
          *ngFor="
            let textarea of formData.textarea;
            index as i;
            first as isFirs
          "
          class="col-12"
        >
          <mat-form-field appearance="outline" class="container__text-area">
            <mat-label>{{ textarea.label }}</mat-label>
            <textarea
              cdkTextareaAutosize
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="4"
              matInput
              placeholder="{{ textarea.placeholder }}"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div> -->
  </form>
</mat-dialog-content>
<mat-dialog-actions align="start" class="container__btn">
  <button mat-raised-button color="primary" (click)='save()'>Cadastrar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
