<div class="page">
  <div class="header">
    <h1>Bancos PDV</h1>
  </div>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form [formGroup]="filters">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Ponto de venda</mat-label>
          <input type="text" placeholder="Digite para pesquisar" matInput required [formControl]="myControl"
            [matAutocomplete]="auto" />
          <mat-autocomplete (optionSelected)="selectedPontoDeVenda($event)" [displayWith]="getOptionText"
            #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredPontosDeVenda" [value]="option">
              {{ option.id + " - " + option.nome_comercial }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Modalidade</mat-label>
          <mat-select formControlName="modalidade">
            <mat-option value="">TODOS</mat-option>
            <mat-option value="PRE">Pré Pago</mat-option>
            <mat-option value="POS">Pós Pago</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Configuração do Boleto</mat-label>
          <mat-select formControlName="boletoConfigId">
            <mat-option value="">TODOS</mat-option>
            @for (boletoConfig of boletoConfiguracoes; track boletoConfig.id) {
              <mat-option [value]="boletoConfig.id">{{boletoConfig.nome_config}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>

  @if(!selection.isEmpty()) {
    <mat-card appearance="outlined">
      <mat-card-content>
          <h3>Atualizar a configuração dos pontos selecionados</h3>
          <div class="form-update">
          <mat-form-field appearance="outline">
            <mat-label>Selecione a configuração</mat-label>
            <mat-select [formControl]="boletoConfigId">
              @for (boletoConfig of boletoConfiguracoes; track boletoConfig.id) {
                <mat-option [value]="boletoConfig.id">{{boletoConfig.nome_config}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <button mat-flat-button color="primary" (click)="onUpdate()" [disabled]="boletoConfigId.value === ''">Atualizar</button>
        </div>
      </mat-card-content>
    </mat-card>
  }

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary" (change)="$event ? toggleAllRows() : null"
          [checked]="isAllSelected"
          [indeterminate]="selection.hasValue() && !isAllSelected">
      </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="pdv">
      <th mat-header-cell *matHeaderCellDef>PDV</th>
      <td mat-cell *matCellDef="let boletoConf"> {{boletoConf.pdv_id}} - {{boletoConf.pdv}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let boletoConf">{{boletoConf.pdv_status}}</td>
    </ng-container>

    <ng-container matColumnDef="boletoConfig">
      <th mat-header-cell *matHeaderCellDef>Configuração do Boleto</th>
      <td mat-cell *matCellDef="let boletoConf">{{boletoConf.identificador}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20, 50]"
    pageSize="50"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
