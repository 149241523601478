import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@/services/auth.service';
import { UsuarioModel } from '@/models/usuario.model';

@Directive({
  selector: '[appCan]'
})
export class CanDirective {
  private hasView = false;
  private user: UsuarioModel | null

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
    ) {
      this.user = this.authService.userCurrent()
    }

  @Input() set appCan(permissions: string[]) {
    const userPermissions = this.user?.perfil.permissoes || []
    if(!userPermissions?.length) {
      this.viewContainer.clear();
      this.hasView = false;
      return;
    }

    if(!userPermissions.some(permission => permissions.includes(permission))) {
      this.viewContainer.clear();
      this.hasView = false;
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }
}
