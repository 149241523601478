import { Injectable } from '@angular/core';
import { Category } from '../models/produtos-categorias.model';
import { BaseApi } from './base/base-api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseApi {
  async findCategories(params: any = {}): Promise<Category[]> {
    return lastValueFrom(this.get(`${this.url}/categorias`, {
      params
    }));
  }

  async findCategoryById(id: number): Promise<Category> {
    return lastValueFrom(this.get(`${this.url}/categorias/${id}`))
  }
}
