import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BoletoConfiguracoesModel } from '../models/boleto-configuracoes.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BoletoConfiguracoesService {
  url = environment.baseURL;
  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<BoletoConfiguracoesModel[]>{
    return this.httpClient.get<BoletoConfiguracoesModel[]>(`${this.url}/boletos-configuracoes`)
  }

}
