import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wrapper-table',
  templateUrl: './wrapper-table.component.html',
  styleUrls: ['./wrapper-table.component.scss']
})
export class WrapperTableComponent {
  @Input() header:boolean = false;
}
