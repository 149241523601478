import { UsuarioPerfilService } from '@/services/usuario-perfil.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-horario-semana-acesso',
  templateUrl: './horario-semana-acesso.component.html',
  styleUrls: ['./horario-semana-acesso.component.scss'],
})
export class HorarioSemanaAcessoComponent implements OnInit {
  perfilId: number;
  horariosForm: FormGroup;

  perfil: any;

  diasSemana = [
    { label: 'Segunda', value: 1 },
    { label: 'Terça', value: 2 },
    { label: 'Quarta', value: 3 },
    { label: 'Quinta', value: 4 },
    { label: 'Sexta', value: 5 },
    { label: 'Sábado', value: 6 },
    { label: 'Domingo', value: 0 },
  ];

  constructor(
    private fb: FormBuilder,
    private perfilService: UsuarioPerfilService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.perfilId = this.route.snapshot.params['id'];
  }

  get horarios() {
    return (this.horariosForm.get('horarios') as FormArray)
      .controls as FormGroup[];
  }

  inicializarFormulario() {
    this.horariosForm = this.fb.group({
      horarios: this.fb.array(
        this.diasSemana.map((item) =>
          this.fb.group({
            dia_semana: [item.value],
            hora_inicio: [null, Validators.required],
            hora_fim: [null, Validators.required],
            label: item.label,
          })
        )
      ),
    });
  }

  async onSubmit() {
    if (!this.horariosForm.valid) {
      return;
    }

    const data = this.horariosForm.get('horarios')?.value.map((item: any) => ({
      dia_semana: item.dia_semana,
      hora_inicio: item.hora_inicio,
      hora_fim: item.hora_fim,
    }));
    await this.perfilService.updateHorarioAcesso(this.perfilId, data);

    this.snackBar.open('Dados atualizado com sucesso', 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.router.navigate(['/administracao/perfis']);
  }

  async getHorarioAcesso() {
    const data = await this.perfilService.findHorarioAcesso(this.perfilId);
    this.perfil = data;

    data?.horariosAcesso?.forEach((item: any) => {
      console.log({ item });
      const formItem = this.horarios.find(
        (form) => form.get('dia_semana')?.value === item.dia_semana
      );

      if (formItem) {
        formItem.patchValue({
          hora_inicio: item.hora_inicio,
          hora_fim: item.hora_fim,
        });
      }
    });
  }

  ngOnInit() {
    this.inicializarFormulario();
    this.getHorarioAcesso();
  }
}
