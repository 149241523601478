<div class="page">
    <div class="heading">
        <div>
            <h1>{{terminaisMensagemData.descricao || "-"}}</h1>
        </div>
        <div class="btn-actions">
            <button mat-flat-button color="warn" [routerLink]="['/administracao/mensagens-terminais']">Voltar</button>
            <button mat-flat-button color="primary" [routerLink]="['/administracao/mensagens-terminais/' + resourceId + '/editar']">Editar</button>
        </div>
    </div>

    <mat-card class="statuses">
        <mat-card-header>
            <mat-card-title>Status da mensagem</mat-card-title>
        </mat-card-header>
        <mat-card-content>
        
            <div class="grid grid-cols-3 gap-4">
                <div class="status" data-card="total">
                    <strong>{{terminaisMensagemData.numero_terminais || 0}}</strong>
                    <span>Enviadas</span>
                </div>
                <div class="status" data-card="recebidas">
                    <strong>{{terminaisMensagemData.mensagens_recebidas || 0}}</strong>
                    <span>Recebidas</span>
                </div>
                <div class="status" data-card="nao_recebidas">
                    <strong>{{terminaisMensagemData?.numero_terminais ? terminaisMensagemData.numero_terminais - terminaisMensagemData.mensagens_recebidas : 0}}</strong>
                    <span>Não recebidas</span>
                </div>
            </div>


        </mat-card-content>
    </mat-card>

    <mat-card class="geral">
        <mat-card-header>
            <mat-card-title>Dados da mensagem</mat-card-title>
            <button mat-icon-button color="primary" matTooltip="Editar" [routerLink]="['/administracao/mensagens-terminais/' + resourceId + '/editar']">
                <mat-icon>edit</mat-icon>
            </button>
        </mat-card-header>
        <mat-card-content>
            <div class="info">
                <small>Período vigente</small>
                @if (terminaisMensagemData?.id) {
                    <span>{{ terminaisMensagemData.periodo_inicio |  date: "short"}} até {{terminaisMensagemData.periodo_fim | date: "short"}}</span>
                }

                @else {
                    <span>-</span>
                }
            </div>

            <div class="info">
                <small>Descrição</small>
                <span>{{terminaisMensagemData.descricao || "-"}}</span>
            </div>
            <div class="info">
                <small>Mensagem</small>
                <div class="msg">
                    <form [formGroup]="editorForm">
                        <ngx-editor
                        [editor]="editor"
                        formControlName="editorContent"
                    ></ngx-editor>
                    </form>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="geral">
        <mat-card-header>
            <mat-card-title>Terminais</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table mat-table [dataSource]="terminaisAssoc.items">
                <ng-container matColumnDef="pdv">
                  <th mat-header-cell *matHeaderCellDef>Ponto de Venda</th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.pdv_id +
                        " - " +
                        element.pdv
                    }}
                  </td>
                </ng-container>
      
                <ng-container matColumnDef="pdv_status">
                  <th mat-header-cell *matHeaderCellDef>Status PDV</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pdv_status }}
                  </td>
                </ng-container>
      
                <ng-container matColumnDef="serial">
                  <th mat-header-cell *matHeaderCellDef>Serial</th>
                  <td mat-cell *matCellDef="let element">{{ element.serial }}</td>
                </ng-container>
      
                <ng-container matColumnDef="modelo">
                  <th mat-header-cell *matHeaderCellDef>Modelo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.modelo }}
                  </td>
                </ng-container>
      
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status Terminal</th>
                  <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                </ng-container>

                <ng-container matColumnDef="mensagem_status">
                    <th mat-header-cell *matHeaderCellDef>Status Mensagem</th>
                    <td mat-cell *matCellDef="let element">{{ element.mensagem_status }}</td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="columns"></tr>
                  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
              </table>
              <mat-paginator
                [length]="terminaisAssoc.total"
                [pageSize]="terminaisAssoc.limit"
                [pageIndex]="terminaisAssoc.page - 1"
                (page)="onPaginate($event)"
                showFirstLastButtons>
            </mat-paginator>
        </mat-card-content>
    </mat-card>

</div>