import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs';
import { PontosVendasProdForComissoesPadroesModel } from '@/models/pontos-vendas-prod-for-comissoes-padroes.model';
import { PontosVendasProdForComissoesDescModel } from '@/models/pontos-vendas-prod-for-comissoes-desc.model';

@Injectable({
  providedIn: 'root',
})
export class PontosVendasProdForComissoesPadroesService {
  url = environment.baseURL;
  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}
  getAllPontosVendasProdForComissoesPadroes() {
    return this.httpClient
      .get<PontosVendasProdForComissoesPadroesModel[]>(
        `${this.url}/pontos-vendas-prod-for-comissoes-padroes`
      )
      .pipe(catchError(this.authService.handleError as any));
  }
  getAllPontosVendasProdComissoesPadroesForDesc(id:number) {
    return this.httpClient
      .get<PontosVendasProdForComissoesPadroesModel[]>(
        `${this.url}/pontos-vendas-prod-for-comissoes-padroes/for-desc/${id}`
      )
      .pipe(catchError(this.authService.handleError as any));
  }
  findOnePontosVendasProdForComissoesPadroes(id: number) {
    return this.httpClient
      .get<PontosVendasProdForComissoesPadroesModel>(
        `${this.url}/pontos-vendas-prod-for-comissoes-padroes/${id}`
      )
      .pipe(catchError(this.authService.handleError as any));
  }
  createPontosVendasProdForComissoesPadroes(body: any) {
    return this.httpClient
      .post<any>(`${this.url}/pontos-vendas-prod-for-comissoes-padroes`, body)
      .pipe(catchError(this.authService.handleError as any));
  }
  editPontosVendasProdForComissoesPadroes(body: any) {
    const id = body.idComissoesPadroes;
    return this.httpClient
      .patch<any>(
        `${this.url}/pontos-vendas-prod-for-comissoes-padroes/${id}`,
        body
      )
      .pipe(catchError(this.authService.handleError as any));
  }
}
