import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecebimentoComponent } from './recebimento/recebimento.component';
import { LancamentosComponent } from './lancamentos/lancamentos.component';
import { FaturamentosComponent } from './faturamentos.component';
import { FaturamentosRoutingModule } from './faturamentos-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    RecebimentoComponent,
    LancamentosComponent,
    FaturamentosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FaturamentosRoutingModule,
    MatGridListModule,
    MatCardModule
  ],
  providers: []
})
export class FaturamentosModule {}
