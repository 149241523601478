import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as EmailValidator from 'email-validator';


export const validateFieldsUsuario = (insertObject: any) => {
  let validated:any = false;
  let isValid = {
    isValid: false,
    cpf_cnpj: false,
    email: false,
    fieldsEmpty: false,
  };
  let errorValidate = [];
  if (insertObject) {
    validated = isFieldEmpty(insertObject);
    errorValidate.push({fieldEmpty: validated});
    isValid.fieldsEmpty = validated
  }
  if (insertObject.cpf_cnpj) {
    validated = validateCpf(insertObject.cpf_cnpj);
    errorValidate.push({cpf: validated});
    isValid.cpf_cnpj = validated
  }
  if (insertObject.email) {
    validated = validateEmail(insertObject.email);
    errorValidate.push({email: validated});
    isValid.email = validated
  }
  const checkField = errorValidate.filter((validate) => validate.fieldEmpty === false || validate.cpf === false || validate.email === false);
  if (checkField.length > 0) {
    isValid.isValid = false
  } else {
     isValid.isValid = true;
  }
  return isValid
};

const validateCpf = (cpfCnpj: string) => {
  if (cpfCnpj.length == 14) {
    return cnpj.isValid(cpfCnpj);
  } else if (cpfCnpj.length == 11) {
    return cpf.isValid(cpfCnpj);
  } else {
    return false;
  }
};

const validateEmail = (email: string) => {
  return EmailValidator.validate(email);
};

const isFieldEmpty = (insertObject: any) => {
  let arrayFields: any = [];
  Object.entries(insertObject).forEach(([key, value]) => {
    arrayFields.push({ key: value });
  });

  const checkFields = arrayFields.filter((item: any) => item.key === '');
  if (checkFields.length === 0) {
    return true;
  } else {
    return false;
  }
};
