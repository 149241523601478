import { LoadingService } from '@/services/loading.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-progress-bar-loading',
  templateUrl: './progress-bar-loading.component.html',
  styleUrls: ['./progress-bar-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarLoadingComponent {
  constructor(public loadingService: LoadingService) {}
}
