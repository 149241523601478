<div class="result">
  <div class="btn-back">
    <button mat-stroked-button color="primary" (click)="finalizar.emit()">Voltar</button>
  </div>
  <app-alert>
    <h2>Resumo</h2>
    <ul>
      <li>
        <strong>Boletos quitados: </strong>{{resultado?.boletos_quitados?.qtde || 0}}
      </li>
      <li>
        <strong>Valor: </strong>{{resultado.boletos_quitados.valor | currency: "R$"}}
      </li>
    </ul>
  </app-alert>

  <div class="materialTableHeader">
    <h3>Boletos cancelados</h3>
  </div>
  <table *ngIf="resultado.boletos_cancelados" mat-table [dataSource]="resultado.boletos_cancelados">
    <ng-container matColumnDef="pdv_nome">
      <th mat-header-cell *matHeaderCellDef>Empresa</th>
      <td mat-cell *matCellDef="let element">{{element.pdv_nome}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_nosso_numero">
      <th mat-header-cell *matHeaderCellDef>Nosso número</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_nosso_numero}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_valor">
      <th mat-header-cell *matHeaderCellDef>Valor pago</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_valor | currency: 'R$'}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_data_vencimento">
      <th mat-header-cell *matHeaderCellDef>Data de vencimento</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_data_vencimento | date: 'dd/MM/yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Situação</th>
      <td class="danger" mat-cell *matCellDef="let element">Cancelado</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="boletosCancelados"></tr>
    <tr mat-row *matRowDef="let row; columns: boletosCancelados;"></tr>
  </table>

  <div style="margin-top: 32px;" class="materialTableHeader">
    <h3>Boletos divergentes</h3>
  </div>
  <table *ngIf="resultado.boletos_divergentes" mat-table [dataSource]="resultado.boletos_divergentes">
    <ng-container matColumnDef="pdv_nome">
      <th mat-header-cell *matHeaderCellDef>Empresa</th>
      <td mat-cell *matCellDef="let element">{{element.pdv_nome}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_nosso_numero">
      <th mat-header-cell *matHeaderCellDef>Nosso número</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_nosso_numero}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_valor_documento">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_valor_documento | currency: 'R$'}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_valor_pago">
      <th mat-header-cell *matHeaderCellDef>Valor pago</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_valor_pago | currency: 'R$'}}</td>
    </ng-container>

    <ng-container matColumnDef="boleto_data_vencimento">
      <th mat-header-cell *matHeaderCellDef>Data de vencimento</th>
      <td mat-cell *matCellDef="let element">{{element.boleto_data_vencimento | date: 'dd/MM/yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Situação</th>
      <td class="warning" mat-cell *matCellDef="let element">Divergente</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="boletosDivergentes"></tr>
    <tr mat-row *matRowDef="let row; columns: boletosDivergentes;"></tr>
  </table>
</div>
