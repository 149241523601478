<div class="administracao">
  <h1>Menu Administrativo</h1>
  <div class="reports mt-4">
    <div
      class="reports__item"
      routerLink="/home"
      *appCan="[Permissao.DASHBOARD]"
    >
      <mat-icon>dashboard</mat-icon>
      <h2 class="reports__title">Estatísticas</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/usuarios/lista"
      *appCan="[Permissao.ADM_USUARIOS_LISTA]"
    >
      <mat-icon>people</mat-icon>
      <h2 class="reports__title">Usuários</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/perfis"
      *appCan="[Permissao.ADM_PERFIS_PERMISSOES]"
    >
      <mat-icon>key</mat-icon>
      <h2 class="reports__title">Perfis/Permissões</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/comissoes-padroes-lista"
      *appCan="[Permissao.ADM_COMISSAO_PADRAO]"
    >
      <mat-icon>assignment</mat-icon>
      <h2 class="reports__title">Comissões Padroes</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/terminais-firmware"
      *appCan="[Permissao.ADM_TERMINAIS_FIRMWARE]"
    >
      <mat-icon>apps</mat-icon>
      <h2 class="reports__title">Terminais Firmware</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/atualização-terminal"
      *appCan="[Permissao.ADM_TERMINAIS_ATUALIZACAO]"
    >
      <mat-icon>apps</mat-icon>
      <h2 class="reports__title">Atualização de Terminais</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/processamento-cnab"
      *appCan="[Permissao.ADM_PROCESSAMENTO_CNAB]"
    >
      <mat-icon>post_add</mat-icon>
      <h2 class="reports__title">Processamento CNAB</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/dias-de-faturamento"
      *appCan="[Permissao.ADM_DIAS_FATURAMENTO_LISTA]"
    >
      <mat-icon>calendar_month</mat-icon>
      <h2 class="reports__title">Dias de Faturamento</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/estornar-pix"
      *appCan="[Permissao.ADM_ESTORNO_PIX]"
    >
      <mat-icon>sync</mat-icon>
      <h2 class="reports__title">Estornar Pix</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/alterar-status-recarga"
      *appCan="[Permissao.ADM_STATUS_RECARGA]"
    >
      <mat-icon>published_with_changes</mat-icon>
      <h2 class="reports__title">Alterar Status de Recarga</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/mensagens-terminais"
      *appCan="[Permissao.ADM_MENSAGEM_TERMINAL]"
    >
      <mat-icon>message</mat-icon>
      <h2 class="reports__title">Mensagens para Terminais</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/feriados"
      *appCan="[Permissao.ADM_FERIADOS]"
    >
      <mat-icon>event</mat-icon>
      <h2 class="reports__title">Feriados</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/bancos-pdv"
      *appCan="[Permissao.ADM_BANCOS_PDV]"
    >
      <mat-icon>account_balance</mat-icon>
      <h2 class="reports__title">Bancos PDV</h2>
    </div>
    <div
      class="reports__item"
      routerLink="/administracao/hipercap-produtos"
      *appCan="[Permissao.ADM_HIPERCAP]"
    >
      <mat-icon>bubble_chart</mat-icon>
      <h2 class="reports__title">Hipercap Produtos</h2>
    </div>
  </div>
</div>
