<h1 mat-dialog-title>Permissões de listagem de produtos da Hipercap</h1>
<div mat-dialog-content>
  <div>
    <strong>Ponto de venda:</strong>
    {{ data.data.pdv_nome }}
  </div>

  <form [formGroup]="form">
    <div formArrayName="items">
      @for(item of itemsArray.controls; track item; let idx = $index) {
      <div [formGroupName]="idx">
        <mat-slide-toggle
          class="example-margin"
          color="primary"
          formControlName="active"
        >
          {{ products[idx].descricao }}
        </mat-slide-toggle>
      </div>
      }
    </div>
  </form>
</div>
<div mat-dialog-actions class="btn-actions">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
  <button mat-button (click)="update()" color="primary">
    {{ !loading ? "Salvar" : "Salvando..." }}
  </button>
</div>
