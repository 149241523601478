import { Status } from '@/enums/Status';
import { TerminalFirmware } from '@/models/terminal-firmware.model';
import { LoadingService } from '@/services/loading.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { TerminaisFirmwareService } from '@/services/terminais-firmware.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-terminais-firmware',
  templateUrl: './terminais-firmware.component.html',
  styleUrls: ['./terminais-firmware.component.scss']
})
export class TerminaisFirmwareComponent implements OnInit {
  terminaisFirmwares: TerminalFirmware[] = []
  tableColumns = ['modelo', 'perfil', 'versao', 'descricao', 'caminho', 'arquivo', 'servidor', 'status', 'acoes'];

  constructor(
    private sharedService: SharedService,
    private terminaisFirmwareService: TerminaisFirmwareService,
    private loading: LoadingService,
    private swal2: SweetAlert2Service
  ) {}

  async fetchTerminaisFirmware() {
    this.loading.show()
    this.terminaisFirmwareService.searchTerminaisfirmware()
    .subscribe({
      next: data => {
        this.terminaisFirmwares = data;
      },
      complete: () => this.loading.hide()
    })
  }

  remove(id: number) {
    this.swal2.confirm('Tem certeza dessa operação?')
      .then(async result => {
        if(result.isConfirmed) {
          await this.terminaisFirmwareService.remove(id);
          this.terminaisFirmwares = this.terminaisFirmwares.filter(item => item.id !== id)
        }
      })
  }

  async updateStatus(terminalFirmware: TerminalFirmware) {
    if(terminalFirmware.status === Status.ATIVO) {
      await this.terminaisFirmwareService.inativar(terminalFirmware.id)
      terminalFirmware.status = Status.INATIVO
      return;
    }

    await this.terminaisFirmwareService.ativar(terminalFirmware.id)
    terminalFirmware.status = Status.ATIVO
  }

  onCreated(data: any) {
    this.terminaisFirmwares = [
      data,
      ...this.terminaisFirmwares,
    ]
  }

  ngOnInit() {
    this.sharedService.changeAuth(true)
    this.fetchTerminaisFirmware()
  }
}
