import { AuthService } from '@/services/auth.service';
import { LoadingService } from '@/services/loading.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { TerminaisAtualizacoesService } from '@/services/terminais-atualizacoes.service';
import { TerminaisAtualizacaoModel } from '@/models/terminais-atualizacao.model';
import { TerminaisModeloService } from '@/services/terminais-modelo.service';
import { TerminaisModeloModel } from '@/models/terminais-modelo.model';
import { TerminaisFirmwareService } from '@/services/terminais-firmware.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-atualizacao-terminal',
  templateUrl: './atualizacao-terminal.component.html',
  styleUrls: ['./atualizacao-terminal.component.scss'],
})
export class AtualizacaoTerminalComponent implements OnInit {
  terminaisAtualizacao = new TerminaisAtualizacaoModel();
  terminaisModelo: TerminaisModeloModel[];
  terminaisFirmware: any[];
  formFilters!: FormGroup

  selection = new SelectionModel<any>(true, [])
  tableColumns = ['select', 'ponto_venda', 'serial', 'status',
    'terminal_modelo', 'firmware_perfil', 'firmware_versao', 'versao_atual',
    'versao_req', 'dt_req_atualizacao_firmware', 'dt_confirmacao_versao',
    'atualizacao_automatica'
  ];
  terminais: any[] = []
  tipoVersao = "all"

  dataSource: any;
  @ViewChild(MatSort) sort !: MatSort;

  constructor(
    private loadingService: LoadingService,
    public authService: AuthService,
    private terminaisAtualizacoesService: TerminaisAtualizacoesService,
    private terminaisModeloService: TerminaisModeloService,
    private terminaisFirmwareService: TerminaisFirmwareService,
    private sharedService: SharedService,
    private swal: SweetAlert2Service,
    private formBuilder: FormBuilder
  ) {
    this.formFilters = this.formBuilder.group({
      modelo: null,
      firmware: [''],
    })

    this.formFilters?.valueChanges.subscribe(() => {
      this.buscaTerminais()
    });
  }

  get isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.terminais.length;
    return numSelected > 0 && numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.terminais);
  }

  async buscaTerminais() {
    try {
      this.loadingService.show();
      const { modelo, firmware } = this.formFilters.value
      this.terminais = await this.terminaisAtualizacoesService.findAll({
        terminal_modelo_id: modelo,
        firmware_perfil: firmware,
        tipo_versao: this.tipoVersao
      });
      this.dataSource = new MatTableDataSource(this.terminais);
      this.dataSource.sort = this.sort;

    } finally {
      this.loadingService.hide();
    }
  }

  async buscaPerfis() {
    const { modelo } = this.formFilters.value
    if(!modelo) return;
    this.terminaisFirmwareService.perfis({terminalModeloId: modelo}).then(data => {
      this.terminaisFirmware = data
    });
  }

  get firmwareAtual() {
    const { modelo } = this.formFilters.value
    if(!modelo) return '---';

    return this.terminaisModelo.find(item => +item.id === +modelo)?.versao_atual ?? '---'
  }

  atualizacaoAutomatica(ativar: boolean) {
    this.swal.build({
      icon: 'question',
      title: 'Atenção!',
      html: `
        Deseja <span
          style="${ativar ? 'color:#29C76F' : 'color:#EA5455'};"
        >${ativar ? 'ativar' : 'desativar'}</span> a atualização automática para os terminais selecionados?
      `,
      confirmButtonText: ativar ? 'Sim, ATIVAR' : 'Sim, DESATIVAR',
      cancelButtonText: 'Cancelar',
      showCancelButton: true
    }).then(async response => {
      if(response.isConfirmed) {
        try {
          await this.terminaisAtualizacoesService.alteraAtualizacaoAutomatica({
            terminal_ids: this.selection.selected?.map(item => +item.terminal_id),
            enabled: ativar
          })
          this.swal.success('Sucesso!')
          this.selection.clear()
          this.buscaTerminais();
        } catch(error) {
          this.swal.error('Algo inesperado aconteceu!')
        }
      }
    })
  }


  ngOnInit(): void {
    this.sharedService.changeAuth(true);
    this.terminaisModeloService.findAllTerminaisModelo().subscribe(
      (res) => {
        this.terminaisModelo = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
