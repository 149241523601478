import { UsuarioPerfilService } from '@/services/usuario-perfil.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioPerfilPermissaoCount } from 'src/app/@types/usuario-perfil';
import { SharedService } from 'src/app/shared/shared.service';
import { PermissoesComponent } from './components/permissoes/permissoes.component';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { CreatePermissaoComponent } from './components/create-permissao/create-permissao.component';

@Component({
  selector: 'app-perfis',
  templateUrl: './perfis.component.html',
  styleUrls: ['./perfis.component.scss']
})
export class PerfisComponent implements OnInit {
  perfis: UsuarioPerfilPermissaoCount[] = []

  tableColumns = ['id', 'nome', 'status', 'totalPermissoes', 'acoes']

  constructor(
    private sharedService: SharedService,
    private usuarioPerfilService: UsuarioPerfilService,
    private dialog: MatDialog,
    private swal: SweetAlert2Service
  ) {}

  async buscarPerfis() {
    this.perfis = await this.usuarioPerfilService.findAll()
  }

  showPermissions(id: number) {
    const dialogRef = this.dialog.open(PermissoesComponent, {
      width: '800px',
      data: {
        perfilId: id
      }
    })

    dialogRef.afterClosed().subscribe(data => {
      if(data) {
        this.swal.success('Sucesso!')
        this.buscarPerfis()
      }
    })
  }

  add(){
    const dialogRef = this.dialog.open(CreatePermissaoComponent, {
      width: '800px',
    })
  }

  ngOnInit() {
    this.sharedService.changeAuth(true)
    this.buscarPerfis()
  }
}
