import { InputType } from '@/models/input-type.model';
import { DialogService } from '@/services/dialog.service';
import { Component, Input, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() buttonCreate: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InputType[], public myDialogService: DialogService) { }

  ngOnInit(): void {}

  save() {
    this.myDialogService.save.next();
  }
}
