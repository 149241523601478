import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogComponent } from '../components/dialog/dialog.component';
import { InputType } from '../models/input-type.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) { }
  public save: EventEmitter<void> = new EventEmitter();

  dialogForm(data: InputType[]): Observable<boolean> {
    return this.dialog.open(DialogComponent, {
      data,
      width: '650px',
      height: '700px',
    }).afterClosed();
  }
}
