<div class="container">
  <h1>Agenda de Faturamento de Pontos de Venda</h1>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form [formGroup]="formFilter">
        <app-input-select-autocomplete
          label="Ponto de venda"
          placeholder="Selecione"
          [options]="pontoDeVendas"
          formControlName="pdv"
          optionLabel="search"
        ></app-input-select-autocomplete>
      </form>
    </mat-card-content>
  </mat-card>

  <div class="materialTableHeader d-flex">
    <h3>Dias de faturamento</h3>
  </div>
  <table mat-table [dataSource]="diasDeFaturamentos">
    <ng-container matColumnDef="nome_comercial">
      <th mat-header-cell *matHeaderCellDef>Ponto de venda</th>
      <td mat-cell *matCellDef="let element">{{element.ponto_venda.nome_comercial}}</td>
    </ng-container>

    <ng-container matColumnDef="data_inicio">
      <th mat-header-cell *matHeaderCellDef>Data inicial</th>
      <td mat-cell *matCellDef="let element">{{this.formataData(element.data_inicio)}}</td>
    </ng-container>

    <ng-container matColumnDef="data_fim">
      <th mat-header-cell *matHeaderCellDef>Data final</th>
      <td mat-cell *matCellDef="let element">{{this.formataData(element.data_fim)}}</td>
    </ng-container>

    <ng-container matColumnDef="data_faturamento">
      <th mat-header-cell *matHeaderCellDef>Data faturamento</th>
      <td mat-cell *matCellDef="let element">{{this.formataData(element.data_faturamento)}}</td>
    </ng-container>

    <ng-container matColumnDef="data_vencimento">
      <th mat-header-cell *matHeaderCellDef>Data vencimento</th>
      <td mat-cell *matCellDef="let element">{{this.formataData(element.data_vencimento)}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let element">
        <button *appCan="[Permissao.ADM_DIAS_FATURAMENTO_EDIT]" mat-icon-button (click)="handleAlterarFaturamento(element.id)" class="small-icon-button">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>

  </table>
</div>

