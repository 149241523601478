import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, Observable } from 'rxjs';
import { FornecedoresModel } from '@/models/fornecedores.model';

@Injectable({
  providedIn: 'root',
})
export class FornecedoresService {
  url = environment.baseURL;
  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  findAllFornecedores(): Observable<FornecedoresModel[]> {
    return this.httpClient
      .get<FornecedoresModel[]>(`${this.url}/fornecedores`)
      .pipe(catchError(this.authService.handleError as any));
  }

  findCategoriasEmFornecedores(
    categoria_id: number
  ): Observable<FornecedoresModel[]> {
    return this.httpClient
      .get<FornecedoresModel[]>(
        `${this.url}/fornecedores/categoria/${categoria_id}`
      )
      .pipe(catchError(this.authService.handleError as any));
  }
}
