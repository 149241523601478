import { FeriadoService } from '@/services/feriado.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-create-holiday-dialog',
  templateUrl: './create-holiday-dialog.component.html',
  styleUrls: ['./create-holiday-dialog.component.scss']
})
export class CreateHolidayDialogComponent {

  loading = false;
  descricao = new FormControl<string>('', Validators.required)

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {data: Date},
    private swal: SweetAlert2Service,
    private dialogRef: MatDialogRef<CreateHolidayDialogComponent>,
    private feriadoService: FeriadoService
  ) {}


  get formattedData() {
    return moment(this.data.data).format('DD/MM/YYYY')
  }

  async create() {
    try {
      this.loading = true
      const response = await this.feriadoService.create({
        descricao: this.descricao.value as string,
        data: moment(this.data.data).format('YYYY-MM-DD')
      })
      this.dialogRef.close({result: response})
      this.swal.success('Feriado registrado com sucesso!')
    } catch(error) {
      this.swal.error('Não foi possivel registrar o feriado. Tente novamente!')
    } finally {
      this.loading = false
    }
  }
}
