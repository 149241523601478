import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, catchError } from 'rxjs';
import { PontosVendaProdutosGruposExceccoes } from '@/models/pontos-venda-produtos-grupos-excecoes.mode';

@Injectable({
  providedIn: 'root',
})
export class PontosVendaProdutosGruposExcecoesService {
  url = environment.baseURL;
  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  createPontosDeVendaProdutosGruposExceccoes(
    pontoVendaId: number | undefined,
    createPontosVendaProdutosGruposExcecoes?: PontosVendaProdutosGruposExceccoes[]
  ) {
    return this.httpClient
      .post<PontosVendaProdutosGruposExceccoes>(
        `${this.url}/pontos-venda-produtos-grupos-excecoes`,
        { pontoVendaId, createPontosVendaProdutosGruposExcecoes }
      )
      .pipe(catchError(this.authService.handleError as any));
  }

  findForPontoVenda(id:number): Observable<PontosVendaProdutosGruposExceccoes[]> {
    return this.httpClient
    .get<PontosVendaProdutosGruposExceccoes[]>(`${this.url}/pontos-venda-produtos-grupos-excecoes/${id}`)
    .pipe(catchError(this.authService.handleError as any));
}


  updatePontosDeVenda(id: string, createPontosVendaProdutosGruposExcecoes: any) {
    return this.httpClient
      .post<PontosVendaProdutosGruposExceccoes>(
        `${this.url}/pontos-venda-produtos-grupos-excecoes/${id}`,
        createPontosVendaProdutosGruposExcecoes
      )
      .pipe(catchError(this.authService.handleError as any));
  }
}
