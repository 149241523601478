import { Modalidade } from "@/enums/Modalidade";
import { ModalidadePDV } from "@/enums/ModalidadePDV";

export const MODALIDADES = [Modalidade.AUTOMATICO, Modalidade.FECHA_FATURA]

export const ModalidadesOptions = [
  {id: Modalidade.AUTOMATICO, label: 'Automático'},
  {id: Modalidade.FECHA_FATURA, label: 'Fecha fatura'},
  {id: Modalidade.PREPAGO, label: 'Pré-Pago'},
]

export const ModalidadesPDVOptions = [
  {id: ModalidadePDV.POS, label: 'Pós-pago'},
  {id: ModalidadePDV.PRE, label: 'Pré-Pago'},
]

export const Bancos = [
  {
    id: 'BRADESCO',
    name: 'Bradesco'
  },
  {
    id: 'ITAU',
    name: 'Itaú'
  }
]
