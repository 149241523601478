import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseApi } from './base/base-api.service';
import { ProcessaArquivoRetornoDto } from '@/models/dto/pagamentos/processa-arquivo-retorno.dto';
import { ProcessaCnab } from '@/models/pagamento.model';

@Injectable({
  providedIn: 'root',
})
export class PagamentoService extends BaseApi {
  async processaArquivoRetorno(data: ProcessaArquivoRetornoDto): Promise<ProcessaCnab> {
    const formData = new FormData();
    formData.append('banco', data.banco,)
    formData.append('file', data.file);

    return lastValueFrom(this.post(`${this.url}/pagamentos/processa-arquivo-retorno`, formData))
  }

  async quitar(): Promise<any> {
    return lastValueFrom(this.post(`${this.url}/pagamentos/quitar`, null))
  }
}
