import { InputComponent } from '../../shared/input/input.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

type InputSize = 'md' | 'lg';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent extends InputComponent<string> {
  @Input() mask: string = '';
  @Input() sufix!: string;
  @Input() prefix!: string;
  @Input() showMaskTyped: boolean = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() size: InputSize = 'md';
  @Input() type = 'text';

  @Output()
  sufixClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() onKeyEnter: EventEmitter<KeyboardEvent> =
    new EventEmitter<KeyboardEvent>();

  handleKeyEnter(event: any) {
    this.onKeyEnter.emit(event);
  }
}
