<mat-form-field class="input" appearance="outline" color="primary" (click)="touched = true">
  <mat-label class="mb-xs" *ngIf="label" [ngClass]="{ asterix: required }">{{ label }}</mat-label>
  <input
    matInput
    type="text"
    [formControl]="inputFormControl"
    (click)="handleClick($event); onTouched()"
    (blur)="checkOption()"
    [errorStateMatcher]="matcher"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    (optionSelected)="optionClick($event)"
    [displayWith]="displayFn"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ _optionLabel(option) }}
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix color="primary">arrow_drop_down</mat-icon>
  <mat-error>
    <app-errors-label
      [label]="label"
      *ngIf="touched && ngControl.errors"
      [errors]="ngControl.errors"
    ></app-errors-label>
  </mat-error>
</mat-form-field>
