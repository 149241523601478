<div class="page">
  <h1 align="center">Gerenciamento de feriados</h1>

  <div class="container">
    <mat-card class="calendar-wrapper">
      <mat-calendar
        (selectedChange)="onChange($event)"
        (viewChanged)="updateDayStyles()"
        (monthSelected)="updateDayStyles()"
        change
      ></mat-calendar>
    </mat-card>

   <mat-card class="list-wrapper">
    <mat-card-content>
      <h3 align="center">Feriados cadastrados no mês</h3>
      <ul>
        <li *ngFor="let item of holidaysThisMonth" class="item">
          <p>
            <strong>Data:</strong> {{ item.data | date: "dd/MM/yyyy"}}
          </p>
          <p>
            <strong>Descrição:</strong> {{ item.descricao }}<br>
          </p>
          <p>
            <strong>Usuário:</strong> {{ item.usuario.nome }}<br>
          </p>
      </ul>
    </mat-card-content>
   </mat-card>
  </div>
</div>
