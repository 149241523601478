import { TerminaisModel } from '@/models/terminais.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, map } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { TerminaisTransferenciasModel } from '@/models/terminais-transferencias.model';

@Injectable({
  providedIn: 'root',
})
export class TerminaisService {
  url = environment.baseURL;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  createTerminais(body: any) {
    return this.httpClient
      .post<TerminaisModel>(`${this.url}/terminais`, body)
      .pipe(catchError(this.authService.handleError as any));
  }

  searchTerminais(query?: string): Observable<TerminaisModel[]> {
    return this.httpClient
      .get<TerminaisModel[]>(`${this.url}/terminais/search?${query}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  partialSearchTerminais(query?: string): Observable<TerminaisModel[]> {
    return this.httpClient
      .get<TerminaisModel[]>(`${this.url}/terminais/partial?${query}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  searchTerminaisFirmareModelo(query?: string): Observable<TerminaisModel[]> {
    return this.httpClient
      .get<TerminaisModel[]>(
        `${this.url}/terminais/search/firware-modelo?${query}`
      )
      .pipe(catchError(this.authService.handleError as any));
  }

  findOneTerminais(id: number): Observable<TerminaisModel> {
    return this.httpClient
      .get<TerminaisModel>(`${this.url}/terminais/${id}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  findOneTerminaisSerial(serial: string): Observable<TerminaisModel> {
    return this.httpClient
      .get<TerminaisModel>(`${this.url}/terminais/serial/${serial}`)
      .pipe(catchError(this.authService.handleError as any));
  }

  updateTerminais(id: number, body: any) {
    return this.httpClient
      .put<TerminaisModel>(`${this.url}/terminais/${id}`, body)
      .pipe(catchError(this.authService.handleError as any));
  }

  transferirPontoDeVenda(
    terminais_transferenciais?: TerminaisTransferenciasModel
  ) {
    return this.httpClient
      .post<TerminaisModel>(
        `${this.url}/terminais/transferir`,
        terminais_transferenciais
      )
      .pipe(catchError(this.authService.handleError as any));
  }

  toggleTerminalStatus(id: number, status: string) {
    return this.httpClient
      .put(`${this.url}/terminais/toggle/${id}`, { status })
      .pipe(catchError(this.authService.handleError as any));
  }

  checkSerial(serial: string) {
    return this.httpClient
      .get(`${this.url}/terminais/verificar-serial/${serial}`)
      .pipe(catchError(this.authService.handleError as any));
  }

}
