import { Injectable } from '@angular/core';
import { BaseApi } from './base/base-api.service';
import { lastValueFrom } from 'rxjs';
import { Permissao } from '../@types/permissao';

@Injectable({
  providedIn: 'root',
})
export class PermissaoService extends BaseApi {

  findAll(): Promise<Permissao[]> {
    return lastValueFrom(this.get(`${this.url}/permissoes`))
  }

  create(data: any) {
    return lastValueFrom(this.post(`${this.url}/permissoes`, data))
  }
}
