<div class="usuarios">
  <div class="header">
    <h1>Usuários</h1>
    <button mat-mini-fab color="primary" (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form>
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Pesquisar</mat-label>
          <input matInput [formControl]="inputSearch" (keydown.enter)="searchUsuarios()" placeholder="Ex: Fulano" #input>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select [formControl]="inputStatus">
            <mat-option value="">TODOS</mat-option>
            <mat-option value="ATIVO">ATIVO</mat-option>
            <mat-option value="INATIVO">INATIVO</mat-option>
            <mat-option value="BLOQUEADO">BLOQUEADO</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>

  <table mat-table [dataSource]="usuarios">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let usuario"> {{usuario.id}} </td>
    </ng-container>

    <ng-container matColumnDef="perfil">
      <th mat-header-cell *matHeaderCellDef>Perfil</th>
      <td mat-cell *matCellDef="let usuario"> {{usuario.perfil.nome}} </td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let usuario"> {{usuario.nome}} </td>
    </ng-container>

    <ng-container matColumnDef="login">
      <th mat-header-cell *matHeaderCellDef>Login</th>
      <td mat-cell *matCellDef="let usuario"> {{usuario.login}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let usuario"> {{usuario.status}} </td>
    </ng-container>

    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let usuario">
        <button
          mat-icon-button
          class="small-icon-button"
          matTooltip="Editar"
          (click)="edit(usuario.id)"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
</div>
