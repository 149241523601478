import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatatableColumn } from '@/models/datatable-column.model';

@Component({
  selector: 'app-resultado-quitacao',
  templateUrl: './resultado-quitacao.component.html',
  styleUrls: ['./resultado-quitacao.component.scss']
})
export class ResultadoQuitacaoComponent implements OnInit {
  @Input() resultado: any
  @Output() finalizar = new EventEmitter()
  boletosCancelados: string[] = ['pdv_nome', 'boleto_nosso_numero', 'boleto_valor', 'boleto_data_vencimento', 'status']
  boletosDivergentes: string[] = ['pdv_nome', 'boleto_nosso_numero', 'boleto_valor_documento', 'boleto_valor_pago', 'boleto_data_vencimento', 'status' ]

  constructor() { }

  ngOnInit(): void {
  }

}
