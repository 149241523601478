import {
  HipercapPdvProduct,
  HipercapProduct,
} from '@/models/hipercap-product.model';
import { HipercapService } from '@/services/hipercap.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, tap } from 'rxjs';

interface Product {
  id: number;
  active: boolean;
}

interface Payload {
  [key: number]: Array<Product>;
}

@Component({
  selector: 'app-hipercap-mass-update-product-dialog',
  templateUrl: './hipercap-mass-update-product-dialog.component.html',
  styleUrls: ['./hipercap-mass-update-product-dialog.component.scss'],
})
export class HipercapMassUpdateProductDialogComponent implements OnInit {
  loading = false;
  items: HipercapPdvProduct[] = [];
  filteredItems: HipercapPdvProduct[] = [];
  displayedColumns: string[] = [];
  form: FormGroup;
  handlerSubmitData: Payload = {};
  isSearching = false;

  search = new FormControl({ value: '', disabled: false });

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewPort: CdkVirtualScrollViewport;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public hipercapProducts: HipercapProduct[],
    private hipercapService: HipercapService,
    private dialogRef: MatDialogRef<HipercapMassUpdateProductDialogComponent>,
    private swal: SweetAlert2Service
  ) {}

  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }
    let offset = this.viewPort['_renderedContentOffset'];
    return `-${offset}px`;
  }

  trackByItemId(index: number, item: any): number {
    return item.id;
  }

  onChange(pdv_id: number, data: { id: number; active: boolean }[]) {
    const payload = data.map((item) => ({
      id: item.id,
      active: item.active,
    }));

    this.handlerSubmitData[pdv_id] = payload;
  }

  setTableColumns() {
    this.displayedColumns = [
      'pdv',
      ...this.hipercapProducts.map((item) => `product_${item.id}`),
    ];
  }

  get itemsArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  async loadData() {
    const data = await this.hipercapService.getProductsPdv();
    this.items = data.data;
    this.filteredItems = data.data;
  }

  async massUpdate() {
    const data = Object.entries(this.handlerSubmitData).map(
      ([pdv_id, products]) => {
        return {
          pdv_id: Number(pdv_id),
          product_ids: products
            .filter((item: Product) => item.active)
            .map((item: Product) => item.id),
        };
      }
    );
    await this.hipercapService.massUpdate({ data });
    this.swal.success('Dados atualizado com sucesso!');
    this.dialogRef.close({ success: true });
  }

  setSearchItems() {
    if (!this.search) {
      this.filteredItems = this.items;
      return;
    }

    const regex = new RegExp(this.search.value as string, 'i');
    this.filteredItems = this.items.filter((item) => {
      return (
        regex.test(item.pdv_nome) ||
        String(item.pdv_id).startsWith(this.search.value as string)
      );
    });

    this.isSearching = false;
  }

  ngOnInit(): void {
    this.setTableColumns();
    this.loadData();

    this.search.valueChanges
      .pipe(
        tap(() => (this.isSearching = true)),
        debounceTime(500)
      )
      .subscribe(() => {
        this.setSearchItems();
      });
  }
}
