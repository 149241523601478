import { ProdutosGruposModel } from '@/models/produtos-grupos.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProdutosGruposService {
  url = environment.baseURL;

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  findAllProdutosGrupos(params?: any): Observable<ProdutosGruposModel[]> {
    return this.httpClient
      .get<ProdutosGruposModel[]>(`${this.url}/produtos-grupos`, {
        params
      })
      .pipe(catchError(this.authService.handleError as any));
  }

  findProdutosByCategoria(
    produto_categoria_id: number
  ): Observable<ProdutosGruposModel[]> {
    return this.httpClient
      .get<ProdutosGruposModel[]>(
        `${this.url}/produtos-grupos/${produto_categoria_id}`
      )
      .pipe(catchError(this.authService.handleError as any))
  }
}
