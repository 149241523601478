<div *ngIf="header" class="materialTableHeader d-flex">
  <ng-content select="[title]"></ng-content>
</div>
<div class="card">
  <div class="body">
    <ng-content select="[table]"></ng-content>
    <!-- <div class="responsive_table">
    </div> -->
  </div>
</div>
