import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as EmailValidator from 'email-validator';


export const validateFieldsComissaoDesc = (insertObject: any) => {
  let validated:any = false;
  let isValid = {
    isValid: false,
    fieldsEmpty: false,
  };
  let errorValidate = [];
  if (insertObject) {
    validated = isFieldEmpty(insertObject);
    errorValidate.push({fieldEmpty: validated});
    isValid.fieldsEmpty = validated
  }
  const checkField = errorValidate.filter((validate) => validate.fieldEmpty === false);
  if (checkField.length > 0) {
    isValid.isValid = false
  } else {
     isValid.isValid = true;
  }
  return isValid
};

const isFieldEmpty = (insertObject: any) => {
  let arrayFields: any = [];
  Object.entries(insertObject).forEach(([key, value]) => {
    arrayFields.push({ key: value });
  });

  const checkFields = arrayFields.filter((item: any) => item.key === '' || item.key === 0);
  if (checkFields.length === 0) {
    return true;
  } else {
    return false;
  }
};
