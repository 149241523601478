<h1 mat-dialog-title>Permissões de listagem de produtos da Hipercap</h1>
<div mat-dialog-content>
  <form>
    <mat-form-field appearance="outline">
      <mat-label>Buscar</mat-label>
      <input matInput placeholder="Buscar pelo PDV" [formControl]="search" />
    </mat-form-field>
  </form>

  <mat-spinner *ngIf="isSearching" [diameter]="20"></mat-spinner>

  <cdk-virtual-scroll-viewport
    *ngIf="!isSearching"
    itemSize="32"
    class="viewport"
    #scrollViewport
  >
    <table>
      <thead>
        <tr>
          <th [style.top]="inverseOfTranslation">PDV</th>
          @for (product of hipercapProducts; track $index) {
          <th [style.top]="inverseOfTranslation">{{ product.descricao }}</th>
          }
        </tr>
      </thead>
      <tbody>
        <tr
          *cdkVirtualFor="let item of filteredItems; trackBy: trackByItemId"
          class="item"
        >
          <td>{{ item.pdv_id }} - {{ item.pdv_nome }}</td>
          @for (product of item.hipercap_products; track $index) {
          <td>
            <mat-checkbox
              color="primary"
              [(ngModel)]="product.active"
              (change)="onChange(item.pdv_id, item.hipercap_products)"
            >
            </mat-checkbox>
          </td>
          }
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
<div mat-dialog-actions class="btn-actions">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
  <button mat-button color="primary" (click)="massUpdate()">
    {{ !loading ? "Salvar" : "Salvando..." }}
  </button>
</div>
