import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { TerminaisMensagensService } from '@/services/terminais-mensagens.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Editor, Toolbar } from 'ngx-editor';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from '@/models/pagination.model';
import { TerminaisModel } from '@/models/terminais.model';
import { TerminaisService } from '@/services/terminais.service';
import { SharedService } from 'src/app/shared/shared.service';
import { PageEvent } from '@angular/material/paginator';
import { TerminaisMensagem } from '@/models/terminais-mensagem';
import {
  MatSnackBar,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-mensagens-terminais-upsert',
  templateUrl: './mensagens-terminais-upsert.component.html',
  styleUrls: ['./mensagens-terminais-upsert.component.scss'],
})
export class MensagensTerminaisUpsertComponent implements OnInit {
  @Output()
  created: EventEmitter<any> = new EventEmitter();
  @Input() inputsValue: any;
  
  subjectId: number | null = null;
  isSendingForm = false
  editor: Editor;
  toolbar: Toolbar = [['bold'], ['align_left', 'align_center', 'align_right']];

  selection = new SelectionModel<any>(true, []);
  columns: string[] = [
    'select',
    'ponto_venda',
    'pontovenda.status',
    'serial',
    'modelo',
    'status'
  ];
  form!: FormGroup;
  status = [
    { name: 'Inativo', value: 'INATIVO' },
    { name: 'Ativo', value: 'ATIVO' },
  ];
  selectedItems: any[] = [];
  terminaisFiltrados: any[] = [];

  terminals: {ids: number[]} & Pagination<TerminaisModel> = {
    data: [],
    current_page: 1,
    last_page: 0,
    per_page: 50,
    total: 0,
    ids: []
  };

  terminalSearch = new FormControl('')
  terminaisMensagem: TerminaisMensagem | null = null

  constructor(
    private formBuilder: FormBuilder,
    private swal2: SweetAlert2Service,
    private terminaisMensagensService: TerminaisMensagensService,
    private route: ActivatedRoute,
    private terminalsService: TerminaisService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    const { id } = this.route.snapshot.params;

    if (id) {
      this.subjectId = Number(id);
    }
  }

  get messagePreview() {
    return this.form.get('mensagem')?.value?.replace(/<p><\/p>/ig, '<p><br><\/p>');
  }


  loadTerminals() {
    this.terminalsService.partialSearchTerminais(this.sharedService.buildQueryUrl({
      status: "",
      search: this.terminalSearch.value,
      page: this.terminals.current_page
    })).subscribe({
      next: (result: any) => {
        this.terminals = {
          data: result.items,
          current_page: result.meta.currentPage,
          total: result.meta.totalItems,
          last_page: result.meta.totalPages,
          per_page: result.meta.itemsPerPage,
          ids: result.ids
        }
      }
    })
  }


  onPaginate(data: PageEvent) {
    this.terminals.current_page = data.pageIndex + 1
    this.loadTerminals()
  }

  async loadSubject() {
    const response = await this.terminaisMensagensService.findById(this.subjectId as number)
    this.terminaisMensagem = response
    this.selection.setSelection(...response.terminal_ids)
    this.form.patchValue({
      mensagem: response.mensagem,
      status: response.status,
      periodo_inicio: dayjs(response.periodo_inicio).format('YYYY-MM-DDTHH:mm'),
      periodo_fim: dayjs(response.periodo_fim).format('YYYY-MM-DDTHH:mm'),
      descricao: response.descricao,
    })
  }

  onPaste(event: ClipboardEvent)  {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
    this.form.patchValue({
      mensagem: text
    })
  }

  ngOnInit() {
    this.editor = new Editor();
    this.loadTerminals()
    this.form = this.formBuilder.group({
      periodo_inicio: [this.getCurrentDateTime(), [Validators.required]],
      periodo_fim: [this.getCurrentDateTime(), [Validators.required]],
      status: [this.status[0]],
      descricao: ['', [Validators.required]],
      mensagem: ['', [Validators.required]],
      id: null,
    });

    if(this.subjectId) {
      this.loadSubject()
    }
  }

  resetForm() {
    this.form.patchValue({
      periodo_inicio: this.getCurrentDateTime(),
      periodo_fim: this.getCurrentDateTime(),
      descricao: '',
      mensagem: '',
      id: null,
    });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  async sendForm() {
    try {
      this.isSendingForm = true
      const periodo_inicio = dayjs(this.form.value['periodo_inicio']);
      const periodo_fim = dayjs(this.form.value['periodo_fim']);

      if(periodo_fim.isBefore(periodo_inicio)) {
        this.snackBar.open('Período selecionado inválido', 'OK', {
          horizontalPosition: "center",
          verticalPosition: "top",
        })

        return
      }

      const mensagem = {
        periodo_inicio,
        periodo_fim,
        descricao: this.form.value['descricao'],
        mensagem: this.form.value['mensagem'],
        status: this.form.value['status']?.value,
      };

      const response = await this.terminaisMensagensService.createMensagem(mensagem, this.selection.selected, this.subjectId)
      this.snackBar.open('Ação registrada com sucesso', 'Fechar', {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 5000,
      })
      this.router.navigate([`/administracao/mensagens-terminais/${response.id}/detalhes`])
    } finally {
      this.isSendingForm = false
    }
  }

  getCurrentDateTime(): string {
    return dayjs().format('YYYY-MM-DDTHH:mm');
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  get isAllElementsFromCurrentPageSelected() {
    const elementIdsFromCurrentPage = this.terminals.data.map(item => item.id)
    return elementIdsFromCurrentPage.every(item => this.selection.isSelected(item))
  }

  selectAllElements() {
    this.selection.setSelection(...this.terminals.ids)
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected === this.terminals.data.length || numSelected === this.terminals.ids.length;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.terminals.data.map(item => item.id));
  }

  resetPagination() {
    this.terminals.current_page = 1
    this.loadTerminals()
  }
}
