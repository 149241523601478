<h1 mat-dialog-title>Dados do Boleto</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" color="primary">
      <input matInput currencyMask type="text" formControlName="valor" placeholder="Valor">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Data de Vencimento</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="data_vencimento" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <mat-spinner *ngIf="loading; else buttons;" diameter="24"></mat-spinner>
  <ng-template #buttons>
    <button mat-button cdkFocusInitial (click)="submit()" [disabled]="form.invalid">Gerar</button>
    <button mat-button mat-dialog-close>Cancelar</button>
  </ng-template>
</div>
