import { environment } from "@/environments/environment";
import { FaturamentoDto } from "@/models/dto/faturamento.dto";
import { PontoVendaSimple } from "@/models/dto/ponto_venda.simple";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { FaturamentoRecolheDto } from "@/models/dto/faturamento-recolhe.dto";

@Injectable({
    providedIn: 'root',
})
export class FaturamentosService {
    url = environment.baseURL;

    constructor(
        private httpClient: HttpClient,
        public authService: AuthService
    ) { }

    findAllFaturamentosByData(date: string): Observable<FaturamentoDto[]> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("date", date);
        return this.httpClient
            .get<FaturamentoDto[]>(`${this.url}/faturamentos`, { params: queryParams })
            .pipe(catchError(this.authService.handleError as any));
    }

    searchFaturamentos(query: any): Observable<FaturamentoDto[]> {
        return this.httpClient
            .get<FaturamentoDto[]>(`${this.url}/faturamentos/search`, { params: query })
            .pipe(catchError(this.authService.handleError as any));
      }

    findPdvsSemRecebimento(idFaturamento: number): Observable<PontoVendaSimple[]> {
        return this.httpClient
            .get<PontoVendaSimple[]>(`${this.url}/faturamentos/${idFaturamento}/pdv-sem-rec`)
            .pipe(catchError(this.authService.handleError as any));
    }


    gerarRecebimento(recebimentoId: number) {
      return this.httpClient
        .get(`${this.url}/faturamentos/${recebimentoId}/gerar-recebimento`)
  }

  findFaturamentosRecolheByData(dateFrom: string, dateTo: string): Observable<FaturamentoRecolheDto[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('date_from', dateFrom);
    queryParams = queryParams.append('date_to', dateTo);

    return this.httpClient
      .get<FaturamentoDto[]>(`${this.url}/faturamentos/recolhe`, { params: queryParams })
      .pipe(catchError(this.authService.handleError as any));
  }

}
