import { BancoPdv } from '@/models/BancoPdv.';
import { BoletoConfiguracoesModel } from '@/models/boleto-configuracoes.model';
import { AuthService } from '@/services/auth.service';
import { BoletoConfiguracoesService } from '@/services/boleto-configuracoes.service';
import { PontosDeVendaService } from '@/services/pontos-de-venda.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Bancos } from 'src/app/constants';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-bancos-pdv',
  templateUrl: './bancos-pdv.component.html',
  styleUrl: './bancos-pdv.component.scss'
})
export class BancosPdvComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<BancoPdv>();
  tableColumns: string[] = ['select', 'pdv', 'status', 'boletoConfig']
  myControl: FormControl = new FormControl('');
  boletoConfigId: FormControl = new FormControl('');
  pdvs: any[] = [];
  boletoConfiguracoes: BoletoConfiguracoesModel[] = []
  selection = new SelectionModel<any>(true, [])
  filters: FormGroup
  bancos = Bancos

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private sharedService: SharedService,
    public authService: AuthService,
    private pdvService: PontosDeVendaService,
    private formBuilder: FormBuilder,
    private swal: SweetAlert2Service,
    private boletoConfiguracoesService: BoletoConfiguracoesService
  ){
    this.filters = this.formBuilder.group({
      modalidade: [''],
      boletoConfigId: [''],
      pdv: [null],
    })
  }

  get isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length
    return numSelected > 0 && numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  searchBoletosConf() {
    this.pdvService.boletosConfiguracoes({
      pdv_id: this.filters.get('pdv')?.value?.id || '',
      modalidade: this.filters.get('modalidade')?.value || '',
      boletoConfigId: this.filters.get('boletoConfigId')?.value || ''
    }).subscribe({
      next: (value: BancoPdv[]) => {
        this.dataSource.data = value;
      },
    });
  }

  getPontoVendas() {
    this.pdvService.findAllPontosDeVenda().subscribe({
      next: (data: any) => {
        this.pdvs = data
      },
    });
  }

  getConfigs() {
    this.boletoConfiguracoesService.findAll().subscribe({
      next: (data) => {
        this.boletoConfiguracoes = data
      }
    })
  }

  getOptionText(option: any) {
    if (option.id === undefined && option.nome_comercial === undefined) {
      return option.id;
    }
    return `${option.id} - ${option.nome_comercial}`;
  }

  get filteredPontosDeVenda() {
    if(this.myControl.value) {
      const match = new RegExp(this.myControl.value, 'i')
      const filteredItems = this.pdvs
        .filter(pdv => match.test(pdv.nome_comercial) || match.test(pdv.id))

      return filteredItems
    }

    return this.pdvs
  }

  selectedPontoDeVenda(event: MatAutocompleteSelectedEvent) {
    this.filters.get('pdv')?.setValue(event.option.value)
  }

  onUpdate() {
    const config = this.boletoConfiguracoes.find(item => item.id == this.boletoConfigId.value);
    this.swal.confirm(`Os ${this.selection.selected.length} pontos selecionados terão sua configuração alteradas para ${config?.nome_config}.`, 'Deseja confirmar essa ação?')
      .then(result => {
        if(result.isConfirmed) {
          this.swal.loading()
          this.pdvService.atualizaBoletosConfiguracoes({
            pdv_ids: this.selection.selected?.map(item => item.pdv_id),
            boletoConfigId: this.boletoConfigId.value
          }).subscribe({
            next: () => {
              this.swal.success('Dados atualizados com sucesso')
              this.selection.clear()
              this.boletoConfigId.setValue('')
              this.searchBoletosConf()
            },
            error: (error) => {
              this.swal.error(error)
              this.swal.hideLoading()
            },
            complete: () => {
              this.swal.hideLoading()
            }
          })
        }
      })
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.getPontoVendas()
    this.getConfigs()
    this.sharedService.changeAuth(true);
    this.searchBoletosConf();

    this.filters.valueChanges.subscribe(() => {
      this.searchBoletosConf();
    })

    this.myControl.valueChanges.subscribe(value => {
      if(value === '') {
        this.filters.get('pdv')?.setValue(null)
        this.searchBoletosConf();
      }
    })
  }
}
