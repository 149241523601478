import { TransacoesPixModel } from '@/models/transacoes-pix.model';
import { LoadingService } from '@/services/loading.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { TransacoesPixService } from '@/services/transacoes-pix.service';
import { Component, OnInit } from '@angular/core';
import { DatatableColumn } from '@/models/datatable-column.model';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-estornar-pix',
  templateUrl: './estornar-pix.component.html',
  styleUrls: ['./estornar-pix.component.scss']
})
export class EstornarPixComponent {
  columns: string[] = ['cod_pdv', 'pdv', 'serial', 'data_hora_rec', 'n_recarga', 'fornecedor_nsu', 'valor', 'pix_nsu', 'actions' ]
  data: TransacoesPixModel[] = []

  constructor(
    private loading: LoadingService,
    private transacoesPixService: TransacoesPixService,
    private sharedService: SharedService,
    private swal: SweetAlert2Service
  ) {}

  async buscarUltimos24Horas() {
    try {
      this.loading.show()
      const data = await this.transacoesPixService.ultimos24Horas()
      this.data = data;
    } finally {
      this.loading.hide()
    }
  }

  async gerarEstorno(transacaoId: number) {
    try {
      this.loading.show()
      await this.transacoesPixService.estornar(transacaoId);
      this.buscarUltimos24Horas()
      this.swal.success('Estorno realizado com sucesso!')
    } catch (err: any) {
      this.swal.error(err?.message || 'Algo inesperado aconteceu')
    } finally {
      this.loading.hide()
    }

  }

  estornar(data: TransacoesPixModel) {
    this.swal.confirm(`Deseja estornar o pix NSU: ${data.pix_nsu}?`)
      .then(async (result) => {
        if(result.isConfirmed) {
          this.gerarEstorno(data.transacao_id)
        }
      })
  }

  ngOnInit() {
    this.sharedService.changeAuth(true)
    this.buscarUltimos24Horas()
  }
}
