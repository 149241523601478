<div class="container-fluid container-wrap">
  <h1>Lançamentos</h1>

  <mat-spinner *ngIf="loading" diameter="20" class="mx-auto my-4"></mat-spinner>
  <ng-container *ngIf="lancamentos.length; then content else no_content"></ng-container>
  <ng-template #no_content>
    <p class="text-center">Nenhum registro encontrado.</p>
  </ng-template>
  <ng-template #content>
    <div class="mat-elevation-z1 card container-space">
      <form [formGroup]="form">
        <div class="form-container">
          <mat-form-field appearance="outline" color="primary" style="max-width: 150px;">
            <input matInput currencyMask type="text"  formControlName="valor" placeholder="Valor">
          </mat-form-field>
          <mat-form-field appearance="outline" color="primary" style="min-width: 400px;" >
            <input matInput type="text" placeholder="Descrição" formControlName="descricao">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select required name="tipo" aria-placeholder="tipo" formControlName="tipo">
              <mat-option [value]="LANCAMENTO_TIPOS.CREDITO">{{LANCAMENTO_TIPOS.CREDITO}}</mat-option>
              <mat-option [value]="LANCAMENTO_TIPOS.DEBITO">{{LANCAMENTO_TIPOS.DEBITO}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button color="primary" [disabled]="form.invalid" (click)="btnRegistrarLancamento()" style="height: 55px;">Adicionar</button>
        </div>
      </form>
    </div>

    <table mat-table [dataSource]="lancamentos">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

      <ng-container matColumnDef="produto_grupo">
        <th mat-header-cell *matHeaderCellDef>Grupo</th>
        <td mat-cell *matCellDef="let element">{{element.produto_grupo.descricao}}</td>
      </ng-container>

      <ng-container matColumnDef="quantidade_transacoes">
        <th mat-header-cell *matHeaderCellDef>Transações</th>
        <td mat-cell *matCellDef="let element">{{element.quantidade_transacoes}}</td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">{{element.tipo}}</td>
      </ng-container>

      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">{{this.formataValorParaBRL(element.valor)}}</td>
      </ng-container>

      <ng-container matColumnDef="descricao">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">{{element.descricao}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
  </ng-template>
</div>
