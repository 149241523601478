<div class="materialTableHeader d-flex">
  <h3>Recargas com Status Pendente - Últimos 3 dias</h3>
</div>
<table mat-table [dataSource]="data">
  <ng-container matColumnDef="serial">
    <th mat-header-cell *matHeaderCellDef>Serial</th>
    <td mat-cell *matCellDef="let element">{{element.serial}}</td>
  </ng-container>

  <ng-container matColumnDef="data_hora">
    <th mat-header-cell *matHeaderCellDef>Data Hora</th>
    <td mat-cell *matCellDef="let element">{{element.data_hora | date: "dd/MM/yyyy HH:mm:ss"}}</td>
  </ng-container>

  <ng-container matColumnDef="pdv">
    <th mat-header-cell *matHeaderCellDef>PDV</th>
    <td mat-cell *matCellDef="let element">{{element.pv_id + " - "+ element.nome_comercial}}</td>
  </ng-container>

  <ng-container matColumnDef="produto">
    <th mat-header-cell *matHeaderCellDef>Produto</th>
    <td mat-cell *matCellDef="let element">{{element.descricao + " - "+ element.produto}}</td>
  </ng-container>

  <ng-container matColumnDef="forma_pagamento">
    <th mat-header-cell *matHeaderCellDef>Forma de Pagamento</th>
    <td mat-cell *matCellDef="let element">{{element.forma_pagamento}}</td>
  </ng-container>

  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef>Valor</th>
    <td mat-cell *matCellDef="let element">{{element.valor | currency: "R$"}}</td>
  </ng-container>

  <ng-container matColumnDef="numero_identificador">
    <th mat-header-cell *matHeaderCellDef>Numero Identificador</th>
    <td mat-cell *matCellDef="let element">{{element.numero_identificador}}</td>
  </ng-container>

  <ng-container matColumnDef="fornecedor_nsu">
    <th mat-header-cell *matHeaderCellDef>Fornecedor NSU</th>
    <td mat-cell *matCellDef="let element">{{element.fornecedor_nsu}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Ações</th>
    <td mat-cell *matCellDef="let data">
      <button
        matTooltip="Alterar Status da Recarga"
        (click)="confirmModal(data)"
        mat-icon-button
        class="small-icon-button"
        color="accent"
        ng-reflect-color="accent"
      >
        <mat-icon>published_with_changes</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>

</table>
