import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseApi } from './base/base-api.service';
import { ProcessaArquivoRetornoDto } from '@/models/dto/pagamentos/processa-arquivo-retorno.dto';
import { ProcessaCnab } from '@/models/pagamento.model';
import { Feriado } from '@/models/feriado.model';

@Injectable({
  providedIn: 'root',
})
export class FeriadoService extends BaseApi {
  async findAll(): Promise<Feriado[]> {
    return lastValueFrom(this.get(`${this.url}/feriados`))
  }

  async create(data: {data: string, descricao: string}): Promise<Feriado> {
    return lastValueFrom(this.post(`${this.url}/feriados`, data))
  }

  async remove(id: number): Promise<void> {
    return lastValueFrom(this.delete(`${this.url}/feriados/${id}`))
  }
}
