import { BoletoService } from '@/services/boleto.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';

interface DialogData {
  recebimentoId: number;
  boletoId: number;
  valorDocumento: number;
}

@Component({
  selector: 'app-modal-alterar-vencimento-boleto',
  templateUrl: './modal-alterar-vencimento-boleto.component.html',
  styleUrls: ['./modal-alterar-vencimento-boleto.component.scss']
})
export class ModalAlterarVencimentoBoletoComponent {
  data_vencimento = new Date();
  form!: FormGroup
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: DialogData,
    private fb: FormBuilder,
    private boletoService: BoletoService,
    private swal: SweetAlert2Service,
    private dialogRef: MatDialogRef<ModalAlterarVencimentoBoletoComponent>
  ){
    this.form = this.fb.group({
      data_vencimento: [new Date(), [Validators.required]]
    })
  }


  submit() {
    const {data_vencimento} = this.form.value;
    const dataVencimentoFormatada = dayjs(data_vencimento).format('YYYY-MM-DD')
    console.log(dataVencimentoFormatada)

    this.loading = true;
    this.boletoService.alterarVencimento(this.data.boletoId, {dataVencimentoFormatada}).subscribe({
      next: () => {
        this.swal.success('Data de vencimento do boleto alterada com sucesso!')
        this.dialogRef.close(true);
      },
      complete: () => {
        this.loading = false
      },
      error: (err) => {
        if(err.error) {
          this.swal.error(err.error?.message);
        }
        this.loading = false;
      },
    })
  }
}
