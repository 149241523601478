import { FeriadoService } from './../../../../services/feriado.service';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { HolidayPreviewDialogComponent } from './components/holiday-preview-dialog/holiday-preview-dialog.component';
import { Feriado } from '@/models/feriado.model';
import { CreateHolidayDialogComponent } from './components/create-holiday-dialog/create-holiday-dialog.component';
import { debounceTime, fromEvent } from 'rxjs';
moment.locale('pt-BR')

@Component({
  selector: 'app-feriados',
  templateUrl: './feriados.component.html',
  styleUrls: ['./feriados.component.scss']
})
export class FeriadosComponent {
  holidays: Feriado[] = [];
  holidaysThisMonth: Feriado[] = [];
  constructor(
    private dialog: MatDialog,
    private feriadoService: FeriadoService
  ) {}

  ngOnInit() {
    this.loadHolidays()
  }

  loadHolidays() {
    this.feriadoService.findAll()
      .then(response => {
        this.holidays = response
        this.updateDayStyles()
      })
  }

  updateDayStyles() {
    this.holidaysThisMonth = []
    const calendar = document.querySelector(".mat-calendar-content");
    const cellDays = calendar?.querySelectorAll('.mat-calendar-body-cell') || [];

    cellDays.forEach((day: any) => {
      this.holidays.forEach(() => {
        const preview = day?.querySelector('.mat-calendar-body-cell-content')
        preview.classList.remove('holiday')
        preview.removeAttribute('data-title')
      });

      this.holidays.forEach(holiday => {
        const holidayParseDate = moment(holiday.data);
        const cellDate = moment(day.getAttribute('aria-label'), 'D [de] MMMM [de] YYYY');

        const preview = day?.querySelector('.mat-calendar-body-cell-content')
        if (cellDate.isSame(holidayParseDate, 'day') && preview) {
          preview.classList.add('holiday')
          preview.setAttribute('data-title', holiday.descricao)
          this.holidaysThisMonth.push(holiday)
        }
      });
    });
  }

  onChange(event: Date) {
    const formattedDate = moment(event).format('YYYY-MM-DD');
    const holiday = this.holidays.find(item => item.data === formattedDate);

    if(holiday) {
      const dialogRef = this.dialog.open(HolidayPreviewDialogComponent, {
        width: '500px',
        data: holiday
      });

      dialogRef.afterClosed().subscribe(data => {
        if(data.excluded) {
          this.holidays = this.holidays.filter(item => item.id != holiday.id)
          this.updateDayStyles()
        }
      })

      return;
    }

    const dialogRef = this.dialog.open(CreateHolidayDialogComponent, {
      width: '500px',
      data: {
        data: event
      }
    })

    dialogRef.afterClosed().subscribe(data => {
      if(data?.result) {
        this.holidays.push(data.result);
        this.updateDayStyles()
      }
    })
  }

  ngAfterViewInit() {
    fromEvent(document.querySelectorAll('.mat-calendar-previous-button, .mat-calendar-next-button'), 'click')
      .pipe(
        debounceTime(100)
      ).subscribe(() => this.updateDayStyles())
  }
}
